// CLIENTS && REALISATIONS
// Détail d'une rélisation
/*

*/
.clients {

    .page-intro i {
        color: darken(@gray, 30%);
        font-size: 7.7rem;
    }

    .filter-realisations {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: @gray;
        padding: 0.20%;

        header {
            flex: 0 0 100%;
        }

        a {
            display: flex;
            padding: 25px 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            margin: 0.20%;
            flex: 0 0 49.59%;
            ._transition;

            &.active,
            &:hover {
                background-color: @gray;

                i {
                    color: @orange;
                }

                img {
                    ._filter(grayscale(0));

                }
            }
        }

        i {
            color: darken(@gray, 30%);
            font-size: 4.2rem;
            ._transition;
        }

        img {
            max-height: 70px;
            width: auto;
            ._filter(grayscale(0.4));
            ._transition;
        }

        p {
            margin-top: 15px;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 600;
            text-align: center;
            color: @title-mc;

            & + hr + p {
                margin-top: 0;
            }
        }

        hr {
            ._reset;
            width: 20px;
            height: 1px;
            background-color: darken(@gray, 15%);
            margin: 5px auto;
        }
    }

    @media screen and (min-width: 500px) {
        .filter-realisations {
            a {
                padding: 25px 15px;
                flex: 1;

                &.cc-third {
                    padding: 30px 20px;
                    flex: 0 0 32.90%;
                }

            }
        }
    }

    &.ref-bornes {
        video {
            max-width: 100%;
        }
    }
}


// Détail d'une réalisation
.realisation-detail {

    .canvas-basic {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
    }

    // Reference image + title + summary
    .realisation-top {
        overflow: hidden;

        .realisation-top-left {
            min-height: 30vh;
        }

        .realisation-top-right {
            padding: 40px 20px;
            position: relative;

            h1 {
                text-align: center;
                position: relative;
                z-index: 9;
                color: white;

                small {
                    color: currentcolor;
                    padding-top: 10px;
                    display: block;
                }
            }
        }
    }

    // Reference skills
    .wrap-reference-skills {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        div, a {
            flex: 1 0 50.00%;
            display: flex;
            padding: 10px 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            ._transition;

            &:hover {
                background-color: white;
            }
        }

        .reference-year {
            color: darken(@gray, 30%);
            height: 20px;
            display: flex;
            align-items: center;
            font-size: 2rem;
        }

        i {
            color: darken(@gray, 30%);
            font-size: 3rem;
        }

        img {
            max-height: 30px;
            width: auto;
        }

        p:not(.reference-year) {
            color: @title-mc;
            margin-top: 15px;
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
        }
    }

    // Reference description
    .reference-description {
        padding: 40px 20px;

        a {
            color: @orange;
        }

        // Reference with video in description
        .video-container {
            margin: 60px auto 0;
            width: 700px;
            max-width: 100%;

            .contain-video {
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    // Reference Testominoal
    .wrap-testinomial {
        padding: 50px 20px;
        background-color: #282828;
        overflow: hidden;

        & > .cc-inside {
            position: relative;
        }

        h2 {
            font-size: 2.5rem;
            color: white;
            position: relative;
            z-index: 2;
            margin-bottom: 30px;
            padding: 0 20px;
        }

        .icon-illu {
            color: lighten(#282828, 5%);
            font-size: 30rem;
            position: absolute;
            z-index: 0;
            top: -100px;
            left: -100px;

            i {
                display: block;
            }
        }

        blockquote {
            ._reset;
            position: relative;
            z-index: 2;
            padding: 0 20px;
            text-align: justify;
            font-weight: 300;
            color: darken(white, 25%);

            footer {
                padding-top: 0;
                margin-top: 25px;
                font-size: 1.4rem;
            }
        }
    }

    // Links
    .references-links {
        padding: 40px 20px;

        a {
            display: block;
            margin: 20px auto 0px;

            &:nth-child(1) {
                margin-top: 0;
            }
        }
    }


    // Screen > 767px
    ._media-s-up({
        #main-content {
            padding-top: 96px;
        }
        // Reference skills
        .wrap-reference-skills {
            div, a {
                flex: 0 1 auto;
                padding: 25px 15px;
            }

            .reference-year {
                height: 42px;
                font-size: 3.5rem;
            }

            i {
                font-size: 4.2rem;
            }

            img {
                max-height: 42px;
            }

            p:not(.reference-year) {
                font-size: 1.4rem;
            }

        }
        // Links
        .references-links {
            padding: 40px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                margin: 0;

                &.cc-fat {
                    order: 1
                }
            }
        }
    });


    // Screen > 989px
    ._media-m-up({
        #main-content {
            padding-top: 108px;
        }
        // Reference image + title + summary
        .realisation-top {
            display: flex;
            min-height: 500px;

            .realisation-top-left {
                flex: 0 0 60%;
            }

            .realisation-top-right {
                background-color: #f7f7f7;
                flex: 1;
                padding: 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: -250px;
                    width: 250px;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.5);
                    ._skew('X', -20deg)

                }

                &:after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: -125px;
                    width: 250px;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.5);
                    ._skew('X', -20deg)
                }


            }
        }
        // Reference testimonial
        .wrap-testinomial {
            padding: 60px 20px;

            blockquote {
                padding: 0 70px;
            }
        }
        // Links
        .references-links {
            padding: 40px 0px;
        }
    });


    // Screen > 1199px;
    ._media-l-up({ // Testimonial
        .wrap-testinomial {
            .icon-illu {
                font-size: 30rem;
                top: -150px;
                left: -50px;
            }
        }
    });
}
