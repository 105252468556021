@charset "UTF-8";
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}
/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 13px;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}
/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/* titillium-web-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/titillium-web-v15-latin-600.ebc29b39.eot);
  /* IE9 Compat Modes */
  src: url(/build/fonts/titillium-web-v15-latin-600.ebc29b39.eot?#iefix) format('embedded-opentype'),  url(/build/fonts/titillium-web-v15-latin-600.5d7c6bb8.woff2) format('woff2'),  url(/build/fonts/titillium-web-v15-latin-600.d70a96f8.woff) format('woff'),  url(/build/fonts/titillium-web-v15-latin-600.bbfe4270.ttf) format('truetype'),  url(/build/images/titillium-web-v15-latin-600.9d361bb7.svg#TitilliumWeb) format('svg');
  /* Legacy iOS */
}
/* open-sans-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/build/fonts/open-sans-v34-latin-300.4a6e5d15.eot);
  /* IE9 Compat Modes */
  src: url(/build/fonts/open-sans-v34-latin-300.4a6e5d15.eot?#iefix) format('embedded-opentype'),  url(/build/fonts/open-sans-v34-latin-300.62d24b94.woff2) format('woff2'),  url(/build/fonts/open-sans-v34-latin-300.6b8abe90.woff) format('woff'),  url(/build/fonts/open-sans-v34-latin-300.f40faaa8.ttf) format('truetype'),  url(/build/images/open-sans-v34-latin-300.78c27962.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/build/fonts/open-sans-v34-latin-regular.ec2df544.eot);
  /* IE9 Compat Modes */
  src: url(/build/fonts/open-sans-v34-latin-regular.ec2df544.eot?#iefix) format('embedded-opentype'),  url(/build/fonts/open-sans-v34-latin-regular.e43b5358.woff2) format('woff2'),  url(/build/fonts/open-sans-v34-latin-regular.600270a4.woff) format('woff'),  url(/build/fonts/open-sans-v34-latin-regular.4e6feb3d.ttf) format('truetype'),  url(/build/images/open-sans-v34-latin-regular.ef2d6483.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/build/fonts/open-sans-v34-latin-italic.5337f0ca.eot);
  /* IE9 Compat Modes */
  src: url(/build/fonts/open-sans-v34-latin-italic.5337f0ca.eot?#iefix) format('embedded-opentype'),  url(/build/fonts/open-sans-v34-latin-italic.3d5107ab.woff2) format('woff2'),  url(/build/fonts/open-sans-v34-latin-italic.f1e23d36.woff) format('woff'),  url(/build/fonts/open-sans-v34-latin-italic.2f30eb04.ttf) format('truetype'),  url(/build/images/open-sans-v34-latin-italic.85120c16.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(/build/fonts/open-sans-v34-latin-600.e4521bb3.eot);
  /* IE9 Compat Modes */
  src: url(/build/fonts/open-sans-v34-latin-600.e4521bb3.eot?#iefix) format('embedded-opentype'),  url(/build/fonts/open-sans-v34-latin-600.603c9927.woff2) format('woff2'),  url(/build/fonts/open-sans-v34-latin-600.3e8ff770.woff) format('woff'),  url(/build/fonts/open-sans-v34-latin-600.2f87912f.ttf) format('truetype'),  url(/build/images/open-sans-v34-latin-600.17f9019c.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/*
    == ANIMATIONS ==
*/
/* *****
    @name : ._loading-spin
    @description : animate loading buttons
***** */
@-webkit-keyframes loading-spin {
  from {
    /* Not perspective */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* Perspective */
  }
  to {
    /* Not perspective */
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    /* Perspective */
  }
}
@keyframes loading-spin {
  from {
    /* Not perspective */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* Perspective */
  }
  to {
    /* Not perspective */
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    /* Perspective */
  }
}
/* *****
    @name : loaderDots
    @description : animate dots loader
***** */
@-webkit-keyframes loaderDots {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes loaderDots {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
/* *****
    @name : loaderSpinner
    @description : animate spinner loader
***** */
@-webkit-keyframes loaderSpinner {
  0% {
    /* Not perspective */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* Perspective */
  }
  100% {
    /* Not perspective */
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    /* Perspective */
  }
}
@keyframes loaderSpinner {
  0% {
    /* Not perspective */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* Perspective */
  }
  100% {
    /* Not perspective */
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    /* Perspective */
  }
}
/* *****
    @name : loaderCaterpillar
    @description : animate caterpillar loader
***** */
@-webkit-keyframes loaderCaterpillar {
  0% {
    left: 100px;
    top: 0;
  }
  80% {
    left: 0;
    top: 0;
  }
  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  90% {
    width: 40px;
    height: 15px;
  }
  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 100px;
    top: 0;
  }
}
@keyframes loaderCaterpillar {
  0% {
    left: 100px;
    top: 0;
  }
  80% {
    left: 0;
    top: 0;
  }
  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  90% {
    width: 40px;
    height: 15px;
  }
  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 100px;
    top: 0;
  }
}
/* *****
    @name : checkdio
    @description : animate radio & checkox inputs when checked
***** */
@-webkit-keyframes checkdio {
  0% {
    opacity: 0.4;
    /* X != Y */
    /* X only */
    -webkit-transform: scale(3);
            transform: scale(3);
    /* X & Y empty : default value */
  }
  10% {
    /* X != Y */
    /* X only */
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
    /* X & Y empty : default value */
  }
  90% {
    /* X != Y */
    /* X only */
    -webkit-transform: scale(1);
            transform: scale(1);
    /* X & Y empty : default value */
  }
  100% {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes checkdio {
  0% {
    opacity: 0.4;
    /* X != Y */
    /* X only */
    -webkit-transform: scale(3);
            transform: scale(3);
    /* X & Y empty : default value */
  }
  10% {
    /* X != Y */
    /* X only */
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
    /* X & Y empty : default value */
  }
  90% {
    /* X != Y */
    /* X only */
    -webkit-transform: scale(1);
            transform: scale(1);
    /* X & Y empty : default value */
  }
  100% {
    -webkit-transform: none;
            transform: none;
  }
}
/* *****
    @name : showLazyImg
    @description : Animate img and backstretch when elements are loaded with lozad
***** */
img[data-loaded="true"],
[data-loaded="true"] img {
  -webkit-animation-name: showLazyImg;
          animation-name: showLazyImg;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
    == BASE : Define <html>, <body> and selection styles ==
*/
html {
  font-size: 62.5%;
  font-size: calc(1em*0.625);
  overflow-x: hidden;
}
html.opened-modal,
body.opened-modal,
html.opened-menu,
body.opened-menu {
  overflow: hidden !important;
}
body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #3a3a3a;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  line-height: 1.5;
  /* Box-sizing */
}
@media screen and (min-width: 360px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 768px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 990px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 1200px) {
  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
body *,
body *:before,
body *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
div,
dl,
dd,
dt,
p,
ul,
li,
ol {
  padding: 0;
  margin: 0;
  border: 0;
}
li {
  list-style: none;
}
/* define text selection */
::selection {
  background: #3a3a3a;
  text-shadow: none;
  color: white;
}
/* ******** */
/** TITLES **/
/* ******** */
/* *****
    @name : ._generateTitles
    @description : Generate title <hn> styles
***** */
h1,
.h1-like {
  font-size: 2em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h1 small,
.h1-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
h2,
.h2-like {
  font-size: 1.75em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h2 small,
.h2-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
h3,
.h3-like {
  font-size: 1.5625em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h3 small,
.h3-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
h4,
.h4-like {
  font-size: 1.25em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h4 small,
.h4-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
h5,
.h5-like {
  font-size: 1.125em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h5 small,
.h5-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
h6,
.h6-like {
  font-size: 1.0625em;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  color: #070707;
}
h6 small,
.h6-like small {
  font-weight: 400;
  color: #474747;
  font-size: 70%;
}
/* ***************** */
/** LINKS / BUTTONS **/
/* ***************** */
/* ********************* */
/*
    - General links & buttons styles.

    /* - - -
    /!\ : you can override ._btn bg-color in functions.less : ._btn(@bg, @bgHover); /!\
    /* - - -

*/
/* Simple HTML links */
a {
  text-decoration: none;
  color: #3a3a3a;
}
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}
/* Buttons && buttons-like HTML links */
a.btn,
button {
  display: inline-block;
  color: #3a3a3a;
  padding: 0.3em 1em;
  line-height: 1.8;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 1.6rem;
  vertical-align: middle;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
  /* hover : default, change border-color */
}
a.btn:focus,
button:focus,
a.btn:active,
button:active,
a.btn:visited,
button:visited {
  outline: none;
}
a.btn:hover,
button:hover {
  border-color: #f9f9f9;
}
a.btn.cc,
button.cc {
  /* Thins buttons, .btn links */
  /* Fats buttons, .btn links */
  /* disabled */
  /* Loading buttons */
}
a.btn.cc-thin,
button.cc-thin {
  font-size: 1.12rem;
  /* Thin Loading buttons */
}
a.btn.cc-thin.cc-loading:before,
button.cc-thin.cc-loading:before {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  border-width: 1px;
}
a.btn.cc-fat,
button.cc-fat {
  font-size: 2.08rem;
  /* Thin Loading buttons */
}
a.btn.cc-fat.cc-loading:before,
button.cc-fat.cc-loading:before {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}
a.btn.cc-disabled,
button.cc-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  /* old browser */
  pointer-events: none;
}
a.btn.cc-loading,
button.cc-loading {
  position: relative;
  color: transparent !important;
  cursor: not-allowed;
  pointer-events: none;
}
a.btn.cc-loading::selection,
button.cc-loading::selection {
  background: transparent;
  color: transparent;
}
a.btn.cc-loading:before,
button.cc-loading:before {
  display: block;
  border-width: 2px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-radius: 50%;
  content: " ";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  margin-right: 0;
  -webkit-animation-name: loading-spin;
          animation-name: loading-spin;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
/* ******* */
/** LISTS **/
/* ******* */
/* Specific for Drupal : reset list style margin, padding & border */
.item-list ul li {
  padding: 0;
  margin: 0;
  border: 0;
}
ul,
dl,
ol {
  color: #3a3a3a;
  margin: 1em;
}
/* ul */
ul li {
  list-style: disc outside;
  /* Specific for Drupal */
}
ul li.leaf {
  padding: 0;
  margin: 0;
  border: 0;
  background-image: none;
  list-style: none;
}
ul.cc {
  /* Unstyled lists */
  /* Horizontal lists */
  /* Dotted lists */
}
ul.cc-unstyled li {
  list-style: none;
}
ul.cc-horizontal li {
  display: inline-block;
  margin: 0 1.5em;
}
@media screen and (max-width: 767px) {
  ul.cc-horizontal li {
    display: block;
    margin: 1em 0;
  }
}
ul.cc-dotted li {
  list-style: none;
  position: relative;
  padding-left: 15px;
}
ul.cc-dotted li:before {
  content: ' ';
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: #ff810d;
  top: 9px;
  left: 0px;
}
/* Ordered lists */
ol li {
  list-style: decimal outside;
  padding: 0;
  margin: 0;
  border: 0;
}
ol li:before {
  display: none;
}
/* ******** */
/** IMAGES **/
/* ******** */
img {
  max-width: 100%;
  height: auto;
}
img.cc {
  /* No responsive images */
  /* Bordered */
  /* Shadowed image */
}
img.cc-no-responsive {
  max-width: inherit;
}
img.cc-bordered {
  display: inline-block;
  background-color: white;
  padding: 5px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
img.cc-shadowed {
  display: inline-block;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
}
/* ******** */
/** ALERTS **/
/* ******** */
/* ********************* */
/*
    - General alerts styles
    - {type} : warning, error, success, info

    <div class="alert alert-{type}">
        <button data-close-alert role="button"><i class="fas fa-times"></i></button>
        <i class="fab fa-apple"></i> 
        <div>
            <strong>Primary color</strong> alert with <strong>FontAwesome icon</strong>.
        </div>
    </div>
*/
.alert,
.message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1em 0;
  font-size: 100%;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  /* Alerts types */
}
.alert > div,
.message > div {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 20px;
}
.alert > div a,
.message > div a {
  text-decoration: underline;
}
.alert > div ul,
.message > div ul {
  padding-left: 20px;
  margin: 1em auto;
}
.alert > div ul li,
.message > div ul li {
  list-style-position: inside;
}
.alert > i,
.message > i {
  padding: 20px;
  font-size: 3rem;
}
.alert [data-close-alert],
.message [data-close-alert] {
  border: 0;
  cursor: pointer;
  padding: 20px;
  opacity: 0.4;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2;
  background-color: transparent;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.alert [data-close-alert]:hover,
.message [data-close-alert]:hover {
  opacity: 1;
}
.alert [data-close-alert] i,
.message [data-close-alert] i {
  padding: 0;
  margin: 0;
  border: 0;
}
.alert [data-close-alert] i:before,
.message [data-close-alert] i:before {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 2.4rem;
}
.alert-primary,
.message-primary {
  border-top: 10px solid #ff810d;
  color: #ff810d;
}
.alert-primary p,
.alert-primary div,
.alert-primary ul,
.alert-primary [data-close-alert],
.alert-primary a,
.message-primary p,
.message-primary div,
.message-primary ul,
.message-primary [data-close-alert],
.message-primary a {
  color: #ff810d;
}
.alert-primary p.btn,
.alert-primary div.btn,
.alert-primary ul.btn,
.alert-primary [data-close-alert].btn,
.alert-primary a.btn,
.message-primary p.btn,
.message-primary div.btn,
.message-primary ul.btn,
.message-primary [data-close-alert].btn,
.message-primary a.btn {
  text-decoration: none;
  background-color: #ff810d;
  border-color: #ff810d;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  color: white;
}
.alert-primary p.btn:hover,
.alert-primary div.btn:hover,
.alert-primary ul.btn:hover,
.alert-primary [data-close-alert].btn:hover,
.alert-primary a.btn:hover,
.message-primary p.btn:hover,
.message-primary div.btn:hover,
.message-primary ul.btn:hover,
.message-primary [data-close-alert].btn:hover,
.message-primary a.btn:hover {
  background-color: #d96800;
  border-color: #d96800;
}
.alert-info,
.message-info {
  border-top: 10px solid #3a87ad;
  color: #3a87ad;
}
.alert-info p,
.alert-info div,
.alert-info ul,
.alert-info [data-close-alert],
.alert-info a,
.message-info p,
.message-info div,
.message-info ul,
.message-info [data-close-alert],
.message-info a {
  color: #3a87ad;
}
.alert-info p.btn,
.alert-info div.btn,
.alert-info ul.btn,
.alert-info [data-close-alert].btn,
.alert-info a.btn,
.message-info p.btn,
.message-info div.btn,
.message-info ul.btn,
.message-info [data-close-alert].btn,
.message-info a.btn {
  text-decoration: none;
  background-color: #3a87ad;
  border-color: #3a87ad;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  color: white;
}
.alert-info p.btn:hover,
.alert-info div.btn:hover,
.alert-info ul.btn:hover,
.alert-info [data-close-alert].btn:hover,
.alert-info a.btn:hover,
.message-info p.btn:hover,
.message-info div.btn:hover,
.message-info ul.btn:hover,
.message-info [data-close-alert].btn:hover,
.message-info a.btn:hover {
  background-color: #2d6987;
  border-color: #2d6987;
}
.alert-warning,
.message-warning {
  border-top: 10px solid #c09853;
  color: #c09853;
}
.alert-warning p,
.alert-warning div,
.alert-warning ul,
.alert-warning [data-close-alert],
.alert-warning a,
.message-warning p,
.message-warning div,
.message-warning ul,
.message-warning [data-close-alert],
.message-warning a {
  color: #c09853;
}
.alert-warning p.btn,
.alert-warning div.btn,
.alert-warning ul.btn,
.alert-warning [data-close-alert].btn,
.alert-warning a.btn,
.message-warning p.btn,
.message-warning div.btn,
.message-warning ul.btn,
.message-warning [data-close-alert].btn,
.message-warning a.btn {
  text-decoration: none;
  background-color: #c09853;
  border-color: #c09853;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  color: white;
}
.alert-warning p.btn:hover,
.alert-warning div.btn:hover,
.alert-warning ul.btn:hover,
.alert-warning [data-close-alert].btn:hover,
.alert-warning a.btn:hover,
.message-warning p.btn:hover,
.message-warning div.btn:hover,
.message-warning ul.btn:hover,
.message-warning [data-close-alert].btn:hover,
.message-warning a.btn:hover {
  background-color: #a47e3c;
  border-color: #a47e3c;
}
.alert-error,
.message-error {
  border-top: 10px solid #b94a48;
  color: #b94a48;
}
.alert-error p,
.alert-error div,
.alert-error ul,
.alert-error [data-close-alert],
.alert-error a,
.message-error p,
.message-error div,
.message-error ul,
.message-error [data-close-alert],
.message-error a {
  color: #b94a48;
}
.alert-error p.btn,
.alert-error div.btn,
.alert-error ul.btn,
.alert-error [data-close-alert].btn,
.alert-error a.btn,
.message-error p.btn,
.message-error div.btn,
.message-error ul.btn,
.message-error [data-close-alert].btn,
.message-error a.btn {
  text-decoration: none;
  background-color: #b94a48;
  border-color: #b94a48;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  color: white;
}
.alert-error p.btn:hover,
.alert-error div.btn:hover,
.alert-error ul.btn:hover,
.alert-error [data-close-alert].btn:hover,
.alert-error a.btn:hover,
.message-error p.btn:hover,
.message-error div.btn:hover,
.message-error ul.btn:hover,
.message-error [data-close-alert].btn:hover,
.message-error a.btn:hover {
  background-color: #953b39;
  border-color: #953b39;
}
.alert-success,
.message-success {
  border-top: 10px solid #468847;
  color: #468847;
}
.alert-success p,
.alert-success div,
.alert-success ul,
.alert-success [data-close-alert],
.alert-success a,
.message-success p,
.message-success div,
.message-success ul,
.message-success [data-close-alert],
.message-success a {
  color: #468847;
}
.alert-success p.btn,
.alert-success div.btn,
.alert-success ul.btn,
.alert-success [data-close-alert].btn,
.alert-success a.btn,
.message-success p.btn,
.message-success div.btn,
.message-success ul.btn,
.message-success [data-close-alert].btn,
.message-success a.btn {
  text-decoration: none;
  background-color: #468847;
  border-color: #468847;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  color: white;
}
.alert-success p.btn:hover,
.alert-success div.btn:hover,
.alert-success ul.btn:hover,
.alert-success [data-close-alert].btn:hover,
.alert-success a.btn:hover,
.message-success p.btn:hover,
.message-success div.btn:hover,
.message-success ul.btn:hover,
.message-success [data-close-alert].btn:hover,
.message-success a.btn:hover {
  background-color: #356635;
  border-color: #356635;
}
@media screen and (min-width: 768px) {
  .alert > i,
  .message > i {
    font-size: 6rem;
  }
}
/* ********** */
/** MESSAGES **/
/* ********** */
/*
    DEPRECIATED SINCE V3.4.0
    Use '.alert' instead
    Will be deleted in v4.0.0
*/
/* ******** */
/** MODALS **/
/* ******** */
/* ********************* */
/* Modal wrapper */
.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: -2;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  /* Shown modal */
  /* Sizes */
  /* Close button */
  /* Content */
  /* Overlay */
  /* Fixed Header/Footer */
}
.modal.active {
  opacity: 1;
  z-index: 99;
}
.modal.active .modal-content {
  opacity: 1;
  /* X != Y */
  /* X only */
  -webkit-transform: scale(1);
          transform: scale(1);
  /* X & Y empty : default value */
}
.modal.active .modal-close,
.modal.active > [data-close-modal] {
  -webkit-transition: 0.3s top ease-out 0.3s;
  transition: 0.3s top ease-out 0.3s;
  top: 8px;
}
@media screen and (min-width: 768px) {
  .modal.active .modal-close,
  .modal.active > [data-close-modal] {
    top: 20px;
  }
}
.modal.active .modal-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
@media screen and (min-width: 768px) {
  .modal.modal-large .modal-content {
    width: 80%;
  }
  .modal.modal-small .modal-content {
    width: 30%;
  }
}
.modal-close,
.modal > [data-close-modal] {
  padding: 0;
  margin: 0;
  border: 0;
  position: fixed;
  top: -60px;
  left: 50%;
  z-index: 2;
  background: none;
  border-radius: 100%;
  line-height: inherit;
  background-color: transparent;
  width: 40px;
  height: 40px;
  margin-left: -20px;
}
.modal-close:after,
.modal > [data-close-modal]:after,
.modal-close:before,
.modal > [data-close-modal]:before {
  display: inline-block;
  content: ' ';
  width: 19px;
  height: 3px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  top: 19px;
  left: 10px;
}
.modal-close:before,
.modal > [data-close-modal]:before {
  /* Not perspective */
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  /* Perspective */
}
.modal-close:after,
.modal > [data-close-modal]:after {
  /* Not perspective */
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  /* Perspective */
}
.modal-close:hover,
.modal > [data-close-modal]:hover {
  background-color: #ef6a6a;
}
.modal-content {
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  z-index: 2;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
  /* X != Y */
  /* X only */
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  /* X & Y empty : default value */
}
.modal-content .modal-body,
.modal-content .modal-footer,
.modal-content .modal-header {
  padding: 20px;
}
.modal-content .modal-header {
  border-bottom: 1px solid #cecece;
  color: #f9f9f9;
  text-transform: uppercase;
}
.modal-content .modal-footer {
  border-top: 1px solid #cecece;
}
@media screen and (min-width: 768px) {
  .modal-content {
    width: 50%;
  }
}
.modal-overlay {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.modal[data-fixed-hf] .modal-content {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.modal[data-fixed-hf] .modal-footer,
.modal[data-fixed-hf] .modal-header,
.modal[data-fixed-hf] .wrap-modal-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.modal[data-fixed-hf] .wrap-modal-body {
  overflow-y: auto;
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
}
.modal[data-fullscreen] {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.modal[data-fullscreen] .modal-content {
  width: 100vw;
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  border-radius: 0px;
}
@media screen and (min-width: 768px) {
  .modal[data-fullscreen] .modal-content {
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
  }
}
.modal[data-fullscreen] .modal-footer,
.modal[data-fullscreen] .modal-header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.modal[data-fullscreen] .wrap-modal-body {
  overflow-y: auto;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
/* 
    * OPENING EFFECTS *
*/
[data-open] {
  /*
        Combine with data-fullscreen
    */
}
[data-open].active .modal-content {
  -webkit-transform: translate3d(0%, 0%, 0%);
          transform: translate3d(0%, 0%, 0%);
}
[data-open] .modal-content {
  /* X != Y */
  /* X only */
  -webkit-transform: scale(1);
          transform: scale(1);
  /* X & Y empty : default value */
}
@media screen and (min-width: 768px) {
  [data-open][data-fullscreen].active.modal-large .modal-content {
    -webkit-transform: translate3d(0%, 0%, 0%);
            transform: translate3d(0%, 0%, 0%);
  }
}
[data-open][data-fullscreen] .modal-content {
  height: 100vh;
  max-height: 100vh;
  width: calc(100vw - 80px);
}
@media screen and (min-width: 768px) {
  [data-open][data-fullscreen] .modal-content {
    width: 50%;
  }
  [data-open][data-fullscreen].modal-small .modal-content {
    width: 30%;
  }
  [data-open][data-fullscreen].modal-large .modal-content {
    width: 80%;
  }
}
[data-open="slideInRight"] {
  /*
        Combine with data-fullscreen
    */
}
[data-open="slideInRight"] .modal-content {
  -webkit-transform: translate3d(200px, 0%, 0%);
          transform: translate3d(200px, 0%, 0%);
}
[data-open="slideInRight"][data-fullscreen] {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  /* Close button */
  /* Sizes */
}
[data-open="slideInRight"][data-fullscreen].active .modal-close,
[data-open="slideInRight"][data-fullscreen].active > [data-close-modal] {
  left: 20px;
  -webkit-transition: 0.3s left ease-out 0.3s;
  transition: 0.3s left ease-out 0.3s;
}
[data-open="slideInRight"][data-fullscreen] .modal-close,
[data-open="slideInRight"][data-fullscreen] > [data-close-modal] {
  left: -100px;
  margin-left: 0;
}
@media screen and (min-width: 768px) {
  [data-open="slideInRight"][data-fullscreen].modal-large .modal-content {
    -webkit-transform: translate3d(400px, 0%, 0%);
            transform: translate3d(400px, 0%, 0%);
  }
}
[data-open="slideInLeft"] {
  /*
        Combine with data-fullscreen
    */
}
[data-open="slideInLeft"] .modal-content {
  -webkit-transform: translate3d(-200px, 0%, 0%);
          transform: translate3d(-200px, 0%, 0%);
}
[data-open="slideInLeft"][data-fullscreen] {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  /* Close button */
  /* Sizes */
}
[data-open="slideInLeft"][data-fullscreen].active .modal-close,
[data-open="slideInLeft"][data-fullscreen].active > [data-close-modal] {
  right: 20px;
  -webkit-transition: 0.3s right ease-out 0.3s;
  transition: 0.3s right ease-out 0.3s;
}
[data-open="slideInLeft"][data-fullscreen] .modal-close,
[data-open="slideInLeft"][data-fullscreen] > [data-close-modal] {
  left: initial;
  right: -100px;
}
@media screen and (min-width: 768px) {
  [data-open="slideInLeft"][data-fullscreen].modal-large .modal-content {
    -webkit-transform: translate3d(-400px, 0%, 0%);
            transform: translate3d(-400px, 0%, 0%);
  }
}
/*
    == HELPERS ==
*/
.cc {
  /* Width */
  /* Clearfix */
}
.cc-block {
  display: block;
}
.cc-inline {
  display: inline-block;
}
.cc-inside {
  width: 1199px;
  margin: 0 auto;
  -webkit-transition: 0.2s width ease-out 0s;
  transition: 0.2s width ease-out 0s;
}
.cc-centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cc-hidden {
  display: none;
}
@media screen and (max-width: 359px) and (min-width: 0px) {
  .cc-hidden-xs-only {
    display: none;
  }
}
@media screen and (max-width: 359px) {
  .cc-hidden-xs-down {
    display: none;
  }
}
@media screen and (min-width: 360px) {
  .cc-hidden-s {
    display: none;
  }
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  .cc-hidden-s-only {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .cc-hidden-s-down {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .cc-hidden-m {
    display: none;
  }
}
@media screen and (max-width: 989px) and (min-width: 768px) {
  .cc-hidden-m-only {
    display: none;
  }
}
@media screen and (max-width: 989px) {
  .cc-hidden-m-down {
    display: none;
  }
}
@media screen and (min-width: 990px) {
  .cc-hidden-l {
    display: none;
  }
}
@media screen and (max-width: 1199px) and (min-width: 990px) {
  .cc-hidden-l-only {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .cc-hidden-l-down {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .cc-hidden-xl {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .cc-hidden-xl-only {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .cc-shown-xl-only {
    display: none;
  }
}
.cc-shown-l-only {
  display: none;
}
@media screen and (max-width: 1199px) and (min-width: 990px) {
  .cc-shown-l-only {
    display: block;
  }
}
.cc-shown-m-only {
  display: none;
}
@media screen and (max-width: 989px) and (min-width: 768px) {
  .cc-shown-m-only {
    display: block;
  }
}
.cc-shown-s-only {
  display: none;
}
@media screen and (max-width: 767px) and (min-width: 360px) {
  .cc-shown-s-only {
    display: block;
  }
}
.cc-shown-xs-only {
  display: none;
}
@media screen and (max-width: 359px) and (min-width: 0px) {
  .cc-shown-xs-only {
    display: block;
  }
}
.cc-w-third {
  width: 33.333%;
}
.cc-w-twothird {
  width: 66.666%;
}
.cc-clearfix {
  zoom: 1;
}
.cc-clearfix:before,
.cc-clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.cc-clearfix:after {
  clear: both;
}
/*
    == FONTAWESOME ==
*/
.fa.icon-left,
.far.icon-left,
.fal.icon-left,
.fas.icon-left,
.fab.icon-left,
[class*="ion-"].icon-left {
  margin-right: 8px;
}
.fa.icon-right,
.far.icon-right,
.fal.icon-right,
.fas.icon-right,
.fab.icon-right,
[class*="ion-"].icon-right {
  margin-left: 8px;
}
/*
    == GENERATORS ==
*/
/*
** Mixins list **
    ._generateColorsClass
    ._generatePaddingMarginClass
    ._generateColumns
    ._generatePercentWidth
    ._generateFontSize
    ._generateFontWeight
    ._generateTextsAligments
*/
/* *****
    @name : ._generateColorsClass
    @description : Generate colors styles when using "@{css-prefix}-{color}" class on an element
    @params : @aColors : array built in settings.less file
***** */
/* titles */
h1.cc-primary,
h2.cc-primary,
h3.cc-primary,
h4.cc-primary,
h5.cc-primary,
h6.cc-primary,
.h1-like.cc-primary,
.h2-like.cc-primary,
.h3-like.cc-primary,
.h4-like.cc-primary,
.h5-like.cc-primary,
.h6-like.cc-primary {
  color: #ff810d;
}
h1.cc-bg-primary,
h2.cc-bg-primary,
h3.cc-bg-primary,
h4.cc-bg-primary,
h5.cc-bg-primary,
h6.cc-bg-primary,
.h1-like.cc-bg-primary,
.h2-like.cc-bg-primary,
.h3-like.cc-bg-primary,
.h4-like.cc-bg-primary,
.h5-like.cc-bg-primary,
.h6-like.cc-bg-primary {
  color: white;
  background-color: #ff810d;
}
/* elements */
p.cc-primary,
div.cc-primary,
section.cc-primary,
aside.cc-primary,
header.cc-primary,
footer.cc-primary,
article.cc-primary,
main.cc-primary,
nav.cc-primary,
td.cc-primary,
th.cc-primary,
span.cc-primary,
i.cc-primary,
a.cc-primary,
strong.cc-primary,
em.cc-primary,
code.cc-primary,
small.cc-primary {
  color: #ff810d;
}
p.cc-bg-primary,
div.cc-bg-primary,
section.cc-bg-primary,
aside.cc-bg-primary,
header.cc-bg-primary,
footer.cc-bg-primary,
article.cc-bg-primary,
main.cc-bg-primary,
nav.cc-bg-primary,
td.cc-bg-primary,
th.cc-bg-primary,
span.cc-bg-primary,
i.cc-bg-primary,
a.cc-bg-primary,
strong.cc-bg-primary,
em.cc-bg-primary,
code.cc-bg-primary,
small.cc-bg-primary {
  color: white;
  background-color: #ff810d;
}
p.cc-bg-primary a:not(.btn),
div.cc-bg-primary a:not(.btn),
section.cc-bg-primary a:not(.btn),
aside.cc-bg-primary a:not(.btn),
header.cc-bg-primary a:not(.btn),
footer.cc-bg-primary a:not(.btn),
article.cc-bg-primary a:not(.btn),
main.cc-bg-primary a:not(.btn),
nav.cc-bg-primary a:not(.btn),
td.cc-bg-primary a:not(.btn),
th.cc-bg-primary a:not(.btn),
span.cc-bg-primary a:not(.btn),
i.cc-bg-primary a:not(.btn),
a.cc-bg-primary a:not(.btn),
strong.cc-bg-primary a:not(.btn),
em.cc-bg-primary a:not(.btn),
code.cc-bg-primary a:not(.btn),
small.cc-bg-primary a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-primary {
  background-color: #ff810d;
  color: white;
}
li.cc-primary:before {
  color: #ff810d;
}
/* blockquotes */
blockquote.cc-primary {
  border-left: 4px solid #ff810d;
}
blockquote.cc-primary.cc-quoted:before {
  color: #ff810d;
}
blockquote.cc-primary.cc-reverse {
  border-left-width: 0px;
  border-right-color: #ff810d;
}
/* Tables */
table.cc-primary {
  /* Bordered & Colored tables */
}
table.cc-primary thead td,
table.cc-primary tfoot td,
table.cc-primary thead th,
table.cc-primary tfoot th {
  background-color: #ff810d;
  color: white;
  border-bottom-color: #a65000;
}
table.cc-primary tbody tr td {
  border-bottom: 1px solid #ff810d;
}
table.cc-primary.cc-bordered tfoot td,
table.cc-primary.cc-bordered thead td,
table.cc-primary.cc-bordered tfoot th,
table.cc-primary.cc-bordered thead th {
  border-color: #d96800;
}
table.cc-primary.cc-bordered tbody tr td {
  border-color: #d96800;
}
table tr.cc-bg-primary td {
  background-color: #ff810d;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-primary,
button.cc-bg-primary {
  color: white;
  background-color: #ff810d;
  border-color: #ff810d;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-primary:hover,
button.cc-bg-primary:hover {
  background-color: #d96800;
  border-color: #d96800;
}
a.btn.cc-bg-primary:hover,
button.cc-bg-primary:hover {
  color: white;
}
a.btn.cc-bg-primary.cc-loading:before,
button.cc-bg-primary.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-primary.cc-outlined,
button.cc-bg-primary.cc-outlined {
  color: #ff810d;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #ff810d;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-primary.cc-outlined:hover,
button.cc-bg-primary.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-primary.cc-outlined:hover,
button.cc-bg-primary.cc-outlined:hover {
  border-color: #a65000;
  color: #a65000;
}
a.btn.cc-bg-primary.cc-outlined.cc-loading,
button.cc-bg-primary.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-primary.cc-outlined.cc-loading:before,
button.cc-bg-primary.cc-outlined.cc-loading:before {
  border-color: transparent #ff810d #ff810d #ff810d;
}
/* Colored forms */
form.cc-primary .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-primary .form-checkbox:not(.error) input:checked {
  background-color: #ff810d;
  border-color: #ff810d;
}
form.cc-primary .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-primary .form-radio:not(.error) input:checked:before {
  background-color: #ff810d;
}
form.cc-primary .form-ps span {
  background-color: #ff810d;
}
form.cc-primary select:focus,
form.cc-primary textarea:focus,
form.cc-primary input:focus {
  border-color: #ff810d;
}
form.cc-primary input[type="submit"],
form.cc-primary button[type="submit"] {
  background-color: #ff810d;
  border-color: #ff810d;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-primary input[type="submit"]:hover,
form.cc-primary button[type="submit"]:hover {
  background-color: #d96800;
  border-color: #d96800;
}
/* Tabs */
.tabs.cc-primary {
  /* Small screen ruleset */
}
.tabs.cc-primary.cc-bordered > li:hover a:not(.btn),
.tabs.cc-primary.cc-bordered > li.active a:not(.btn) {
  border-color: #ff810d #ff810d white #ff810d;
}
.tabs.cc-primary.cc-pills > li.active a:not(.btn),
.tabs.cc-primary.cc-pills > li:hover a:not(.btn) {
  background-color: #ff810d;
  border-top: 1px solid #ff810d;
  border-bottom: 1px solid #ff810d;
  color: white;
}
.tabs.cc-primary > li {
  /* Active child */
}
.tabs.cc-primary > li:hover a:not(.btn),
.tabs.cc-primary > li.active a:not(.btn) {
  color: #ff810d;
  border-bottom-color: #ff810d;
}
@media screen and (max-width: 767px) {
  .tabs.cc-primary.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-primary.cc-bordered > li.active a:not(.btn) {
    border-color: #ff810d;
  }
  .tabs.cc-primary.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-primary.cc-pills > li.active a:not(.btn),
  .tabs.cc-primary.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-primary.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #ff810d;
  }
}
/* titles */
h1.cc-green,
h2.cc-green,
h3.cc-green,
h4.cc-green,
h5.cc-green,
h6.cc-green,
.h1-like.cc-green,
.h2-like.cc-green,
.h3-like.cc-green,
.h4-like.cc-green,
.h5-like.cc-green,
.h6-like.cc-green {
  color: #5C7D2B;
}
h1.cc-bg-green,
h2.cc-bg-green,
h3.cc-bg-green,
h4.cc-bg-green,
h5.cc-bg-green,
h6.cc-bg-green,
.h1-like.cc-bg-green,
.h2-like.cc-bg-green,
.h3-like.cc-bg-green,
.h4-like.cc-bg-green,
.h5-like.cc-bg-green,
.h6-like.cc-bg-green {
  color: white;
  background-color: #5C7D2B;
}
/* elements */
p.cc-green,
div.cc-green,
section.cc-green,
aside.cc-green,
header.cc-green,
footer.cc-green,
article.cc-green,
main.cc-green,
nav.cc-green,
td.cc-green,
th.cc-green,
span.cc-green,
i.cc-green,
a.cc-green,
strong.cc-green,
em.cc-green,
code.cc-green,
small.cc-green {
  color: #5C7D2B;
}
p.cc-bg-green,
div.cc-bg-green,
section.cc-bg-green,
aside.cc-bg-green,
header.cc-bg-green,
footer.cc-bg-green,
article.cc-bg-green,
main.cc-bg-green,
nav.cc-bg-green,
td.cc-bg-green,
th.cc-bg-green,
span.cc-bg-green,
i.cc-bg-green,
a.cc-bg-green,
strong.cc-bg-green,
em.cc-bg-green,
code.cc-bg-green,
small.cc-bg-green {
  color: white;
  background-color: #5C7D2B;
}
p.cc-bg-green a:not(.btn),
div.cc-bg-green a:not(.btn),
section.cc-bg-green a:not(.btn),
aside.cc-bg-green a:not(.btn),
header.cc-bg-green a:not(.btn),
footer.cc-bg-green a:not(.btn),
article.cc-bg-green a:not(.btn),
main.cc-bg-green a:not(.btn),
nav.cc-bg-green a:not(.btn),
td.cc-bg-green a:not(.btn),
th.cc-bg-green a:not(.btn),
span.cc-bg-green a:not(.btn),
i.cc-bg-green a:not(.btn),
a.cc-bg-green a:not(.btn),
strong.cc-bg-green a:not(.btn),
em.cc-bg-green a:not(.btn),
code.cc-bg-green a:not(.btn),
small.cc-bg-green a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-green {
  background-color: #5C7D2B;
  color: white;
}
li.cc-green:before {
  color: #5C7D2B;
}
/* blockquotes */
blockquote.cc-green {
  border-left: 4px solid #5C7D2B;
}
blockquote.cc-green.cc-quoted:before {
  color: #5C7D2B;
}
blockquote.cc-green.cc-reverse {
  border-left-width: 0px;
  border-right-color: #5C7D2B;
}
/* Tables */
table.cc-green {
  /* Bordered & Colored tables */
}
table.cc-green thead td,
table.cc-green tfoot td,
table.cc-green thead th,
table.cc-green tfoot th {
  background-color: #5C7D2B;
  color: white;
  border-bottom-color: #243111;
}
table.cc-green tbody tr td {
  border-bottom: 1px solid #5C7D2B;
}
table.cc-green.cc-bordered tfoot td,
table.cc-green.cc-bordered thead td,
table.cc-green.cc-bordered tfoot th,
table.cc-green.cc-bordered thead th {
  border-color: #40571e;
}
table.cc-green.cc-bordered tbody tr td {
  border-color: #40571e;
}
table tr.cc-bg-green td {
  background-color: #5C7D2B;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-green,
button.cc-bg-green {
  color: white;
  background-color: #5C7D2B;
  border-color: #5C7D2B;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-green:hover,
button.cc-bg-green:hover {
  background-color: #40571e;
  border-color: #40571e;
}
a.btn.cc-bg-green:hover,
button.cc-bg-green:hover {
  color: white;
}
a.btn.cc-bg-green.cc-loading:before,
button.cc-bg-green.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-green.cc-outlined,
button.cc-bg-green.cc-outlined {
  color: #5C7D2B;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #5C7D2B;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-green.cc-outlined:hover,
button.cc-bg-green.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-green.cc-outlined:hover,
button.cc-bg-green.cc-outlined:hover {
  border-color: #243111;
  color: #243111;
}
a.btn.cc-bg-green.cc-outlined.cc-loading,
button.cc-bg-green.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-green.cc-outlined.cc-loading:before,
button.cc-bg-green.cc-outlined.cc-loading:before {
  border-color: transparent #5C7D2B #5C7D2B #5C7D2B;
}
/* Colored forms */
form.cc-green .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-green .form-checkbox:not(.error) input:checked {
  background-color: #5C7D2B;
  border-color: #5C7D2B;
}
form.cc-green .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-green .form-radio:not(.error) input:checked:before {
  background-color: #5C7D2B;
}
form.cc-green .form-ps span {
  background-color: #5C7D2B;
}
form.cc-green select:focus,
form.cc-green textarea:focus,
form.cc-green input:focus {
  border-color: #5C7D2B;
}
form.cc-green input[type="submit"],
form.cc-green button[type="submit"] {
  background-color: #5C7D2B;
  border-color: #5C7D2B;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-green input[type="submit"]:hover,
form.cc-green button[type="submit"]:hover {
  background-color: #40571e;
  border-color: #40571e;
}
/* Tabs */
.tabs.cc-green {
  /* Small screen ruleset */
}
.tabs.cc-green.cc-bordered > li:hover a:not(.btn),
.tabs.cc-green.cc-bordered > li.active a:not(.btn) {
  border-color: #5C7D2B #5C7D2B white #5C7D2B;
}
.tabs.cc-green.cc-pills > li.active a:not(.btn),
.tabs.cc-green.cc-pills > li:hover a:not(.btn) {
  background-color: #5C7D2B;
  border-top: 1px solid #5C7D2B;
  border-bottom: 1px solid #5C7D2B;
  color: white;
}
.tabs.cc-green > li {
  /* Active child */
}
.tabs.cc-green > li:hover a:not(.btn),
.tabs.cc-green > li.active a:not(.btn) {
  color: #5C7D2B;
  border-bottom-color: #5C7D2B;
}
@media screen and (max-width: 767px) {
  .tabs.cc-green.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-green.cc-bordered > li.active a:not(.btn) {
    border-color: #5C7D2B;
  }
  .tabs.cc-green.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-green.cc-pills > li.active a:not(.btn),
  .tabs.cc-green.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-green.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #5C7D2B;
  }
}
/* titles */
h1.cc-red,
h2.cc-red,
h3.cc-red,
h4.cc-red,
h5.cc-red,
h6.cc-red,
.h1-like.cc-red,
.h2-like.cc-red,
.h3-like.cc-red,
.h4-like.cc-red,
.h5-like.cc-red,
.h6-like.cc-red {
  color: #c41515;
}
h1.cc-bg-red,
h2.cc-bg-red,
h3.cc-bg-red,
h4.cc-bg-red,
h5.cc-bg-red,
h6.cc-bg-red,
.h1-like.cc-bg-red,
.h2-like.cc-bg-red,
.h3-like.cc-bg-red,
.h4-like.cc-bg-red,
.h5-like.cc-bg-red,
.h6-like.cc-bg-red {
  color: white;
  background-color: #c41515;
}
/* elements */
p.cc-red,
div.cc-red,
section.cc-red,
aside.cc-red,
header.cc-red,
footer.cc-red,
article.cc-red,
main.cc-red,
nav.cc-red,
td.cc-red,
th.cc-red,
span.cc-red,
i.cc-red,
a.cc-red,
strong.cc-red,
em.cc-red,
code.cc-red,
small.cc-red {
  color: #c41515;
}
p.cc-bg-red,
div.cc-bg-red,
section.cc-bg-red,
aside.cc-bg-red,
header.cc-bg-red,
footer.cc-bg-red,
article.cc-bg-red,
main.cc-bg-red,
nav.cc-bg-red,
td.cc-bg-red,
th.cc-bg-red,
span.cc-bg-red,
i.cc-bg-red,
a.cc-bg-red,
strong.cc-bg-red,
em.cc-bg-red,
code.cc-bg-red,
small.cc-bg-red {
  color: white;
  background-color: #c41515;
}
p.cc-bg-red a:not(.btn),
div.cc-bg-red a:not(.btn),
section.cc-bg-red a:not(.btn),
aside.cc-bg-red a:not(.btn),
header.cc-bg-red a:not(.btn),
footer.cc-bg-red a:not(.btn),
article.cc-bg-red a:not(.btn),
main.cc-bg-red a:not(.btn),
nav.cc-bg-red a:not(.btn),
td.cc-bg-red a:not(.btn),
th.cc-bg-red a:not(.btn),
span.cc-bg-red a:not(.btn),
i.cc-bg-red a:not(.btn),
a.cc-bg-red a:not(.btn),
strong.cc-bg-red a:not(.btn),
em.cc-bg-red a:not(.btn),
code.cc-bg-red a:not(.btn),
small.cc-bg-red a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-red {
  background-color: #c41515;
  color: white;
}
li.cc-red:before {
  color: #c41515;
}
/* blockquotes */
blockquote.cc-red {
  border-left: 4px solid #c41515;
}
blockquote.cc-red.cc-quoted:before {
  color: #c41515;
}
blockquote.cc-red.cc-reverse {
  border-left-width: 0px;
  border-right-color: #c41515;
}
/* Tables */
table.cc-red {
  /* Bordered & Colored tables */
}
table.cc-red thead td,
table.cc-red tfoot td,
table.cc-red thead th,
table.cc-red tfoot th {
  background-color: #c41515;
  color: white;
  border-bottom-color: #680b0b;
}
table.cc-red tbody tr td {
  border-bottom: 1px solid #c41515;
}
table.cc-red.cc-bordered tfoot td,
table.cc-red.cc-bordered thead td,
table.cc-red.cc-bordered tfoot th,
table.cc-red.cc-bordered thead th {
  border-color: #961010;
}
table.cc-red.cc-bordered tbody tr td {
  border-color: #961010;
}
table tr.cc-bg-red td {
  background-color: #c41515;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-red,
button.cc-bg-red {
  color: white;
  background-color: #c41515;
  border-color: #c41515;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-red:hover,
button.cc-bg-red:hover {
  background-color: #961010;
  border-color: #961010;
}
a.btn.cc-bg-red:hover,
button.cc-bg-red:hover {
  color: white;
}
a.btn.cc-bg-red.cc-loading:before,
button.cc-bg-red.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-red.cc-outlined,
button.cc-bg-red.cc-outlined {
  color: #c41515;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #c41515;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-red.cc-outlined:hover,
button.cc-bg-red.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-red.cc-outlined:hover,
button.cc-bg-red.cc-outlined:hover {
  border-color: #680b0b;
  color: #680b0b;
}
a.btn.cc-bg-red.cc-outlined.cc-loading,
button.cc-bg-red.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-red.cc-outlined.cc-loading:before,
button.cc-bg-red.cc-outlined.cc-loading:before {
  border-color: transparent #c41515 #c41515 #c41515;
}
/* Colored forms */
form.cc-red .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-red .form-checkbox:not(.error) input:checked {
  background-color: #c41515;
  border-color: #c41515;
}
form.cc-red .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-red .form-radio:not(.error) input:checked:before {
  background-color: #c41515;
}
form.cc-red .form-ps span {
  background-color: #c41515;
}
form.cc-red select:focus,
form.cc-red textarea:focus,
form.cc-red input:focus {
  border-color: #c41515;
}
form.cc-red input[type="submit"],
form.cc-red button[type="submit"] {
  background-color: #c41515;
  border-color: #c41515;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-red input[type="submit"]:hover,
form.cc-red button[type="submit"]:hover {
  background-color: #961010;
  border-color: #961010;
}
/* Tabs */
.tabs.cc-red {
  /* Small screen ruleset */
}
.tabs.cc-red.cc-bordered > li:hover a:not(.btn),
.tabs.cc-red.cc-bordered > li.active a:not(.btn) {
  border-color: #c41515 #c41515 white #c41515;
}
.tabs.cc-red.cc-pills > li.active a:not(.btn),
.tabs.cc-red.cc-pills > li:hover a:not(.btn) {
  background-color: #c41515;
  border-top: 1px solid #c41515;
  border-bottom: 1px solid #c41515;
  color: white;
}
.tabs.cc-red > li {
  /* Active child */
}
.tabs.cc-red > li:hover a:not(.btn),
.tabs.cc-red > li.active a:not(.btn) {
  color: #c41515;
  border-bottom-color: #c41515;
}
@media screen and (max-width: 767px) {
  .tabs.cc-red.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-red.cc-bordered > li.active a:not(.btn) {
    border-color: #c41515;
  }
  .tabs.cc-red.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-red.cc-pills > li.active a:not(.btn),
  .tabs.cc-red.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-red.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #c41515;
  }
}
/* titles */
h1.cc-gray,
h2.cc-gray,
h3.cc-gray,
h4.cc-gray,
h5.cc-gray,
h6.cc-gray,
.h1-like.cc-gray,
.h2-like.cc-gray,
.h3-like.cc-gray,
.h4-like.cc-gray,
.h5-like.cc-gray,
.h6-like.cc-gray {
  color: #f9f9f9;
}
h1.cc-bg-gray,
h2.cc-bg-gray,
h3.cc-bg-gray,
h4.cc-bg-gray,
h5.cc-bg-gray,
h6.cc-bg-gray,
.h1-like.cc-bg-gray,
.h2-like.cc-bg-gray,
.h3-like.cc-bg-gray,
.h4-like.cc-bg-gray,
.h5-like.cc-bg-gray,
.h6-like.cc-bg-gray {
  color: white;
  background-color: #f9f9f9;
}
/* elements */
p.cc-gray,
div.cc-gray,
section.cc-gray,
aside.cc-gray,
header.cc-gray,
footer.cc-gray,
article.cc-gray,
main.cc-gray,
nav.cc-gray,
td.cc-gray,
th.cc-gray,
span.cc-gray,
i.cc-gray,
a.cc-gray,
strong.cc-gray,
em.cc-gray,
code.cc-gray,
small.cc-gray {
  color: #f9f9f9;
}
p.cc-bg-gray,
div.cc-bg-gray,
section.cc-bg-gray,
aside.cc-bg-gray,
header.cc-bg-gray,
footer.cc-bg-gray,
article.cc-bg-gray,
main.cc-bg-gray,
nav.cc-bg-gray,
td.cc-bg-gray,
th.cc-bg-gray,
span.cc-bg-gray,
i.cc-bg-gray,
a.cc-bg-gray,
strong.cc-bg-gray,
em.cc-bg-gray,
code.cc-bg-gray,
small.cc-bg-gray {
  color: white;
  background-color: #f9f9f9;
}
p.cc-bg-gray a:not(.btn),
div.cc-bg-gray a:not(.btn),
section.cc-bg-gray a:not(.btn),
aside.cc-bg-gray a:not(.btn),
header.cc-bg-gray a:not(.btn),
footer.cc-bg-gray a:not(.btn),
article.cc-bg-gray a:not(.btn),
main.cc-bg-gray a:not(.btn),
nav.cc-bg-gray a:not(.btn),
td.cc-bg-gray a:not(.btn),
th.cc-bg-gray a:not(.btn),
span.cc-bg-gray a:not(.btn),
i.cc-bg-gray a:not(.btn),
a.cc-bg-gray a:not(.btn),
strong.cc-bg-gray a:not(.btn),
em.cc-bg-gray a:not(.btn),
code.cc-bg-gray a:not(.btn),
small.cc-bg-gray a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-gray {
  background-color: #f9f9f9;
  color: white;
}
li.cc-gray:before {
  color: #f9f9f9;
}
/* blockquotes */
blockquote.cc-gray {
  border-left: 4px solid #f9f9f9;
}
blockquote.cc-gray.cc-quoted:before {
  color: #f9f9f9;
}
blockquote.cc-gray.cc-reverse {
  border-left-width: 0px;
  border-right-color: #f9f9f9;
}
/* Tables */
table.cc-gray {
  /* Bordered & Colored tables */
}
table.cc-gray thead td,
table.cc-gray tfoot td,
table.cc-gray thead th,
table.cc-gray tfoot th {
  background-color: #f9f9f9;
  color: white;
  border-bottom-color: #c6c6c6;
}
table.cc-gray tbody tr td {
  border-bottom: 1px solid #f9f9f9;
}
table.cc-gray.cc-bordered tfoot td,
table.cc-gray.cc-bordered thead td,
table.cc-gray.cc-bordered tfoot th,
table.cc-gray.cc-bordered thead th {
  border-color: #e0e0e0;
}
table.cc-gray.cc-bordered tbody tr td {
  border-color: #e0e0e0;
}
table tr.cc-bg-gray td {
  background-color: #f9f9f9;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-gray,
button.cc-bg-gray {
  color: white;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-gray:hover,
button.cc-bg-gray:hover {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
a.btn.cc-bg-gray:hover,
button.cc-bg-gray:hover {
  color: white;
}
a.btn.cc-bg-gray.cc-loading:before,
button.cc-bg-gray.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-gray.cc-outlined,
button.cc-bg-gray.cc-outlined {
  color: #f9f9f9;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #f9f9f9;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-gray.cc-outlined:hover,
button.cc-bg-gray.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-gray.cc-outlined:hover,
button.cc-bg-gray.cc-outlined:hover {
  border-color: #c6c6c6;
  color: #c6c6c6;
}
a.btn.cc-bg-gray.cc-outlined.cc-loading,
button.cc-bg-gray.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-gray.cc-outlined.cc-loading:before,
button.cc-bg-gray.cc-outlined.cc-loading:before {
  border-color: transparent #f9f9f9 #f9f9f9 #f9f9f9;
}
/* Colored forms */
form.cc-gray .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-gray .form-checkbox:not(.error) input:checked {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
form.cc-gray .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-gray .form-radio:not(.error) input:checked:before {
  background-color: #f9f9f9;
}
form.cc-gray .form-ps span {
  background-color: #f9f9f9;
}
form.cc-gray select:focus,
form.cc-gray textarea:focus,
form.cc-gray input:focus {
  border-color: #f9f9f9;
}
form.cc-gray input[type="submit"],
form.cc-gray button[type="submit"] {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-gray input[type="submit"]:hover,
form.cc-gray button[type="submit"]:hover {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
/* Tabs */
.tabs.cc-gray {
  /* Small screen ruleset */
}
.tabs.cc-gray.cc-bordered > li:hover a:not(.btn),
.tabs.cc-gray.cc-bordered > li.active a:not(.btn) {
  border-color: #f9f9f9 #f9f9f9 white #f9f9f9;
}
.tabs.cc-gray.cc-pills > li.active a:not(.btn),
.tabs.cc-gray.cc-pills > li:hover a:not(.btn) {
  background-color: #f9f9f9;
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  color: white;
}
.tabs.cc-gray > li {
  /* Active child */
}
.tabs.cc-gray > li:hover a:not(.btn),
.tabs.cc-gray > li.active a:not(.btn) {
  color: #f9f9f9;
  border-bottom-color: #f9f9f9;
}
@media screen and (max-width: 767px) {
  .tabs.cc-gray.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-gray.cc-bordered > li.active a:not(.btn) {
    border-color: #f9f9f9;
  }
  .tabs.cc-gray.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-gray.cc-pills > li.active a:not(.btn),
  .tabs.cc-gray.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-gray.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #f9f9f9;
  }
}
/* titles */
h1.cc-blue,
h2.cc-blue,
h3.cc-blue,
h4.cc-blue,
h5.cc-blue,
h6.cc-blue,
.h1-like.cc-blue,
.h2-like.cc-blue,
.h3-like.cc-blue,
.h4-like.cc-blue,
.h5-like.cc-blue,
.h6-like.cc-blue {
  color: #3498db;
}
h1.cc-bg-blue,
h2.cc-bg-blue,
h3.cc-bg-blue,
h4.cc-bg-blue,
h5.cc-bg-blue,
h6.cc-bg-blue,
.h1-like.cc-bg-blue,
.h2-like.cc-bg-blue,
.h3-like.cc-bg-blue,
.h4-like.cc-bg-blue,
.h5-like.cc-bg-blue,
.h6-like.cc-bg-blue {
  color: white;
  background-color: #3498db;
}
/* elements */
p.cc-blue,
div.cc-blue,
section.cc-blue,
aside.cc-blue,
header.cc-blue,
footer.cc-blue,
article.cc-blue,
main.cc-blue,
nav.cc-blue,
td.cc-blue,
th.cc-blue,
span.cc-blue,
i.cc-blue,
a.cc-blue,
strong.cc-blue,
em.cc-blue,
code.cc-blue,
small.cc-blue {
  color: #3498db;
}
p.cc-bg-blue,
div.cc-bg-blue,
section.cc-bg-blue,
aside.cc-bg-blue,
header.cc-bg-blue,
footer.cc-bg-blue,
article.cc-bg-blue,
main.cc-bg-blue,
nav.cc-bg-blue,
td.cc-bg-blue,
th.cc-bg-blue,
span.cc-bg-blue,
i.cc-bg-blue,
a.cc-bg-blue,
strong.cc-bg-blue,
em.cc-bg-blue,
code.cc-bg-blue,
small.cc-bg-blue {
  color: white;
  background-color: #3498db;
}
p.cc-bg-blue a:not(.btn),
div.cc-bg-blue a:not(.btn),
section.cc-bg-blue a:not(.btn),
aside.cc-bg-blue a:not(.btn),
header.cc-bg-blue a:not(.btn),
footer.cc-bg-blue a:not(.btn),
article.cc-bg-blue a:not(.btn),
main.cc-bg-blue a:not(.btn),
nav.cc-bg-blue a:not(.btn),
td.cc-bg-blue a:not(.btn),
th.cc-bg-blue a:not(.btn),
span.cc-bg-blue a:not(.btn),
i.cc-bg-blue a:not(.btn),
a.cc-bg-blue a:not(.btn),
strong.cc-bg-blue a:not(.btn),
em.cc-bg-blue a:not(.btn),
code.cc-bg-blue a:not(.btn),
small.cc-bg-blue a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-blue {
  background-color: #3498db;
  color: white;
}
li.cc-blue:before {
  color: #3498db;
}
/* blockquotes */
blockquote.cc-blue {
  border-left: 4px solid #3498db;
}
blockquote.cc-blue.cc-quoted:before {
  color: #3498db;
}
blockquote.cc-blue.cc-reverse {
  border-left-width: 0px;
  border-right-color: #3498db;
}
/* Tables */
table.cc-blue {
  /* Bordered & Colored tables */
}
table.cc-blue thead td,
table.cc-blue tfoot td,
table.cc-blue thead th,
table.cc-blue tfoot th {
  background-color: #3498db;
  color: white;
  border-bottom-color: #196090;
}
table.cc-blue tbody tr td {
  border-bottom: 1px solid #3498db;
}
table.cc-blue.cc-bordered tfoot td,
table.cc-blue.cc-bordered thead td,
table.cc-blue.cc-bordered tfoot th,
table.cc-blue.cc-bordered thead th {
  border-color: #217dbb;
}
table.cc-blue.cc-bordered tbody tr td {
  border-color: #217dbb;
}
table tr.cc-bg-blue td {
  background-color: #3498db;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-blue,
button.cc-bg-blue {
  color: white;
  background-color: #3498db;
  border-color: #3498db;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-blue:hover,
button.cc-bg-blue:hover {
  background-color: #217dbb;
  border-color: #217dbb;
}
a.btn.cc-bg-blue:hover,
button.cc-bg-blue:hover {
  color: white;
}
a.btn.cc-bg-blue.cc-loading:before,
button.cc-bg-blue.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-blue.cc-outlined,
button.cc-bg-blue.cc-outlined {
  color: #3498db;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #3498db;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-blue.cc-outlined:hover,
button.cc-bg-blue.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-blue.cc-outlined:hover,
button.cc-bg-blue.cc-outlined:hover {
  border-color: #196090;
  color: #196090;
}
a.btn.cc-bg-blue.cc-outlined.cc-loading,
button.cc-bg-blue.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-blue.cc-outlined.cc-loading:before,
button.cc-bg-blue.cc-outlined.cc-loading:before {
  border-color: transparent #3498db #3498db #3498db;
}
/* Colored forms */
form.cc-blue .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-blue .form-checkbox:not(.error) input:checked {
  background-color: #3498db;
  border-color: #3498db;
}
form.cc-blue .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-blue .form-radio:not(.error) input:checked:before {
  background-color: #3498db;
}
form.cc-blue .form-ps span {
  background-color: #3498db;
}
form.cc-blue select:focus,
form.cc-blue textarea:focus,
form.cc-blue input:focus {
  border-color: #3498db;
}
form.cc-blue input[type="submit"],
form.cc-blue button[type="submit"] {
  background-color: #3498db;
  border-color: #3498db;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-blue input[type="submit"]:hover,
form.cc-blue button[type="submit"]:hover {
  background-color: #217dbb;
  border-color: #217dbb;
}
/* Tabs */
.tabs.cc-blue {
  /* Small screen ruleset */
}
.tabs.cc-blue.cc-bordered > li:hover a:not(.btn),
.tabs.cc-blue.cc-bordered > li.active a:not(.btn) {
  border-color: #3498db #3498db white #3498db;
}
.tabs.cc-blue.cc-pills > li.active a:not(.btn),
.tabs.cc-blue.cc-pills > li:hover a:not(.btn) {
  background-color: #3498db;
  border-top: 1px solid #3498db;
  border-bottom: 1px solid #3498db;
  color: white;
}
.tabs.cc-blue > li {
  /* Active child */
}
.tabs.cc-blue > li:hover a:not(.btn),
.tabs.cc-blue > li.active a:not(.btn) {
  color: #3498db;
  border-bottom-color: #3498db;
}
@media screen and (max-width: 767px) {
  .tabs.cc-blue.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-blue.cc-bordered > li.active a:not(.btn) {
    border-color: #3498db;
  }
  .tabs.cc-blue.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-blue.cc-pills > li.active a:not(.btn),
  .tabs.cc-blue.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-blue.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #3498db;
  }
}
/* titles */
h1.cc-orange,
h2.cc-orange,
h3.cc-orange,
h4.cc-orange,
h5.cc-orange,
h6.cc-orange,
.h1-like.cc-orange,
.h2-like.cc-orange,
.h3-like.cc-orange,
.h4-like.cc-orange,
.h5-like.cc-orange,
.h6-like.cc-orange {
  color: #ff810d;
}
h1.cc-bg-orange,
h2.cc-bg-orange,
h3.cc-bg-orange,
h4.cc-bg-orange,
h5.cc-bg-orange,
h6.cc-bg-orange,
.h1-like.cc-bg-orange,
.h2-like.cc-bg-orange,
.h3-like.cc-bg-orange,
.h4-like.cc-bg-orange,
.h5-like.cc-bg-orange,
.h6-like.cc-bg-orange {
  color: white;
  background-color: #ff810d;
}
/* elements */
p.cc-orange,
div.cc-orange,
section.cc-orange,
aside.cc-orange,
header.cc-orange,
footer.cc-orange,
article.cc-orange,
main.cc-orange,
nav.cc-orange,
td.cc-orange,
th.cc-orange,
span.cc-orange,
i.cc-orange,
a.cc-orange,
strong.cc-orange,
em.cc-orange,
code.cc-orange,
small.cc-orange {
  color: #ff810d;
}
p.cc-bg-orange,
div.cc-bg-orange,
section.cc-bg-orange,
aside.cc-bg-orange,
header.cc-bg-orange,
footer.cc-bg-orange,
article.cc-bg-orange,
main.cc-bg-orange,
nav.cc-bg-orange,
td.cc-bg-orange,
th.cc-bg-orange,
span.cc-bg-orange,
i.cc-bg-orange,
a.cc-bg-orange,
strong.cc-bg-orange,
em.cc-bg-orange,
code.cc-bg-orange,
small.cc-bg-orange {
  color: white;
  background-color: #ff810d;
}
p.cc-bg-orange a:not(.btn),
div.cc-bg-orange a:not(.btn),
section.cc-bg-orange a:not(.btn),
aside.cc-bg-orange a:not(.btn),
header.cc-bg-orange a:not(.btn),
footer.cc-bg-orange a:not(.btn),
article.cc-bg-orange a:not(.btn),
main.cc-bg-orange a:not(.btn),
nav.cc-bg-orange a:not(.btn),
td.cc-bg-orange a:not(.btn),
th.cc-bg-orange a:not(.btn),
span.cc-bg-orange a:not(.btn),
i.cc-bg-orange a:not(.btn),
a.cc-bg-orange a:not(.btn),
strong.cc-bg-orange a:not(.btn),
em.cc-bg-orange a:not(.btn),
code.cc-bg-orange a:not(.btn),
small.cc-bg-orange a:not(.btn) {
  color: white;
}
/* lists */
li.cc-bg-orange {
  background-color: #ff810d;
  color: white;
}
li.cc-orange:before {
  color: #ff810d;
}
/* blockquotes */
blockquote.cc-orange {
  border-left: 4px solid #ff810d;
}
blockquote.cc-orange.cc-quoted:before {
  color: #ff810d;
}
blockquote.cc-orange.cc-reverse {
  border-left-width: 0px;
  border-right-color: #ff810d;
}
/* Tables */
table.cc-orange {
  /* Bordered & Colored tables */
}
table.cc-orange thead td,
table.cc-orange tfoot td,
table.cc-orange thead th,
table.cc-orange tfoot th {
  background-color: #ff810d;
  color: white;
  border-bottom-color: #a65000;
}
table.cc-orange tbody tr td {
  border-bottom: 1px solid #ff810d;
}
table.cc-orange.cc-bordered tfoot td,
table.cc-orange.cc-bordered thead td,
table.cc-orange.cc-bordered tfoot th,
table.cc-orange.cc-bordered thead th {
  border-color: #d96800;
}
table.cc-orange.cc-bordered tbody tr td {
  border-color: #d96800;
}
table tr.cc-bg-orange td {
  background-color: #ff810d;
  color: white;
  border-bottom: 0;
}
/* Links / Buttons */
a.btn.cc-bg-orange,
button.cc-bg-orange {
  color: white;
  background-color: #ff810d;
  border-color: #ff810d;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
  /* Loading + outlined buttons */
  /* Outlined buttons / links */
}
a.btn.cc-bg-orange:hover,
button.cc-bg-orange:hover {
  background-color: #d96800;
  border-color: #d96800;
}
a.btn.cc-bg-orange:hover,
button.cc-bg-orange:hover {
  color: white;
}
a.btn.cc-bg-orange.cc-loading:before,
button.cc-bg-orange.cc-loading:before {
  border-color: transparent white white white;
}
a.btn.cc-bg-orange.cc-outlined,
button.cc-bg-orange.cc-outlined {
  color: #ff810d;
  background-color: transparent;
  border-color: transparent;
  /* If Hover is specified */
  /* else */
  border: 1px solid #ff810d;
  /* Loading + outlined buttons */
}
a.btn.cc-bg-orange.cc-outlined:hover,
button.cc-bg-orange.cc-outlined:hover {
  background-color: transparent;
  border-color: transparent;
}
a.btn.cc-bg-orange.cc-outlined:hover,
button.cc-bg-orange.cc-outlined:hover {
  border-color: #a65000;
  color: #a65000;
}
a.btn.cc-bg-orange.cc-outlined.cc-loading,
button.cc-bg-orange.cc-outlined.cc-loading {
  color: transparent;
}
a.btn.cc-bg-orange.cc-outlined.cc-loading:before,
button.cc-bg-orange.cc-outlined.cc-loading:before {
  border-color: transparent #ff810d #ff810d #ff810d;
}
/* Colored forms */
form.cc-orange .form-checkbox:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-orange .form-checkbox:not(.error) input:checked {
  background-color: #ff810d;
  border-color: #ff810d;
}
form.cc-orange .form-radio:not(.error) input:focus {
  border-color: #ddd;
}
form.cc-orange .form-radio:not(.error) input:checked:before {
  background-color: #ff810d;
}
form.cc-orange .form-ps span {
  background-color: #ff810d;
}
form.cc-orange select:focus,
form.cc-orange textarea:focus,
form.cc-orange input:focus {
  border-color: #ff810d;
}
form.cc-orange input[type="submit"],
form.cc-orange button[type="submit"] {
  background-color: #ff810d;
  border-color: #ff810d;
  /* If Hover is specified */
  /* else */
  /* Hover color will be @bg but 10% darken */
}
form.cc-orange input[type="submit"]:hover,
form.cc-orange button[type="submit"]:hover {
  background-color: #d96800;
  border-color: #d96800;
}
/* Tabs */
.tabs.cc-orange {
  /* Small screen ruleset */
}
.tabs.cc-orange.cc-bordered > li:hover a:not(.btn),
.tabs.cc-orange.cc-bordered > li.active a:not(.btn) {
  border-color: #ff810d #ff810d white #ff810d;
}
.tabs.cc-orange.cc-pills > li.active a:not(.btn),
.tabs.cc-orange.cc-pills > li:hover a:not(.btn) {
  background-color: #ff810d;
  border-top: 1px solid #ff810d;
  border-bottom: 1px solid #ff810d;
  color: white;
}
.tabs.cc-orange > li {
  /* Active child */
}
.tabs.cc-orange > li:hover a:not(.btn),
.tabs.cc-orange > li.active a:not(.btn) {
  color: #ff810d;
  border-bottom-color: #ff810d;
}
@media screen and (max-width: 767px) {
  .tabs.cc-orange.cc-bordered > li:hover a:not(.btn),
  .tabs.cc-orange.cc-bordered > li.active a:not(.btn) {
    border-color: #ff810d;
  }
  .tabs.cc-orange.cc-pills.cc-unchained > li.active a:not(.btn),
  .tabs.cc-orange.cc-pills > li.active a:not(.btn),
  .tabs.cc-orange.cc-pills.cc-unchained > li:hover a:not(.btn),
  .tabs.cc-orange.cc-pills > li:hover a:not(.btn) {
    border: 1px solid #ff810d;
  }
}
/* *****
    @name : ._generatePaddingMarginClass
    @description : Generate padding & margin "@{css-prefix}-pa-{number}" or/and "@{css-prefix}-ma-{number}" class on an element
***** */
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-0 {
  padding: 0px;
}
.cc-pat-0 {
  padding-top: 0px;
}
.cc-par-0 {
  padding-right: 0px;
}
.cc-pab-0 {
  padding-bottom: 0px;
}
.cc-pal-0 {
  padding-left: 0px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-0 {
  margin: 0px;
}
.cc-mat-0 {
  margin-top: 0px;
}
.cc-mar-0 {
  margin-right: 0px;
}
.cc-mab-0 {
  margin-bottom: 0px;
}
.cc-mal-0 {
  margin-left: 0px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-5 {
  padding: 5px;
}
.cc-pat-5 {
  padding-top: 5px;
}
.cc-par-5 {
  padding-right: 5px;
}
.cc-pab-5 {
  padding-bottom: 5px;
}
.cc-pal-5 {
  padding-left: 5px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-5 {
  margin: 5px;
}
.cc-mat-5 {
  margin-top: 5px;
}
.cc-mar-5 {
  margin-right: 5px;
}
.cc-mab-5 {
  margin-bottom: 5px;
}
.cc-mal-5 {
  margin-left: 5px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-10 {
  padding: 10px;
}
.cc-pat-10 {
  padding-top: 10px;
}
.cc-par-10 {
  padding-right: 10px;
}
.cc-pab-10 {
  padding-bottom: 10px;
}
.cc-pal-10 {
  padding-left: 10px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-10 {
  margin: 10px;
}
.cc-mat-10 {
  margin-top: 10px;
}
.cc-mar-10 {
  margin-right: 10px;
}
.cc-mab-10 {
  margin-bottom: 10px;
}
.cc-mal-10 {
  margin-left: 10px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-15 {
  padding: 15px;
}
.cc-pat-15 {
  padding-top: 15px;
}
.cc-par-15 {
  padding-right: 15px;
}
.cc-pab-15 {
  padding-bottom: 15px;
}
.cc-pal-15 {
  padding-left: 15px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-15 {
  margin: 15px;
}
.cc-mat-15 {
  margin-top: 15px;
}
.cc-mar-15 {
  margin-right: 15px;
}
.cc-mab-15 {
  margin-bottom: 15px;
}
.cc-mal-15 {
  margin-left: 15px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-20 {
  padding: 20px;
}
.cc-pat-20 {
  padding-top: 20px;
}
.cc-par-20 {
  padding-right: 20px;
}
.cc-pab-20 {
  padding-bottom: 20px;
}
.cc-pal-20 {
  padding-left: 20px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-20 {
  margin: 20px;
}
.cc-mat-20 {
  margin-top: 20px;
}
.cc-mar-20 {
  margin-right: 20px;
}
.cc-mab-20 {
  margin-bottom: 20px;
}
.cc-mal-20 {
  margin-left: 20px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-25 {
  padding: 25px;
}
.cc-pat-25 {
  padding-top: 25px;
}
.cc-par-25 {
  padding-right: 25px;
}
.cc-pab-25 {
  padding-bottom: 25px;
}
.cc-pal-25 {
  padding-left: 25px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-25 {
  margin: 25px;
}
.cc-mat-25 {
  margin-top: 25px;
}
.cc-mar-25 {
  margin-right: 25px;
}
.cc-mab-25 {
  margin-bottom: 25px;
}
.cc-mal-25 {
  margin-left: 25px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-30 {
  padding: 30px;
}
.cc-pat-30 {
  padding-top: 30px;
}
.cc-par-30 {
  padding-right: 30px;
}
.cc-pab-30 {
  padding-bottom: 30px;
}
.cc-pal-30 {
  padding-left: 30px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-30 {
  margin: 30px;
}
.cc-mat-30 {
  margin-top: 30px;
}
.cc-mar-30 {
  margin-right: 30px;
}
.cc-mab-30 {
  margin-bottom: 30px;
}
.cc-mal-30 {
  margin-left: 30px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-35 {
  padding: 35px;
}
.cc-pat-35 {
  padding-top: 35px;
}
.cc-par-35 {
  padding-right: 35px;
}
.cc-pab-35 {
  padding-bottom: 35px;
}
.cc-pal-35 {
  padding-left: 35px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-35 {
  margin: 35px;
}
.cc-mat-35 {
  margin-top: 35px;
}
.cc-mar-35 {
  margin-right: 35px;
}
.cc-mab-35 {
  margin-bottom: 35px;
}
.cc-mal-35 {
  margin-left: 35px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-40 {
  padding: 40px;
}
.cc-pat-40 {
  padding-top: 40px;
}
.cc-par-40 {
  padding-right: 40px;
}
.cc-pab-40 {
  padding-bottom: 40px;
}
.cc-pal-40 {
  padding-left: 40px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-40 {
  margin: 40px;
}
.cc-mat-40 {
  margin-top: 40px;
}
.cc-mar-40 {
  margin-right: 40px;
}
.cc-mab-40 {
  margin-bottom: 40px;
}
.cc-mal-40 {
  margin-left: 40px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-45 {
  padding: 45px;
}
.cc-pat-45 {
  padding-top: 45px;
}
.cc-par-45 {
  padding-right: 45px;
}
.cc-pab-45 {
  padding-bottom: 45px;
}
.cc-pal-45 {
  padding-left: 45px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-45 {
  margin: 45px;
}
.cc-mat-45 {
  margin-top: 45px;
}
.cc-mar-45 {
  margin-right: 45px;
}
.cc-mab-45 {
  margin-bottom: 45px;
}
.cc-mal-45 {
  margin-left: 45px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-50 {
  padding: 50px;
}
.cc-pat-50 {
  padding-top: 50px;
}
.cc-par-50 {
  padding-right: 50px;
}
.cc-pab-50 {
  padding-bottom: 50px;
}
.cc-pal-50 {
  padding-left: 50px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-50 {
  margin: 50px;
}
.cc-mat-50 {
  margin-top: 50px;
}
.cc-mar-50 {
  margin-right: 50px;
}
.cc-mab-50 {
  margin-bottom: 50px;
}
.cc-mal-50 {
  margin-left: 50px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-55 {
  padding: 55px;
}
.cc-pat-55 {
  padding-top: 55px;
}
.cc-par-55 {
  padding-right: 55px;
}
.cc-pab-55 {
  padding-bottom: 55px;
}
.cc-pal-55 {
  padding-left: 55px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-55 {
  margin: 55px;
}
.cc-mat-55 {
  margin-top: 55px;
}
.cc-mar-55 {
  margin-right: 55px;
}
.cc-mab-55 {
  margin-bottom: 55px;
}
.cc-mal-55 {
  margin-left: 55px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-60 {
  padding: 60px;
}
.cc-pat-60 {
  padding-top: 60px;
}
.cc-par-60 {
  padding-right: 60px;
}
.cc-pab-60 {
  padding-bottom: 60px;
}
.cc-pal-60 {
  padding-left: 60px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-60 {
  margin: 60px;
}
.cc-mat-60 {
  margin-top: 60px;
}
.cc-mar-60 {
  margin-right: 60px;
}
.cc-mab-60 {
  margin-bottom: 60px;
}
.cc-mal-60 {
  margin-left: 60px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-65 {
  padding: 65px;
}
.cc-pat-65 {
  padding-top: 65px;
}
.cc-par-65 {
  padding-right: 65px;
}
.cc-pab-65 {
  padding-bottom: 65px;
}
.cc-pal-65 {
  padding-left: 65px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-65 {
  margin: 65px;
}
.cc-mat-65 {
  margin-top: 65px;
}
.cc-mar-65 {
  margin-right: 65px;
}
.cc-mab-65 {
  margin-bottom: 65px;
}
.cc-mal-65 {
  margin-left: 65px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-70 {
  padding: 70px;
}
.cc-pat-70 {
  padding-top: 70px;
}
.cc-par-70 {
  padding-right: 70px;
}
.cc-pab-70 {
  padding-bottom: 70px;
}
.cc-pal-70 {
  padding-left: 70px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-70 {
  margin: 70px;
}
.cc-mat-70 {
  margin-top: 70px;
}
.cc-mar-70 {
  margin-right: 70px;
}
.cc-mab-70 {
  margin-bottom: 70px;
}
.cc-mal-70 {
  margin-left: 70px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-75 {
  padding: 75px;
}
.cc-pat-75 {
  padding-top: 75px;
}
.cc-par-75 {
  padding-right: 75px;
}
.cc-pab-75 {
  padding-bottom: 75px;
}
.cc-pal-75 {
  padding-left: 75px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-75 {
  margin: 75px;
}
.cc-mat-75 {
  margin-top: 75px;
}
.cc-mar-75 {
  margin-right: 75px;
}
.cc-mab-75 {
  margin-bottom: 75px;
}
.cc-mal-75 {
  margin-left: 75px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-80 {
  padding: 80px;
}
.cc-pat-80 {
  padding-top: 80px;
}
.cc-par-80 {
  padding-right: 80px;
}
.cc-pab-80 {
  padding-bottom: 80px;
}
.cc-pal-80 {
  padding-left: 80px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-80 {
  margin: 80px;
}
.cc-mat-80 {
  margin-top: 80px;
}
.cc-mar-80 {
  margin-right: 80px;
}
.cc-mab-80 {
  margin-bottom: 80px;
}
.cc-mal-80 {
  margin-left: 80px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-85 {
  padding: 85px;
}
.cc-pat-85 {
  padding-top: 85px;
}
.cc-par-85 {
  padding-right: 85px;
}
.cc-pab-85 {
  padding-bottom: 85px;
}
.cc-pal-85 {
  padding-left: 85px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-85 {
  margin: 85px;
}
.cc-mat-85 {
  margin-top: 85px;
}
.cc-mar-85 {
  margin-right: 85px;
}
.cc-mab-85 {
  margin-bottom: 85px;
}
.cc-mal-85 {
  margin-left: 85px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-90 {
  padding: 90px;
}
.cc-pat-90 {
  padding-top: 90px;
}
.cc-par-90 {
  padding-right: 90px;
}
.cc-pab-90 {
  padding-bottom: 90px;
}
.cc-pal-90 {
  padding-left: 90px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-90 {
  margin: 90px;
}
.cc-mat-90 {
  margin-top: 90px;
}
.cc-mar-90 {
  margin-right: 90px;
}
.cc-mab-90 {
  margin-bottom: 90px;
}
.cc-mal-90 {
  margin-left: 90px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-95 {
  padding: 95px;
}
.cc-pat-95 {
  padding-top: 95px;
}
.cc-par-95 {
  padding-right: 95px;
}
.cc-pab-95 {
  padding-bottom: 95px;
}
.cc-pal-95 {
  padding-left: 95px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-95 {
  margin: 95px;
}
.cc-mat-95 {
  margin-top: 95px;
}
.cc-mar-95 {
  margin-right: 95px;
}
.cc-mab-95 {
  margin-bottom: 95px;
}
.cc-mal-95 {
  margin-left: 95px;
}
.cc-pa {
  padding: 1em;
}
.cc-pat {
  padding-top: 1em;
}
.cc-par {
  padding-right: 1em;
}
.cc-pab {
  padding-bottom: 1em;
}
.cc-pal {
  padding-left: 1em;
}
.cc-pa-100 {
  padding: 100px;
}
.cc-pat-100 {
  padding-top: 100px;
}
.cc-par-100 {
  padding-right: 100px;
}
.cc-pab-100 {
  padding-bottom: 100px;
}
.cc-pal-100 {
  padding-left: 100px;
}
.cc-ma {
  margin: 1em;
}
.cc-mat {
  margin-top: 1em;
}
.cc-mar {
  margin-right: 1em;
}
.cc-mab {
  margin-bottom: 1em;
}
.cc-mal {
  margin-left: 1em;
}
.cc-ma-100 {
  margin: 100px;
}
.cc-mat-100 {
  margin-top: 100px;
}
.cc-mar-100 {
  margin-right: 100px;
}
.cc-mab-100 {
  margin-bottom: 100px;
}
.cc-mal-100 {
  margin-left: 100px;
}
@media screen and (min-width: 360px) {
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-0-s {
    padding: 0px;
  }
  .cc-pat-0-s {
    padding-top: 0px;
  }
  .cc-par-0-s {
    padding-right: 0px;
  }
  .cc-pab-0-s {
    padding-bottom: 0px;
  }
  .cc-pal-0-s {
    padding-left: 0px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-0-s {
    margin: 0px;
  }
  .cc-mat-0-s {
    margin-top: 0px;
  }
  .cc-mar-0-s {
    margin-right: 0px;
  }
  .cc-mab-0-s {
    margin-bottom: 0px;
  }
  .cc-mal-0-s {
    margin-left: 0px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-5-s {
    padding: 5px;
  }
  .cc-pat-5-s {
    padding-top: 5px;
  }
  .cc-par-5-s {
    padding-right: 5px;
  }
  .cc-pab-5-s {
    padding-bottom: 5px;
  }
  .cc-pal-5-s {
    padding-left: 5px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-5-s {
    margin: 5px;
  }
  .cc-mat-5-s {
    margin-top: 5px;
  }
  .cc-mar-5-s {
    margin-right: 5px;
  }
  .cc-mab-5-s {
    margin-bottom: 5px;
  }
  .cc-mal-5-s {
    margin-left: 5px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-10-s {
    padding: 10px;
  }
  .cc-pat-10-s {
    padding-top: 10px;
  }
  .cc-par-10-s {
    padding-right: 10px;
  }
  .cc-pab-10-s {
    padding-bottom: 10px;
  }
  .cc-pal-10-s {
    padding-left: 10px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-10-s {
    margin: 10px;
  }
  .cc-mat-10-s {
    margin-top: 10px;
  }
  .cc-mar-10-s {
    margin-right: 10px;
  }
  .cc-mab-10-s {
    margin-bottom: 10px;
  }
  .cc-mal-10-s {
    margin-left: 10px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-15-s {
    padding: 15px;
  }
  .cc-pat-15-s {
    padding-top: 15px;
  }
  .cc-par-15-s {
    padding-right: 15px;
  }
  .cc-pab-15-s {
    padding-bottom: 15px;
  }
  .cc-pal-15-s {
    padding-left: 15px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-15-s {
    margin: 15px;
  }
  .cc-mat-15-s {
    margin-top: 15px;
  }
  .cc-mar-15-s {
    margin-right: 15px;
  }
  .cc-mab-15-s {
    margin-bottom: 15px;
  }
  .cc-mal-15-s {
    margin-left: 15px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-20-s {
    padding: 20px;
  }
  .cc-pat-20-s {
    padding-top: 20px;
  }
  .cc-par-20-s {
    padding-right: 20px;
  }
  .cc-pab-20-s {
    padding-bottom: 20px;
  }
  .cc-pal-20-s {
    padding-left: 20px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-20-s {
    margin: 20px;
  }
  .cc-mat-20-s {
    margin-top: 20px;
  }
  .cc-mar-20-s {
    margin-right: 20px;
  }
  .cc-mab-20-s {
    margin-bottom: 20px;
  }
  .cc-mal-20-s {
    margin-left: 20px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-25-s {
    padding: 25px;
  }
  .cc-pat-25-s {
    padding-top: 25px;
  }
  .cc-par-25-s {
    padding-right: 25px;
  }
  .cc-pab-25-s {
    padding-bottom: 25px;
  }
  .cc-pal-25-s {
    padding-left: 25px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-25-s {
    margin: 25px;
  }
  .cc-mat-25-s {
    margin-top: 25px;
  }
  .cc-mar-25-s {
    margin-right: 25px;
  }
  .cc-mab-25-s {
    margin-bottom: 25px;
  }
  .cc-mal-25-s {
    margin-left: 25px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-30-s {
    padding: 30px;
  }
  .cc-pat-30-s {
    padding-top: 30px;
  }
  .cc-par-30-s {
    padding-right: 30px;
  }
  .cc-pab-30-s {
    padding-bottom: 30px;
  }
  .cc-pal-30-s {
    padding-left: 30px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-30-s {
    margin: 30px;
  }
  .cc-mat-30-s {
    margin-top: 30px;
  }
  .cc-mar-30-s {
    margin-right: 30px;
  }
  .cc-mab-30-s {
    margin-bottom: 30px;
  }
  .cc-mal-30-s {
    margin-left: 30px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-35-s {
    padding: 35px;
  }
  .cc-pat-35-s {
    padding-top: 35px;
  }
  .cc-par-35-s {
    padding-right: 35px;
  }
  .cc-pab-35-s {
    padding-bottom: 35px;
  }
  .cc-pal-35-s {
    padding-left: 35px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-35-s {
    margin: 35px;
  }
  .cc-mat-35-s {
    margin-top: 35px;
  }
  .cc-mar-35-s {
    margin-right: 35px;
  }
  .cc-mab-35-s {
    margin-bottom: 35px;
  }
  .cc-mal-35-s {
    margin-left: 35px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-40-s {
    padding: 40px;
  }
  .cc-pat-40-s {
    padding-top: 40px;
  }
  .cc-par-40-s {
    padding-right: 40px;
  }
  .cc-pab-40-s {
    padding-bottom: 40px;
  }
  .cc-pal-40-s {
    padding-left: 40px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-40-s {
    margin: 40px;
  }
  .cc-mat-40-s {
    margin-top: 40px;
  }
  .cc-mar-40-s {
    margin-right: 40px;
  }
  .cc-mab-40-s {
    margin-bottom: 40px;
  }
  .cc-mal-40-s {
    margin-left: 40px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-45-s {
    padding: 45px;
  }
  .cc-pat-45-s {
    padding-top: 45px;
  }
  .cc-par-45-s {
    padding-right: 45px;
  }
  .cc-pab-45-s {
    padding-bottom: 45px;
  }
  .cc-pal-45-s {
    padding-left: 45px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-45-s {
    margin: 45px;
  }
  .cc-mat-45-s {
    margin-top: 45px;
  }
  .cc-mar-45-s {
    margin-right: 45px;
  }
  .cc-mab-45-s {
    margin-bottom: 45px;
  }
  .cc-mal-45-s {
    margin-left: 45px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-50-s {
    padding: 50px;
  }
  .cc-pat-50-s {
    padding-top: 50px;
  }
  .cc-par-50-s {
    padding-right: 50px;
  }
  .cc-pab-50-s {
    padding-bottom: 50px;
  }
  .cc-pal-50-s {
    padding-left: 50px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-50-s {
    margin: 50px;
  }
  .cc-mat-50-s {
    margin-top: 50px;
  }
  .cc-mar-50-s {
    margin-right: 50px;
  }
  .cc-mab-50-s {
    margin-bottom: 50px;
  }
  .cc-mal-50-s {
    margin-left: 50px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-55-s {
    padding: 55px;
  }
  .cc-pat-55-s {
    padding-top: 55px;
  }
  .cc-par-55-s {
    padding-right: 55px;
  }
  .cc-pab-55-s {
    padding-bottom: 55px;
  }
  .cc-pal-55-s {
    padding-left: 55px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-55-s {
    margin: 55px;
  }
  .cc-mat-55-s {
    margin-top: 55px;
  }
  .cc-mar-55-s {
    margin-right: 55px;
  }
  .cc-mab-55-s {
    margin-bottom: 55px;
  }
  .cc-mal-55-s {
    margin-left: 55px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-60-s {
    padding: 60px;
  }
  .cc-pat-60-s {
    padding-top: 60px;
  }
  .cc-par-60-s {
    padding-right: 60px;
  }
  .cc-pab-60-s {
    padding-bottom: 60px;
  }
  .cc-pal-60-s {
    padding-left: 60px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-60-s {
    margin: 60px;
  }
  .cc-mat-60-s {
    margin-top: 60px;
  }
  .cc-mar-60-s {
    margin-right: 60px;
  }
  .cc-mab-60-s {
    margin-bottom: 60px;
  }
  .cc-mal-60-s {
    margin-left: 60px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-65-s {
    padding: 65px;
  }
  .cc-pat-65-s {
    padding-top: 65px;
  }
  .cc-par-65-s {
    padding-right: 65px;
  }
  .cc-pab-65-s {
    padding-bottom: 65px;
  }
  .cc-pal-65-s {
    padding-left: 65px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-65-s {
    margin: 65px;
  }
  .cc-mat-65-s {
    margin-top: 65px;
  }
  .cc-mar-65-s {
    margin-right: 65px;
  }
  .cc-mab-65-s {
    margin-bottom: 65px;
  }
  .cc-mal-65-s {
    margin-left: 65px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-70-s {
    padding: 70px;
  }
  .cc-pat-70-s {
    padding-top: 70px;
  }
  .cc-par-70-s {
    padding-right: 70px;
  }
  .cc-pab-70-s {
    padding-bottom: 70px;
  }
  .cc-pal-70-s {
    padding-left: 70px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-70-s {
    margin: 70px;
  }
  .cc-mat-70-s {
    margin-top: 70px;
  }
  .cc-mar-70-s {
    margin-right: 70px;
  }
  .cc-mab-70-s {
    margin-bottom: 70px;
  }
  .cc-mal-70-s {
    margin-left: 70px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-75-s {
    padding: 75px;
  }
  .cc-pat-75-s {
    padding-top: 75px;
  }
  .cc-par-75-s {
    padding-right: 75px;
  }
  .cc-pab-75-s {
    padding-bottom: 75px;
  }
  .cc-pal-75-s {
    padding-left: 75px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-75-s {
    margin: 75px;
  }
  .cc-mat-75-s {
    margin-top: 75px;
  }
  .cc-mar-75-s {
    margin-right: 75px;
  }
  .cc-mab-75-s {
    margin-bottom: 75px;
  }
  .cc-mal-75-s {
    margin-left: 75px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-80-s {
    padding: 80px;
  }
  .cc-pat-80-s {
    padding-top: 80px;
  }
  .cc-par-80-s {
    padding-right: 80px;
  }
  .cc-pab-80-s {
    padding-bottom: 80px;
  }
  .cc-pal-80-s {
    padding-left: 80px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-80-s {
    margin: 80px;
  }
  .cc-mat-80-s {
    margin-top: 80px;
  }
  .cc-mar-80-s {
    margin-right: 80px;
  }
  .cc-mab-80-s {
    margin-bottom: 80px;
  }
  .cc-mal-80-s {
    margin-left: 80px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-85-s {
    padding: 85px;
  }
  .cc-pat-85-s {
    padding-top: 85px;
  }
  .cc-par-85-s {
    padding-right: 85px;
  }
  .cc-pab-85-s {
    padding-bottom: 85px;
  }
  .cc-pal-85-s {
    padding-left: 85px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-85-s {
    margin: 85px;
  }
  .cc-mat-85-s {
    margin-top: 85px;
  }
  .cc-mar-85-s {
    margin-right: 85px;
  }
  .cc-mab-85-s {
    margin-bottom: 85px;
  }
  .cc-mal-85-s {
    margin-left: 85px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-90-s {
    padding: 90px;
  }
  .cc-pat-90-s {
    padding-top: 90px;
  }
  .cc-par-90-s {
    padding-right: 90px;
  }
  .cc-pab-90-s {
    padding-bottom: 90px;
  }
  .cc-pal-90-s {
    padding-left: 90px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-90-s {
    margin: 90px;
  }
  .cc-mat-90-s {
    margin-top: 90px;
  }
  .cc-mar-90-s {
    margin-right: 90px;
  }
  .cc-mab-90-s {
    margin-bottom: 90px;
  }
  .cc-mal-90-s {
    margin-left: 90px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-95-s {
    padding: 95px;
  }
  .cc-pat-95-s {
    padding-top: 95px;
  }
  .cc-par-95-s {
    padding-right: 95px;
  }
  .cc-pab-95-s {
    padding-bottom: 95px;
  }
  .cc-pal-95-s {
    padding-left: 95px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-95-s {
    margin: 95px;
  }
  .cc-mat-95-s {
    margin-top: 95px;
  }
  .cc-mar-95-s {
    margin-right: 95px;
  }
  .cc-mab-95-s {
    margin-bottom: 95px;
  }
  .cc-mal-95-s {
    margin-left: 95px;
  }
  .cc-pa-s {
    padding: 1em;
  }
  .cc-pat-s {
    padding-top: 1em;
  }
  .cc-par-s {
    padding-right: 1em;
  }
  .cc-pab-s {
    padding-bottom: 1em;
  }
  .cc-pal-s {
    padding-left: 1em;
  }
  .cc-pa-100-s {
    padding: 100px;
  }
  .cc-pat-100-s {
    padding-top: 100px;
  }
  .cc-par-100-s {
    padding-right: 100px;
  }
  .cc-pab-100-s {
    padding-bottom: 100px;
  }
  .cc-pal-100-s {
    padding-left: 100px;
  }
  .cc-ma-s {
    margin: 1em;
  }
  .cc-mat-s {
    margin-top: 1em;
  }
  .cc-mar-s {
    margin-right: 1em;
  }
  .cc-mab-s {
    margin-bottom: 1em;
  }
  .cc-mal-s {
    margin-left: 1em;
  }
  .cc-ma-100-s {
    margin: 100px;
  }
  .cc-mat-100-s {
    margin-top: 100px;
  }
  .cc-mar-100-s {
    margin-right: 100px;
  }
  .cc-mab-100-s {
    margin-bottom: 100px;
  }
  .cc-mal-100-s {
    margin-left: 100px;
  }
}
@media screen and (min-width: 768px) {
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-0-m {
    padding: 0px;
  }
  .cc-pat-0-m {
    padding-top: 0px;
  }
  .cc-par-0-m {
    padding-right: 0px;
  }
  .cc-pab-0-m {
    padding-bottom: 0px;
  }
  .cc-pal-0-m {
    padding-left: 0px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-0-m {
    margin: 0px;
  }
  .cc-mat-0-m {
    margin-top: 0px;
  }
  .cc-mar-0-m {
    margin-right: 0px;
  }
  .cc-mab-0-m {
    margin-bottom: 0px;
  }
  .cc-mal-0-m {
    margin-left: 0px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-5-m {
    padding: 5px;
  }
  .cc-pat-5-m {
    padding-top: 5px;
  }
  .cc-par-5-m {
    padding-right: 5px;
  }
  .cc-pab-5-m {
    padding-bottom: 5px;
  }
  .cc-pal-5-m {
    padding-left: 5px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-5-m {
    margin: 5px;
  }
  .cc-mat-5-m {
    margin-top: 5px;
  }
  .cc-mar-5-m {
    margin-right: 5px;
  }
  .cc-mab-5-m {
    margin-bottom: 5px;
  }
  .cc-mal-5-m {
    margin-left: 5px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-10-m {
    padding: 10px;
  }
  .cc-pat-10-m {
    padding-top: 10px;
  }
  .cc-par-10-m {
    padding-right: 10px;
  }
  .cc-pab-10-m {
    padding-bottom: 10px;
  }
  .cc-pal-10-m {
    padding-left: 10px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-10-m {
    margin: 10px;
  }
  .cc-mat-10-m {
    margin-top: 10px;
  }
  .cc-mar-10-m {
    margin-right: 10px;
  }
  .cc-mab-10-m {
    margin-bottom: 10px;
  }
  .cc-mal-10-m {
    margin-left: 10px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-15-m {
    padding: 15px;
  }
  .cc-pat-15-m {
    padding-top: 15px;
  }
  .cc-par-15-m {
    padding-right: 15px;
  }
  .cc-pab-15-m {
    padding-bottom: 15px;
  }
  .cc-pal-15-m {
    padding-left: 15px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-15-m {
    margin: 15px;
  }
  .cc-mat-15-m {
    margin-top: 15px;
  }
  .cc-mar-15-m {
    margin-right: 15px;
  }
  .cc-mab-15-m {
    margin-bottom: 15px;
  }
  .cc-mal-15-m {
    margin-left: 15px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-20-m {
    padding: 20px;
  }
  .cc-pat-20-m {
    padding-top: 20px;
  }
  .cc-par-20-m {
    padding-right: 20px;
  }
  .cc-pab-20-m {
    padding-bottom: 20px;
  }
  .cc-pal-20-m {
    padding-left: 20px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-20-m {
    margin: 20px;
  }
  .cc-mat-20-m {
    margin-top: 20px;
  }
  .cc-mar-20-m {
    margin-right: 20px;
  }
  .cc-mab-20-m {
    margin-bottom: 20px;
  }
  .cc-mal-20-m {
    margin-left: 20px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-25-m {
    padding: 25px;
  }
  .cc-pat-25-m {
    padding-top: 25px;
  }
  .cc-par-25-m {
    padding-right: 25px;
  }
  .cc-pab-25-m {
    padding-bottom: 25px;
  }
  .cc-pal-25-m {
    padding-left: 25px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-25-m {
    margin: 25px;
  }
  .cc-mat-25-m {
    margin-top: 25px;
  }
  .cc-mar-25-m {
    margin-right: 25px;
  }
  .cc-mab-25-m {
    margin-bottom: 25px;
  }
  .cc-mal-25-m {
    margin-left: 25px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-30-m {
    padding: 30px;
  }
  .cc-pat-30-m {
    padding-top: 30px;
  }
  .cc-par-30-m {
    padding-right: 30px;
  }
  .cc-pab-30-m {
    padding-bottom: 30px;
  }
  .cc-pal-30-m {
    padding-left: 30px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-30-m {
    margin: 30px;
  }
  .cc-mat-30-m {
    margin-top: 30px;
  }
  .cc-mar-30-m {
    margin-right: 30px;
  }
  .cc-mab-30-m {
    margin-bottom: 30px;
  }
  .cc-mal-30-m {
    margin-left: 30px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-35-m {
    padding: 35px;
  }
  .cc-pat-35-m {
    padding-top: 35px;
  }
  .cc-par-35-m {
    padding-right: 35px;
  }
  .cc-pab-35-m {
    padding-bottom: 35px;
  }
  .cc-pal-35-m {
    padding-left: 35px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-35-m {
    margin: 35px;
  }
  .cc-mat-35-m {
    margin-top: 35px;
  }
  .cc-mar-35-m {
    margin-right: 35px;
  }
  .cc-mab-35-m {
    margin-bottom: 35px;
  }
  .cc-mal-35-m {
    margin-left: 35px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-40-m {
    padding: 40px;
  }
  .cc-pat-40-m {
    padding-top: 40px;
  }
  .cc-par-40-m {
    padding-right: 40px;
  }
  .cc-pab-40-m {
    padding-bottom: 40px;
  }
  .cc-pal-40-m {
    padding-left: 40px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-40-m {
    margin: 40px;
  }
  .cc-mat-40-m {
    margin-top: 40px;
  }
  .cc-mar-40-m {
    margin-right: 40px;
  }
  .cc-mab-40-m {
    margin-bottom: 40px;
  }
  .cc-mal-40-m {
    margin-left: 40px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-45-m {
    padding: 45px;
  }
  .cc-pat-45-m {
    padding-top: 45px;
  }
  .cc-par-45-m {
    padding-right: 45px;
  }
  .cc-pab-45-m {
    padding-bottom: 45px;
  }
  .cc-pal-45-m {
    padding-left: 45px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-45-m {
    margin: 45px;
  }
  .cc-mat-45-m {
    margin-top: 45px;
  }
  .cc-mar-45-m {
    margin-right: 45px;
  }
  .cc-mab-45-m {
    margin-bottom: 45px;
  }
  .cc-mal-45-m {
    margin-left: 45px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-50-m {
    padding: 50px;
  }
  .cc-pat-50-m {
    padding-top: 50px;
  }
  .cc-par-50-m {
    padding-right: 50px;
  }
  .cc-pab-50-m {
    padding-bottom: 50px;
  }
  .cc-pal-50-m {
    padding-left: 50px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-50-m {
    margin: 50px;
  }
  .cc-mat-50-m {
    margin-top: 50px;
  }
  .cc-mar-50-m {
    margin-right: 50px;
  }
  .cc-mab-50-m {
    margin-bottom: 50px;
  }
  .cc-mal-50-m {
    margin-left: 50px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-55-m {
    padding: 55px;
  }
  .cc-pat-55-m {
    padding-top: 55px;
  }
  .cc-par-55-m {
    padding-right: 55px;
  }
  .cc-pab-55-m {
    padding-bottom: 55px;
  }
  .cc-pal-55-m {
    padding-left: 55px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-55-m {
    margin: 55px;
  }
  .cc-mat-55-m {
    margin-top: 55px;
  }
  .cc-mar-55-m {
    margin-right: 55px;
  }
  .cc-mab-55-m {
    margin-bottom: 55px;
  }
  .cc-mal-55-m {
    margin-left: 55px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-60-m {
    padding: 60px;
  }
  .cc-pat-60-m {
    padding-top: 60px;
  }
  .cc-par-60-m {
    padding-right: 60px;
  }
  .cc-pab-60-m {
    padding-bottom: 60px;
  }
  .cc-pal-60-m {
    padding-left: 60px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-60-m {
    margin: 60px;
  }
  .cc-mat-60-m {
    margin-top: 60px;
  }
  .cc-mar-60-m {
    margin-right: 60px;
  }
  .cc-mab-60-m {
    margin-bottom: 60px;
  }
  .cc-mal-60-m {
    margin-left: 60px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-65-m {
    padding: 65px;
  }
  .cc-pat-65-m {
    padding-top: 65px;
  }
  .cc-par-65-m {
    padding-right: 65px;
  }
  .cc-pab-65-m {
    padding-bottom: 65px;
  }
  .cc-pal-65-m {
    padding-left: 65px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-65-m {
    margin: 65px;
  }
  .cc-mat-65-m {
    margin-top: 65px;
  }
  .cc-mar-65-m {
    margin-right: 65px;
  }
  .cc-mab-65-m {
    margin-bottom: 65px;
  }
  .cc-mal-65-m {
    margin-left: 65px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-70-m {
    padding: 70px;
  }
  .cc-pat-70-m {
    padding-top: 70px;
  }
  .cc-par-70-m {
    padding-right: 70px;
  }
  .cc-pab-70-m {
    padding-bottom: 70px;
  }
  .cc-pal-70-m {
    padding-left: 70px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-70-m {
    margin: 70px;
  }
  .cc-mat-70-m {
    margin-top: 70px;
  }
  .cc-mar-70-m {
    margin-right: 70px;
  }
  .cc-mab-70-m {
    margin-bottom: 70px;
  }
  .cc-mal-70-m {
    margin-left: 70px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-75-m {
    padding: 75px;
  }
  .cc-pat-75-m {
    padding-top: 75px;
  }
  .cc-par-75-m {
    padding-right: 75px;
  }
  .cc-pab-75-m {
    padding-bottom: 75px;
  }
  .cc-pal-75-m {
    padding-left: 75px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-75-m {
    margin: 75px;
  }
  .cc-mat-75-m {
    margin-top: 75px;
  }
  .cc-mar-75-m {
    margin-right: 75px;
  }
  .cc-mab-75-m {
    margin-bottom: 75px;
  }
  .cc-mal-75-m {
    margin-left: 75px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-80-m {
    padding: 80px;
  }
  .cc-pat-80-m {
    padding-top: 80px;
  }
  .cc-par-80-m {
    padding-right: 80px;
  }
  .cc-pab-80-m {
    padding-bottom: 80px;
  }
  .cc-pal-80-m {
    padding-left: 80px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-80-m {
    margin: 80px;
  }
  .cc-mat-80-m {
    margin-top: 80px;
  }
  .cc-mar-80-m {
    margin-right: 80px;
  }
  .cc-mab-80-m {
    margin-bottom: 80px;
  }
  .cc-mal-80-m {
    margin-left: 80px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-85-m {
    padding: 85px;
  }
  .cc-pat-85-m {
    padding-top: 85px;
  }
  .cc-par-85-m {
    padding-right: 85px;
  }
  .cc-pab-85-m {
    padding-bottom: 85px;
  }
  .cc-pal-85-m {
    padding-left: 85px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-85-m {
    margin: 85px;
  }
  .cc-mat-85-m {
    margin-top: 85px;
  }
  .cc-mar-85-m {
    margin-right: 85px;
  }
  .cc-mab-85-m {
    margin-bottom: 85px;
  }
  .cc-mal-85-m {
    margin-left: 85px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-90-m {
    padding: 90px;
  }
  .cc-pat-90-m {
    padding-top: 90px;
  }
  .cc-par-90-m {
    padding-right: 90px;
  }
  .cc-pab-90-m {
    padding-bottom: 90px;
  }
  .cc-pal-90-m {
    padding-left: 90px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-90-m {
    margin: 90px;
  }
  .cc-mat-90-m {
    margin-top: 90px;
  }
  .cc-mar-90-m {
    margin-right: 90px;
  }
  .cc-mab-90-m {
    margin-bottom: 90px;
  }
  .cc-mal-90-m {
    margin-left: 90px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-95-m {
    padding: 95px;
  }
  .cc-pat-95-m {
    padding-top: 95px;
  }
  .cc-par-95-m {
    padding-right: 95px;
  }
  .cc-pab-95-m {
    padding-bottom: 95px;
  }
  .cc-pal-95-m {
    padding-left: 95px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-95-m {
    margin: 95px;
  }
  .cc-mat-95-m {
    margin-top: 95px;
  }
  .cc-mar-95-m {
    margin-right: 95px;
  }
  .cc-mab-95-m {
    margin-bottom: 95px;
  }
  .cc-mal-95-m {
    margin-left: 95px;
  }
  .cc-pa-m {
    padding: 1em;
  }
  .cc-pat-m {
    padding-top: 1em;
  }
  .cc-par-m {
    padding-right: 1em;
  }
  .cc-pab-m {
    padding-bottom: 1em;
  }
  .cc-pal-m {
    padding-left: 1em;
  }
  .cc-pa-100-m {
    padding: 100px;
  }
  .cc-pat-100-m {
    padding-top: 100px;
  }
  .cc-par-100-m {
    padding-right: 100px;
  }
  .cc-pab-100-m {
    padding-bottom: 100px;
  }
  .cc-pal-100-m {
    padding-left: 100px;
  }
  .cc-ma-m {
    margin: 1em;
  }
  .cc-mat-m {
    margin-top: 1em;
  }
  .cc-mar-m {
    margin-right: 1em;
  }
  .cc-mab-m {
    margin-bottom: 1em;
  }
  .cc-mal-m {
    margin-left: 1em;
  }
  .cc-ma-100-m {
    margin: 100px;
  }
  .cc-mat-100-m {
    margin-top: 100px;
  }
  .cc-mar-100-m {
    margin-right: 100px;
  }
  .cc-mab-100-m {
    margin-bottom: 100px;
  }
  .cc-mal-100-m {
    margin-left: 100px;
  }
}
@media screen and (min-width: 990px) {
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-0-l {
    padding: 0px;
  }
  .cc-pat-0-l {
    padding-top: 0px;
  }
  .cc-par-0-l {
    padding-right: 0px;
  }
  .cc-pab-0-l {
    padding-bottom: 0px;
  }
  .cc-pal-0-l {
    padding-left: 0px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-0-l {
    margin: 0px;
  }
  .cc-mat-0-l {
    margin-top: 0px;
  }
  .cc-mar-0-l {
    margin-right: 0px;
  }
  .cc-mab-0-l {
    margin-bottom: 0px;
  }
  .cc-mal-0-l {
    margin-left: 0px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-5-l {
    padding: 5px;
  }
  .cc-pat-5-l {
    padding-top: 5px;
  }
  .cc-par-5-l {
    padding-right: 5px;
  }
  .cc-pab-5-l {
    padding-bottom: 5px;
  }
  .cc-pal-5-l {
    padding-left: 5px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-5-l {
    margin: 5px;
  }
  .cc-mat-5-l {
    margin-top: 5px;
  }
  .cc-mar-5-l {
    margin-right: 5px;
  }
  .cc-mab-5-l {
    margin-bottom: 5px;
  }
  .cc-mal-5-l {
    margin-left: 5px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-10-l {
    padding: 10px;
  }
  .cc-pat-10-l {
    padding-top: 10px;
  }
  .cc-par-10-l {
    padding-right: 10px;
  }
  .cc-pab-10-l {
    padding-bottom: 10px;
  }
  .cc-pal-10-l {
    padding-left: 10px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-10-l {
    margin: 10px;
  }
  .cc-mat-10-l {
    margin-top: 10px;
  }
  .cc-mar-10-l {
    margin-right: 10px;
  }
  .cc-mab-10-l {
    margin-bottom: 10px;
  }
  .cc-mal-10-l {
    margin-left: 10px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-15-l {
    padding: 15px;
  }
  .cc-pat-15-l {
    padding-top: 15px;
  }
  .cc-par-15-l {
    padding-right: 15px;
  }
  .cc-pab-15-l {
    padding-bottom: 15px;
  }
  .cc-pal-15-l {
    padding-left: 15px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-15-l {
    margin: 15px;
  }
  .cc-mat-15-l {
    margin-top: 15px;
  }
  .cc-mar-15-l {
    margin-right: 15px;
  }
  .cc-mab-15-l {
    margin-bottom: 15px;
  }
  .cc-mal-15-l {
    margin-left: 15px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-20-l {
    padding: 20px;
  }
  .cc-pat-20-l {
    padding-top: 20px;
  }
  .cc-par-20-l {
    padding-right: 20px;
  }
  .cc-pab-20-l {
    padding-bottom: 20px;
  }
  .cc-pal-20-l {
    padding-left: 20px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-20-l {
    margin: 20px;
  }
  .cc-mat-20-l {
    margin-top: 20px;
  }
  .cc-mar-20-l {
    margin-right: 20px;
  }
  .cc-mab-20-l {
    margin-bottom: 20px;
  }
  .cc-mal-20-l {
    margin-left: 20px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-25-l {
    padding: 25px;
  }
  .cc-pat-25-l {
    padding-top: 25px;
  }
  .cc-par-25-l {
    padding-right: 25px;
  }
  .cc-pab-25-l {
    padding-bottom: 25px;
  }
  .cc-pal-25-l {
    padding-left: 25px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-25-l {
    margin: 25px;
  }
  .cc-mat-25-l {
    margin-top: 25px;
  }
  .cc-mar-25-l {
    margin-right: 25px;
  }
  .cc-mab-25-l {
    margin-bottom: 25px;
  }
  .cc-mal-25-l {
    margin-left: 25px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-30-l {
    padding: 30px;
  }
  .cc-pat-30-l {
    padding-top: 30px;
  }
  .cc-par-30-l {
    padding-right: 30px;
  }
  .cc-pab-30-l {
    padding-bottom: 30px;
  }
  .cc-pal-30-l {
    padding-left: 30px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-30-l {
    margin: 30px;
  }
  .cc-mat-30-l {
    margin-top: 30px;
  }
  .cc-mar-30-l {
    margin-right: 30px;
  }
  .cc-mab-30-l {
    margin-bottom: 30px;
  }
  .cc-mal-30-l {
    margin-left: 30px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-35-l {
    padding: 35px;
  }
  .cc-pat-35-l {
    padding-top: 35px;
  }
  .cc-par-35-l {
    padding-right: 35px;
  }
  .cc-pab-35-l {
    padding-bottom: 35px;
  }
  .cc-pal-35-l {
    padding-left: 35px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-35-l {
    margin: 35px;
  }
  .cc-mat-35-l {
    margin-top: 35px;
  }
  .cc-mar-35-l {
    margin-right: 35px;
  }
  .cc-mab-35-l {
    margin-bottom: 35px;
  }
  .cc-mal-35-l {
    margin-left: 35px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-40-l {
    padding: 40px;
  }
  .cc-pat-40-l {
    padding-top: 40px;
  }
  .cc-par-40-l {
    padding-right: 40px;
  }
  .cc-pab-40-l {
    padding-bottom: 40px;
  }
  .cc-pal-40-l {
    padding-left: 40px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-40-l {
    margin: 40px;
  }
  .cc-mat-40-l {
    margin-top: 40px;
  }
  .cc-mar-40-l {
    margin-right: 40px;
  }
  .cc-mab-40-l {
    margin-bottom: 40px;
  }
  .cc-mal-40-l {
    margin-left: 40px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-45-l {
    padding: 45px;
  }
  .cc-pat-45-l {
    padding-top: 45px;
  }
  .cc-par-45-l {
    padding-right: 45px;
  }
  .cc-pab-45-l {
    padding-bottom: 45px;
  }
  .cc-pal-45-l {
    padding-left: 45px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-45-l {
    margin: 45px;
  }
  .cc-mat-45-l {
    margin-top: 45px;
  }
  .cc-mar-45-l {
    margin-right: 45px;
  }
  .cc-mab-45-l {
    margin-bottom: 45px;
  }
  .cc-mal-45-l {
    margin-left: 45px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-50-l {
    padding: 50px;
  }
  .cc-pat-50-l {
    padding-top: 50px;
  }
  .cc-par-50-l {
    padding-right: 50px;
  }
  .cc-pab-50-l {
    padding-bottom: 50px;
  }
  .cc-pal-50-l {
    padding-left: 50px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-50-l {
    margin: 50px;
  }
  .cc-mat-50-l {
    margin-top: 50px;
  }
  .cc-mar-50-l {
    margin-right: 50px;
  }
  .cc-mab-50-l {
    margin-bottom: 50px;
  }
  .cc-mal-50-l {
    margin-left: 50px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-55-l {
    padding: 55px;
  }
  .cc-pat-55-l {
    padding-top: 55px;
  }
  .cc-par-55-l {
    padding-right: 55px;
  }
  .cc-pab-55-l {
    padding-bottom: 55px;
  }
  .cc-pal-55-l {
    padding-left: 55px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-55-l {
    margin: 55px;
  }
  .cc-mat-55-l {
    margin-top: 55px;
  }
  .cc-mar-55-l {
    margin-right: 55px;
  }
  .cc-mab-55-l {
    margin-bottom: 55px;
  }
  .cc-mal-55-l {
    margin-left: 55px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-60-l {
    padding: 60px;
  }
  .cc-pat-60-l {
    padding-top: 60px;
  }
  .cc-par-60-l {
    padding-right: 60px;
  }
  .cc-pab-60-l {
    padding-bottom: 60px;
  }
  .cc-pal-60-l {
    padding-left: 60px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-60-l {
    margin: 60px;
  }
  .cc-mat-60-l {
    margin-top: 60px;
  }
  .cc-mar-60-l {
    margin-right: 60px;
  }
  .cc-mab-60-l {
    margin-bottom: 60px;
  }
  .cc-mal-60-l {
    margin-left: 60px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-65-l {
    padding: 65px;
  }
  .cc-pat-65-l {
    padding-top: 65px;
  }
  .cc-par-65-l {
    padding-right: 65px;
  }
  .cc-pab-65-l {
    padding-bottom: 65px;
  }
  .cc-pal-65-l {
    padding-left: 65px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-65-l {
    margin: 65px;
  }
  .cc-mat-65-l {
    margin-top: 65px;
  }
  .cc-mar-65-l {
    margin-right: 65px;
  }
  .cc-mab-65-l {
    margin-bottom: 65px;
  }
  .cc-mal-65-l {
    margin-left: 65px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-70-l {
    padding: 70px;
  }
  .cc-pat-70-l {
    padding-top: 70px;
  }
  .cc-par-70-l {
    padding-right: 70px;
  }
  .cc-pab-70-l {
    padding-bottom: 70px;
  }
  .cc-pal-70-l {
    padding-left: 70px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-70-l {
    margin: 70px;
  }
  .cc-mat-70-l {
    margin-top: 70px;
  }
  .cc-mar-70-l {
    margin-right: 70px;
  }
  .cc-mab-70-l {
    margin-bottom: 70px;
  }
  .cc-mal-70-l {
    margin-left: 70px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-75-l {
    padding: 75px;
  }
  .cc-pat-75-l {
    padding-top: 75px;
  }
  .cc-par-75-l {
    padding-right: 75px;
  }
  .cc-pab-75-l {
    padding-bottom: 75px;
  }
  .cc-pal-75-l {
    padding-left: 75px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-75-l {
    margin: 75px;
  }
  .cc-mat-75-l {
    margin-top: 75px;
  }
  .cc-mar-75-l {
    margin-right: 75px;
  }
  .cc-mab-75-l {
    margin-bottom: 75px;
  }
  .cc-mal-75-l {
    margin-left: 75px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-80-l {
    padding: 80px;
  }
  .cc-pat-80-l {
    padding-top: 80px;
  }
  .cc-par-80-l {
    padding-right: 80px;
  }
  .cc-pab-80-l {
    padding-bottom: 80px;
  }
  .cc-pal-80-l {
    padding-left: 80px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-80-l {
    margin: 80px;
  }
  .cc-mat-80-l {
    margin-top: 80px;
  }
  .cc-mar-80-l {
    margin-right: 80px;
  }
  .cc-mab-80-l {
    margin-bottom: 80px;
  }
  .cc-mal-80-l {
    margin-left: 80px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-85-l {
    padding: 85px;
  }
  .cc-pat-85-l {
    padding-top: 85px;
  }
  .cc-par-85-l {
    padding-right: 85px;
  }
  .cc-pab-85-l {
    padding-bottom: 85px;
  }
  .cc-pal-85-l {
    padding-left: 85px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-85-l {
    margin: 85px;
  }
  .cc-mat-85-l {
    margin-top: 85px;
  }
  .cc-mar-85-l {
    margin-right: 85px;
  }
  .cc-mab-85-l {
    margin-bottom: 85px;
  }
  .cc-mal-85-l {
    margin-left: 85px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-90-l {
    padding: 90px;
  }
  .cc-pat-90-l {
    padding-top: 90px;
  }
  .cc-par-90-l {
    padding-right: 90px;
  }
  .cc-pab-90-l {
    padding-bottom: 90px;
  }
  .cc-pal-90-l {
    padding-left: 90px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-90-l {
    margin: 90px;
  }
  .cc-mat-90-l {
    margin-top: 90px;
  }
  .cc-mar-90-l {
    margin-right: 90px;
  }
  .cc-mab-90-l {
    margin-bottom: 90px;
  }
  .cc-mal-90-l {
    margin-left: 90px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-95-l {
    padding: 95px;
  }
  .cc-pat-95-l {
    padding-top: 95px;
  }
  .cc-par-95-l {
    padding-right: 95px;
  }
  .cc-pab-95-l {
    padding-bottom: 95px;
  }
  .cc-pal-95-l {
    padding-left: 95px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-95-l {
    margin: 95px;
  }
  .cc-mat-95-l {
    margin-top: 95px;
  }
  .cc-mar-95-l {
    margin-right: 95px;
  }
  .cc-mab-95-l {
    margin-bottom: 95px;
  }
  .cc-mal-95-l {
    margin-left: 95px;
  }
  .cc-pa-l {
    padding: 1em;
  }
  .cc-pat-l {
    padding-top: 1em;
  }
  .cc-par-l {
    padding-right: 1em;
  }
  .cc-pab-l {
    padding-bottom: 1em;
  }
  .cc-pal-l {
    padding-left: 1em;
  }
  .cc-pa-100-l {
    padding: 100px;
  }
  .cc-pat-100-l {
    padding-top: 100px;
  }
  .cc-par-100-l {
    padding-right: 100px;
  }
  .cc-pab-100-l {
    padding-bottom: 100px;
  }
  .cc-pal-100-l {
    padding-left: 100px;
  }
  .cc-ma-l {
    margin: 1em;
  }
  .cc-mat-l {
    margin-top: 1em;
  }
  .cc-mar-l {
    margin-right: 1em;
  }
  .cc-mab-l {
    margin-bottom: 1em;
  }
  .cc-mal-l {
    margin-left: 1em;
  }
  .cc-ma-100-l {
    margin: 100px;
  }
  .cc-mat-100-l {
    margin-top: 100px;
  }
  .cc-mar-100-l {
    margin-right: 100px;
  }
  .cc-mab-100-l {
    margin-bottom: 100px;
  }
  .cc-mal-100-l {
    margin-left: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-0-xl {
    padding: 0px;
  }
  .cc-pat-0-xl {
    padding-top: 0px;
  }
  .cc-par-0-xl {
    padding-right: 0px;
  }
  .cc-pab-0-xl {
    padding-bottom: 0px;
  }
  .cc-pal-0-xl {
    padding-left: 0px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-0-xl {
    margin: 0px;
  }
  .cc-mat-0-xl {
    margin-top: 0px;
  }
  .cc-mar-0-xl {
    margin-right: 0px;
  }
  .cc-mab-0-xl {
    margin-bottom: 0px;
  }
  .cc-mal-0-xl {
    margin-left: 0px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-5-xl {
    padding: 5px;
  }
  .cc-pat-5-xl {
    padding-top: 5px;
  }
  .cc-par-5-xl {
    padding-right: 5px;
  }
  .cc-pab-5-xl {
    padding-bottom: 5px;
  }
  .cc-pal-5-xl {
    padding-left: 5px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-5-xl {
    margin: 5px;
  }
  .cc-mat-5-xl {
    margin-top: 5px;
  }
  .cc-mar-5-xl {
    margin-right: 5px;
  }
  .cc-mab-5-xl {
    margin-bottom: 5px;
  }
  .cc-mal-5-xl {
    margin-left: 5px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-10-xl {
    padding: 10px;
  }
  .cc-pat-10-xl {
    padding-top: 10px;
  }
  .cc-par-10-xl {
    padding-right: 10px;
  }
  .cc-pab-10-xl {
    padding-bottom: 10px;
  }
  .cc-pal-10-xl {
    padding-left: 10px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-10-xl {
    margin: 10px;
  }
  .cc-mat-10-xl {
    margin-top: 10px;
  }
  .cc-mar-10-xl {
    margin-right: 10px;
  }
  .cc-mab-10-xl {
    margin-bottom: 10px;
  }
  .cc-mal-10-xl {
    margin-left: 10px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-15-xl {
    padding: 15px;
  }
  .cc-pat-15-xl {
    padding-top: 15px;
  }
  .cc-par-15-xl {
    padding-right: 15px;
  }
  .cc-pab-15-xl {
    padding-bottom: 15px;
  }
  .cc-pal-15-xl {
    padding-left: 15px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-15-xl {
    margin: 15px;
  }
  .cc-mat-15-xl {
    margin-top: 15px;
  }
  .cc-mar-15-xl {
    margin-right: 15px;
  }
  .cc-mab-15-xl {
    margin-bottom: 15px;
  }
  .cc-mal-15-xl {
    margin-left: 15px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-20-xl {
    padding: 20px;
  }
  .cc-pat-20-xl {
    padding-top: 20px;
  }
  .cc-par-20-xl {
    padding-right: 20px;
  }
  .cc-pab-20-xl {
    padding-bottom: 20px;
  }
  .cc-pal-20-xl {
    padding-left: 20px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-20-xl {
    margin: 20px;
  }
  .cc-mat-20-xl {
    margin-top: 20px;
  }
  .cc-mar-20-xl {
    margin-right: 20px;
  }
  .cc-mab-20-xl {
    margin-bottom: 20px;
  }
  .cc-mal-20-xl {
    margin-left: 20px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-25-xl {
    padding: 25px;
  }
  .cc-pat-25-xl {
    padding-top: 25px;
  }
  .cc-par-25-xl {
    padding-right: 25px;
  }
  .cc-pab-25-xl {
    padding-bottom: 25px;
  }
  .cc-pal-25-xl {
    padding-left: 25px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-25-xl {
    margin: 25px;
  }
  .cc-mat-25-xl {
    margin-top: 25px;
  }
  .cc-mar-25-xl {
    margin-right: 25px;
  }
  .cc-mab-25-xl {
    margin-bottom: 25px;
  }
  .cc-mal-25-xl {
    margin-left: 25px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-30-xl {
    padding: 30px;
  }
  .cc-pat-30-xl {
    padding-top: 30px;
  }
  .cc-par-30-xl {
    padding-right: 30px;
  }
  .cc-pab-30-xl {
    padding-bottom: 30px;
  }
  .cc-pal-30-xl {
    padding-left: 30px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-30-xl {
    margin: 30px;
  }
  .cc-mat-30-xl {
    margin-top: 30px;
  }
  .cc-mar-30-xl {
    margin-right: 30px;
  }
  .cc-mab-30-xl {
    margin-bottom: 30px;
  }
  .cc-mal-30-xl {
    margin-left: 30px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-35-xl {
    padding: 35px;
  }
  .cc-pat-35-xl {
    padding-top: 35px;
  }
  .cc-par-35-xl {
    padding-right: 35px;
  }
  .cc-pab-35-xl {
    padding-bottom: 35px;
  }
  .cc-pal-35-xl {
    padding-left: 35px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-35-xl {
    margin: 35px;
  }
  .cc-mat-35-xl {
    margin-top: 35px;
  }
  .cc-mar-35-xl {
    margin-right: 35px;
  }
  .cc-mab-35-xl {
    margin-bottom: 35px;
  }
  .cc-mal-35-xl {
    margin-left: 35px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-40-xl {
    padding: 40px;
  }
  .cc-pat-40-xl {
    padding-top: 40px;
  }
  .cc-par-40-xl {
    padding-right: 40px;
  }
  .cc-pab-40-xl {
    padding-bottom: 40px;
  }
  .cc-pal-40-xl {
    padding-left: 40px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-40-xl {
    margin: 40px;
  }
  .cc-mat-40-xl {
    margin-top: 40px;
  }
  .cc-mar-40-xl {
    margin-right: 40px;
  }
  .cc-mab-40-xl {
    margin-bottom: 40px;
  }
  .cc-mal-40-xl {
    margin-left: 40px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-45-xl {
    padding: 45px;
  }
  .cc-pat-45-xl {
    padding-top: 45px;
  }
  .cc-par-45-xl {
    padding-right: 45px;
  }
  .cc-pab-45-xl {
    padding-bottom: 45px;
  }
  .cc-pal-45-xl {
    padding-left: 45px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-45-xl {
    margin: 45px;
  }
  .cc-mat-45-xl {
    margin-top: 45px;
  }
  .cc-mar-45-xl {
    margin-right: 45px;
  }
  .cc-mab-45-xl {
    margin-bottom: 45px;
  }
  .cc-mal-45-xl {
    margin-left: 45px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-50-xl {
    padding: 50px;
  }
  .cc-pat-50-xl {
    padding-top: 50px;
  }
  .cc-par-50-xl {
    padding-right: 50px;
  }
  .cc-pab-50-xl {
    padding-bottom: 50px;
  }
  .cc-pal-50-xl {
    padding-left: 50px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-50-xl {
    margin: 50px;
  }
  .cc-mat-50-xl {
    margin-top: 50px;
  }
  .cc-mar-50-xl {
    margin-right: 50px;
  }
  .cc-mab-50-xl {
    margin-bottom: 50px;
  }
  .cc-mal-50-xl {
    margin-left: 50px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-55-xl {
    padding: 55px;
  }
  .cc-pat-55-xl {
    padding-top: 55px;
  }
  .cc-par-55-xl {
    padding-right: 55px;
  }
  .cc-pab-55-xl {
    padding-bottom: 55px;
  }
  .cc-pal-55-xl {
    padding-left: 55px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-55-xl {
    margin: 55px;
  }
  .cc-mat-55-xl {
    margin-top: 55px;
  }
  .cc-mar-55-xl {
    margin-right: 55px;
  }
  .cc-mab-55-xl {
    margin-bottom: 55px;
  }
  .cc-mal-55-xl {
    margin-left: 55px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-60-xl {
    padding: 60px;
  }
  .cc-pat-60-xl {
    padding-top: 60px;
  }
  .cc-par-60-xl {
    padding-right: 60px;
  }
  .cc-pab-60-xl {
    padding-bottom: 60px;
  }
  .cc-pal-60-xl {
    padding-left: 60px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-60-xl {
    margin: 60px;
  }
  .cc-mat-60-xl {
    margin-top: 60px;
  }
  .cc-mar-60-xl {
    margin-right: 60px;
  }
  .cc-mab-60-xl {
    margin-bottom: 60px;
  }
  .cc-mal-60-xl {
    margin-left: 60px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-65-xl {
    padding: 65px;
  }
  .cc-pat-65-xl {
    padding-top: 65px;
  }
  .cc-par-65-xl {
    padding-right: 65px;
  }
  .cc-pab-65-xl {
    padding-bottom: 65px;
  }
  .cc-pal-65-xl {
    padding-left: 65px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-65-xl {
    margin: 65px;
  }
  .cc-mat-65-xl {
    margin-top: 65px;
  }
  .cc-mar-65-xl {
    margin-right: 65px;
  }
  .cc-mab-65-xl {
    margin-bottom: 65px;
  }
  .cc-mal-65-xl {
    margin-left: 65px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-70-xl {
    padding: 70px;
  }
  .cc-pat-70-xl {
    padding-top: 70px;
  }
  .cc-par-70-xl {
    padding-right: 70px;
  }
  .cc-pab-70-xl {
    padding-bottom: 70px;
  }
  .cc-pal-70-xl {
    padding-left: 70px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-70-xl {
    margin: 70px;
  }
  .cc-mat-70-xl {
    margin-top: 70px;
  }
  .cc-mar-70-xl {
    margin-right: 70px;
  }
  .cc-mab-70-xl {
    margin-bottom: 70px;
  }
  .cc-mal-70-xl {
    margin-left: 70px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-75-xl {
    padding: 75px;
  }
  .cc-pat-75-xl {
    padding-top: 75px;
  }
  .cc-par-75-xl {
    padding-right: 75px;
  }
  .cc-pab-75-xl {
    padding-bottom: 75px;
  }
  .cc-pal-75-xl {
    padding-left: 75px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-75-xl {
    margin: 75px;
  }
  .cc-mat-75-xl {
    margin-top: 75px;
  }
  .cc-mar-75-xl {
    margin-right: 75px;
  }
  .cc-mab-75-xl {
    margin-bottom: 75px;
  }
  .cc-mal-75-xl {
    margin-left: 75px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-80-xl {
    padding: 80px;
  }
  .cc-pat-80-xl {
    padding-top: 80px;
  }
  .cc-par-80-xl {
    padding-right: 80px;
  }
  .cc-pab-80-xl {
    padding-bottom: 80px;
  }
  .cc-pal-80-xl {
    padding-left: 80px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-80-xl {
    margin: 80px;
  }
  .cc-mat-80-xl {
    margin-top: 80px;
  }
  .cc-mar-80-xl {
    margin-right: 80px;
  }
  .cc-mab-80-xl {
    margin-bottom: 80px;
  }
  .cc-mal-80-xl {
    margin-left: 80px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-85-xl {
    padding: 85px;
  }
  .cc-pat-85-xl {
    padding-top: 85px;
  }
  .cc-par-85-xl {
    padding-right: 85px;
  }
  .cc-pab-85-xl {
    padding-bottom: 85px;
  }
  .cc-pal-85-xl {
    padding-left: 85px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-85-xl {
    margin: 85px;
  }
  .cc-mat-85-xl {
    margin-top: 85px;
  }
  .cc-mar-85-xl {
    margin-right: 85px;
  }
  .cc-mab-85-xl {
    margin-bottom: 85px;
  }
  .cc-mal-85-xl {
    margin-left: 85px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-90-xl {
    padding: 90px;
  }
  .cc-pat-90-xl {
    padding-top: 90px;
  }
  .cc-par-90-xl {
    padding-right: 90px;
  }
  .cc-pab-90-xl {
    padding-bottom: 90px;
  }
  .cc-pal-90-xl {
    padding-left: 90px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-90-xl {
    margin: 90px;
  }
  .cc-mat-90-xl {
    margin-top: 90px;
  }
  .cc-mar-90-xl {
    margin-right: 90px;
  }
  .cc-mab-90-xl {
    margin-bottom: 90px;
  }
  .cc-mal-90-xl {
    margin-left: 90px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-95-xl {
    padding: 95px;
  }
  .cc-pat-95-xl {
    padding-top: 95px;
  }
  .cc-par-95-xl {
    padding-right: 95px;
  }
  .cc-pab-95-xl {
    padding-bottom: 95px;
  }
  .cc-pal-95-xl {
    padding-left: 95px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-95-xl {
    margin: 95px;
  }
  .cc-mat-95-xl {
    margin-top: 95px;
  }
  .cc-mar-95-xl {
    margin-right: 95px;
  }
  .cc-mab-95-xl {
    margin-bottom: 95px;
  }
  .cc-mal-95-xl {
    margin-left: 95px;
  }
  .cc-pa-xl {
    padding: 1em;
  }
  .cc-pat-xl {
    padding-top: 1em;
  }
  .cc-par-xl {
    padding-right: 1em;
  }
  .cc-pab-xl {
    padding-bottom: 1em;
  }
  .cc-pal-xl {
    padding-left: 1em;
  }
  .cc-pa-100-xl {
    padding: 100px;
  }
  .cc-pat-100-xl {
    padding-top: 100px;
  }
  .cc-par-100-xl {
    padding-right: 100px;
  }
  .cc-pab-100-xl {
    padding-bottom: 100px;
  }
  .cc-pal-100-xl {
    padding-left: 100px;
  }
  .cc-ma-xl {
    margin: 1em;
  }
  .cc-mat-xl {
    margin-top: 1em;
  }
  .cc-mar-xl {
    margin-right: 1em;
  }
  .cc-mab-xl {
    margin-bottom: 1em;
  }
  .cc-mal-xl {
    margin-left: 1em;
  }
  .cc-ma-100-xl {
    margin: 100px;
  }
  .cc-mat-100-xl {
    margin-top: 100px;
  }
  .cc-mar-100-xl {
    margin-right: 100px;
  }
  .cc-mab-100-xl {
    margin-bottom: 100px;
  }
  .cc-mal-100-xl {
    margin-left: 100px;
  }
}
/* *****
    @name : ._generateColumns
    @description : Generate columns width in flexbox parent
***** */
/* *****
    @name : ._generatePercentWidth
    @description : Generate width for block helpers in %
***** */
.cc-w-0 {
  width: 0%;
}
.cc-w-1 {
  width: 1%;
}
.cc-w-2 {
  width: 2%;
}
.cc-w-3 {
  width: 3%;
}
.cc-w-4 {
  width: 4%;
}
.cc-w-5 {
  width: 5%;
}
.cc-w-6 {
  width: 6%;
}
.cc-w-7 {
  width: 7%;
}
.cc-w-8 {
  width: 8%;
}
.cc-w-9 {
  width: 9%;
}
.cc-w-10 {
  width: 10%;
}
.cc-w-11 {
  width: 11%;
}
.cc-w-12 {
  width: 12%;
}
.cc-w-13 {
  width: 13%;
}
.cc-w-14 {
  width: 14%;
}
.cc-w-15 {
  width: 15%;
}
.cc-w-16 {
  width: 16%;
}
.cc-w-17 {
  width: 17%;
}
.cc-w-18 {
  width: 18%;
}
.cc-w-19 {
  width: 19%;
}
.cc-w-20 {
  width: 20%;
}
.cc-w-21 {
  width: 21%;
}
.cc-w-22 {
  width: 22%;
}
.cc-w-23 {
  width: 23%;
}
.cc-w-24 {
  width: 24%;
}
.cc-w-25 {
  width: 25%;
}
.cc-w-26 {
  width: 26%;
}
.cc-w-27 {
  width: 27%;
}
.cc-w-28 {
  width: 28%;
}
.cc-w-29 {
  width: 29%;
}
.cc-w-30 {
  width: 30%;
}
.cc-w-31 {
  width: 31%;
}
.cc-w-32 {
  width: 32%;
}
.cc-w-33 {
  width: 33%;
}
.cc-w-34 {
  width: 34%;
}
.cc-w-35 {
  width: 35%;
}
.cc-w-36 {
  width: 36%;
}
.cc-w-37 {
  width: 37%;
}
.cc-w-38 {
  width: 38%;
}
.cc-w-39 {
  width: 39%;
}
.cc-w-40 {
  width: 40%;
}
.cc-w-41 {
  width: 41%;
}
.cc-w-42 {
  width: 42%;
}
.cc-w-43 {
  width: 43%;
}
.cc-w-44 {
  width: 44%;
}
.cc-w-45 {
  width: 45%;
}
.cc-w-46 {
  width: 46%;
}
.cc-w-47 {
  width: 47%;
}
.cc-w-48 {
  width: 48%;
}
.cc-w-49 {
  width: 49%;
}
.cc-w-50 {
  width: 50%;
}
.cc-w-51 {
  width: 51%;
}
.cc-w-52 {
  width: 52%;
}
.cc-w-53 {
  width: 53%;
}
.cc-w-54 {
  width: 54%;
}
.cc-w-55 {
  width: 55%;
}
.cc-w-56 {
  width: 56%;
}
.cc-w-57 {
  width: 57%;
}
.cc-w-58 {
  width: 58%;
}
.cc-w-59 {
  width: 59%;
}
.cc-w-60 {
  width: 60%;
}
.cc-w-61 {
  width: 61%;
}
.cc-w-62 {
  width: 62%;
}
.cc-w-63 {
  width: 63%;
}
.cc-w-64 {
  width: 64%;
}
.cc-w-65 {
  width: 65%;
}
.cc-w-66 {
  width: 66%;
}
.cc-w-67 {
  width: 67%;
}
.cc-w-68 {
  width: 68%;
}
.cc-w-69 {
  width: 69%;
}
.cc-w-70 {
  width: 70%;
}
.cc-w-71 {
  width: 71%;
}
.cc-w-72 {
  width: 72%;
}
.cc-w-73 {
  width: 73%;
}
.cc-w-74 {
  width: 74%;
}
.cc-w-75 {
  width: 75%;
}
.cc-w-76 {
  width: 76%;
}
.cc-w-77 {
  width: 77%;
}
.cc-w-78 {
  width: 78%;
}
.cc-w-79 {
  width: 79%;
}
.cc-w-80 {
  width: 80%;
}
.cc-w-81 {
  width: 81%;
}
.cc-w-82 {
  width: 82%;
}
.cc-w-83 {
  width: 83%;
}
.cc-w-84 {
  width: 84%;
}
.cc-w-85 {
  width: 85%;
}
.cc-w-86 {
  width: 86%;
}
.cc-w-87 {
  width: 87%;
}
.cc-w-88 {
  width: 88%;
}
.cc-w-89 {
  width: 89%;
}
.cc-w-90 {
  width: 90%;
}
.cc-w-91 {
  width: 91%;
}
.cc-w-92 {
  width: 92%;
}
.cc-w-93 {
  width: 93%;
}
.cc-w-94 {
  width: 94%;
}
.cc-w-95 {
  width: 95%;
}
.cc-w-96 {
  width: 96%;
}
.cc-w-97 {
  width: 97%;
}
.cc-w-98 {
  width: 98%;
}
.cc-w-99 {
  width: 99%;
}
.cc-w-100 {
  width: 100%;
}
@media screen and (min-width: 360px) {
  .cc-w-0-s {
    width: 0%;
  }
  .cc-w-1-s {
    width: 1%;
  }
  .cc-w-2-s {
    width: 2%;
  }
  .cc-w-3-s {
    width: 3%;
  }
  .cc-w-4-s {
    width: 4%;
  }
  .cc-w-5-s {
    width: 5%;
  }
  .cc-w-6-s {
    width: 6%;
  }
  .cc-w-7-s {
    width: 7%;
  }
  .cc-w-8-s {
    width: 8%;
  }
  .cc-w-9-s {
    width: 9%;
  }
  .cc-w-10-s {
    width: 10%;
  }
  .cc-w-11-s {
    width: 11%;
  }
  .cc-w-12-s {
    width: 12%;
  }
  .cc-w-13-s {
    width: 13%;
  }
  .cc-w-14-s {
    width: 14%;
  }
  .cc-w-15-s {
    width: 15%;
  }
  .cc-w-16-s {
    width: 16%;
  }
  .cc-w-17-s {
    width: 17%;
  }
  .cc-w-18-s {
    width: 18%;
  }
  .cc-w-19-s {
    width: 19%;
  }
  .cc-w-20-s {
    width: 20%;
  }
  .cc-w-21-s {
    width: 21%;
  }
  .cc-w-22-s {
    width: 22%;
  }
  .cc-w-23-s {
    width: 23%;
  }
  .cc-w-24-s {
    width: 24%;
  }
  .cc-w-25-s {
    width: 25%;
  }
  .cc-w-26-s {
    width: 26%;
  }
  .cc-w-27-s {
    width: 27%;
  }
  .cc-w-28-s {
    width: 28%;
  }
  .cc-w-29-s {
    width: 29%;
  }
  .cc-w-30-s {
    width: 30%;
  }
  .cc-w-31-s {
    width: 31%;
  }
  .cc-w-32-s {
    width: 32%;
  }
  .cc-w-33-s {
    width: 33%;
  }
  .cc-w-34-s {
    width: 34%;
  }
  .cc-w-35-s {
    width: 35%;
  }
  .cc-w-36-s {
    width: 36%;
  }
  .cc-w-37-s {
    width: 37%;
  }
  .cc-w-38-s {
    width: 38%;
  }
  .cc-w-39-s {
    width: 39%;
  }
  .cc-w-40-s {
    width: 40%;
  }
  .cc-w-41-s {
    width: 41%;
  }
  .cc-w-42-s {
    width: 42%;
  }
  .cc-w-43-s {
    width: 43%;
  }
  .cc-w-44-s {
    width: 44%;
  }
  .cc-w-45-s {
    width: 45%;
  }
  .cc-w-46-s {
    width: 46%;
  }
  .cc-w-47-s {
    width: 47%;
  }
  .cc-w-48-s {
    width: 48%;
  }
  .cc-w-49-s {
    width: 49%;
  }
  .cc-w-50-s {
    width: 50%;
  }
  .cc-w-51-s {
    width: 51%;
  }
  .cc-w-52-s {
    width: 52%;
  }
  .cc-w-53-s {
    width: 53%;
  }
  .cc-w-54-s {
    width: 54%;
  }
  .cc-w-55-s {
    width: 55%;
  }
  .cc-w-56-s {
    width: 56%;
  }
  .cc-w-57-s {
    width: 57%;
  }
  .cc-w-58-s {
    width: 58%;
  }
  .cc-w-59-s {
    width: 59%;
  }
  .cc-w-60-s {
    width: 60%;
  }
  .cc-w-61-s {
    width: 61%;
  }
  .cc-w-62-s {
    width: 62%;
  }
  .cc-w-63-s {
    width: 63%;
  }
  .cc-w-64-s {
    width: 64%;
  }
  .cc-w-65-s {
    width: 65%;
  }
  .cc-w-66-s {
    width: 66%;
  }
  .cc-w-67-s {
    width: 67%;
  }
  .cc-w-68-s {
    width: 68%;
  }
  .cc-w-69-s {
    width: 69%;
  }
  .cc-w-70-s {
    width: 70%;
  }
  .cc-w-71-s {
    width: 71%;
  }
  .cc-w-72-s {
    width: 72%;
  }
  .cc-w-73-s {
    width: 73%;
  }
  .cc-w-74-s {
    width: 74%;
  }
  .cc-w-75-s {
    width: 75%;
  }
  .cc-w-76-s {
    width: 76%;
  }
  .cc-w-77-s {
    width: 77%;
  }
  .cc-w-78-s {
    width: 78%;
  }
  .cc-w-79-s {
    width: 79%;
  }
  .cc-w-80-s {
    width: 80%;
  }
  .cc-w-81-s {
    width: 81%;
  }
  .cc-w-82-s {
    width: 82%;
  }
  .cc-w-83-s {
    width: 83%;
  }
  .cc-w-84-s {
    width: 84%;
  }
  .cc-w-85-s {
    width: 85%;
  }
  .cc-w-86-s {
    width: 86%;
  }
  .cc-w-87-s {
    width: 87%;
  }
  .cc-w-88-s {
    width: 88%;
  }
  .cc-w-89-s {
    width: 89%;
  }
  .cc-w-90-s {
    width: 90%;
  }
  .cc-w-91-s {
    width: 91%;
  }
  .cc-w-92-s {
    width: 92%;
  }
  .cc-w-93-s {
    width: 93%;
  }
  .cc-w-94-s {
    width: 94%;
  }
  .cc-w-95-s {
    width: 95%;
  }
  .cc-w-96-s {
    width: 96%;
  }
  .cc-w-97-s {
    width: 97%;
  }
  .cc-w-98-s {
    width: 98%;
  }
  .cc-w-99-s {
    width: 99%;
  }
  .cc-w-100-s {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .cc-w-0-m {
    width: 0%;
  }
  .cc-w-1-m {
    width: 1%;
  }
  .cc-w-2-m {
    width: 2%;
  }
  .cc-w-3-m {
    width: 3%;
  }
  .cc-w-4-m {
    width: 4%;
  }
  .cc-w-5-m {
    width: 5%;
  }
  .cc-w-6-m {
    width: 6%;
  }
  .cc-w-7-m {
    width: 7%;
  }
  .cc-w-8-m {
    width: 8%;
  }
  .cc-w-9-m {
    width: 9%;
  }
  .cc-w-10-m {
    width: 10%;
  }
  .cc-w-11-m {
    width: 11%;
  }
  .cc-w-12-m {
    width: 12%;
  }
  .cc-w-13-m {
    width: 13%;
  }
  .cc-w-14-m {
    width: 14%;
  }
  .cc-w-15-m {
    width: 15%;
  }
  .cc-w-16-m {
    width: 16%;
  }
  .cc-w-17-m {
    width: 17%;
  }
  .cc-w-18-m {
    width: 18%;
  }
  .cc-w-19-m {
    width: 19%;
  }
  .cc-w-20-m {
    width: 20%;
  }
  .cc-w-21-m {
    width: 21%;
  }
  .cc-w-22-m {
    width: 22%;
  }
  .cc-w-23-m {
    width: 23%;
  }
  .cc-w-24-m {
    width: 24%;
  }
  .cc-w-25-m {
    width: 25%;
  }
  .cc-w-26-m {
    width: 26%;
  }
  .cc-w-27-m {
    width: 27%;
  }
  .cc-w-28-m {
    width: 28%;
  }
  .cc-w-29-m {
    width: 29%;
  }
  .cc-w-30-m {
    width: 30%;
  }
  .cc-w-31-m {
    width: 31%;
  }
  .cc-w-32-m {
    width: 32%;
  }
  .cc-w-33-m {
    width: 33%;
  }
  .cc-w-34-m {
    width: 34%;
  }
  .cc-w-35-m {
    width: 35%;
  }
  .cc-w-36-m {
    width: 36%;
  }
  .cc-w-37-m {
    width: 37%;
  }
  .cc-w-38-m {
    width: 38%;
  }
  .cc-w-39-m {
    width: 39%;
  }
  .cc-w-40-m {
    width: 40%;
  }
  .cc-w-41-m {
    width: 41%;
  }
  .cc-w-42-m {
    width: 42%;
  }
  .cc-w-43-m {
    width: 43%;
  }
  .cc-w-44-m {
    width: 44%;
  }
  .cc-w-45-m {
    width: 45%;
  }
  .cc-w-46-m {
    width: 46%;
  }
  .cc-w-47-m {
    width: 47%;
  }
  .cc-w-48-m {
    width: 48%;
  }
  .cc-w-49-m {
    width: 49%;
  }
  .cc-w-50-m {
    width: 50%;
  }
  .cc-w-51-m {
    width: 51%;
  }
  .cc-w-52-m {
    width: 52%;
  }
  .cc-w-53-m {
    width: 53%;
  }
  .cc-w-54-m {
    width: 54%;
  }
  .cc-w-55-m {
    width: 55%;
  }
  .cc-w-56-m {
    width: 56%;
  }
  .cc-w-57-m {
    width: 57%;
  }
  .cc-w-58-m {
    width: 58%;
  }
  .cc-w-59-m {
    width: 59%;
  }
  .cc-w-60-m {
    width: 60%;
  }
  .cc-w-61-m {
    width: 61%;
  }
  .cc-w-62-m {
    width: 62%;
  }
  .cc-w-63-m {
    width: 63%;
  }
  .cc-w-64-m {
    width: 64%;
  }
  .cc-w-65-m {
    width: 65%;
  }
  .cc-w-66-m {
    width: 66%;
  }
  .cc-w-67-m {
    width: 67%;
  }
  .cc-w-68-m {
    width: 68%;
  }
  .cc-w-69-m {
    width: 69%;
  }
  .cc-w-70-m {
    width: 70%;
  }
  .cc-w-71-m {
    width: 71%;
  }
  .cc-w-72-m {
    width: 72%;
  }
  .cc-w-73-m {
    width: 73%;
  }
  .cc-w-74-m {
    width: 74%;
  }
  .cc-w-75-m {
    width: 75%;
  }
  .cc-w-76-m {
    width: 76%;
  }
  .cc-w-77-m {
    width: 77%;
  }
  .cc-w-78-m {
    width: 78%;
  }
  .cc-w-79-m {
    width: 79%;
  }
  .cc-w-80-m {
    width: 80%;
  }
  .cc-w-81-m {
    width: 81%;
  }
  .cc-w-82-m {
    width: 82%;
  }
  .cc-w-83-m {
    width: 83%;
  }
  .cc-w-84-m {
    width: 84%;
  }
  .cc-w-85-m {
    width: 85%;
  }
  .cc-w-86-m {
    width: 86%;
  }
  .cc-w-87-m {
    width: 87%;
  }
  .cc-w-88-m {
    width: 88%;
  }
  .cc-w-89-m {
    width: 89%;
  }
  .cc-w-90-m {
    width: 90%;
  }
  .cc-w-91-m {
    width: 91%;
  }
  .cc-w-92-m {
    width: 92%;
  }
  .cc-w-93-m {
    width: 93%;
  }
  .cc-w-94-m {
    width: 94%;
  }
  .cc-w-95-m {
    width: 95%;
  }
  .cc-w-96-m {
    width: 96%;
  }
  .cc-w-97-m {
    width: 97%;
  }
  .cc-w-98-m {
    width: 98%;
  }
  .cc-w-99-m {
    width: 99%;
  }
  .cc-w-100-m {
    width: 100%;
  }
}
@media screen and (min-width: 990px) {
  .cc-w-0-l {
    width: 0%;
  }
  .cc-w-1-l {
    width: 1%;
  }
  .cc-w-2-l {
    width: 2%;
  }
  .cc-w-3-l {
    width: 3%;
  }
  .cc-w-4-l {
    width: 4%;
  }
  .cc-w-5-l {
    width: 5%;
  }
  .cc-w-6-l {
    width: 6%;
  }
  .cc-w-7-l {
    width: 7%;
  }
  .cc-w-8-l {
    width: 8%;
  }
  .cc-w-9-l {
    width: 9%;
  }
  .cc-w-10-l {
    width: 10%;
  }
  .cc-w-11-l {
    width: 11%;
  }
  .cc-w-12-l {
    width: 12%;
  }
  .cc-w-13-l {
    width: 13%;
  }
  .cc-w-14-l {
    width: 14%;
  }
  .cc-w-15-l {
    width: 15%;
  }
  .cc-w-16-l {
    width: 16%;
  }
  .cc-w-17-l {
    width: 17%;
  }
  .cc-w-18-l {
    width: 18%;
  }
  .cc-w-19-l {
    width: 19%;
  }
  .cc-w-20-l {
    width: 20%;
  }
  .cc-w-21-l {
    width: 21%;
  }
  .cc-w-22-l {
    width: 22%;
  }
  .cc-w-23-l {
    width: 23%;
  }
  .cc-w-24-l {
    width: 24%;
  }
  .cc-w-25-l {
    width: 25%;
  }
  .cc-w-26-l {
    width: 26%;
  }
  .cc-w-27-l {
    width: 27%;
  }
  .cc-w-28-l {
    width: 28%;
  }
  .cc-w-29-l {
    width: 29%;
  }
  .cc-w-30-l {
    width: 30%;
  }
  .cc-w-31-l {
    width: 31%;
  }
  .cc-w-32-l {
    width: 32%;
  }
  .cc-w-33-l {
    width: 33%;
  }
  .cc-w-34-l {
    width: 34%;
  }
  .cc-w-35-l {
    width: 35%;
  }
  .cc-w-36-l {
    width: 36%;
  }
  .cc-w-37-l {
    width: 37%;
  }
  .cc-w-38-l {
    width: 38%;
  }
  .cc-w-39-l {
    width: 39%;
  }
  .cc-w-40-l {
    width: 40%;
  }
  .cc-w-41-l {
    width: 41%;
  }
  .cc-w-42-l {
    width: 42%;
  }
  .cc-w-43-l {
    width: 43%;
  }
  .cc-w-44-l {
    width: 44%;
  }
  .cc-w-45-l {
    width: 45%;
  }
  .cc-w-46-l {
    width: 46%;
  }
  .cc-w-47-l {
    width: 47%;
  }
  .cc-w-48-l {
    width: 48%;
  }
  .cc-w-49-l {
    width: 49%;
  }
  .cc-w-50-l {
    width: 50%;
  }
  .cc-w-51-l {
    width: 51%;
  }
  .cc-w-52-l {
    width: 52%;
  }
  .cc-w-53-l {
    width: 53%;
  }
  .cc-w-54-l {
    width: 54%;
  }
  .cc-w-55-l {
    width: 55%;
  }
  .cc-w-56-l {
    width: 56%;
  }
  .cc-w-57-l {
    width: 57%;
  }
  .cc-w-58-l {
    width: 58%;
  }
  .cc-w-59-l {
    width: 59%;
  }
  .cc-w-60-l {
    width: 60%;
  }
  .cc-w-61-l {
    width: 61%;
  }
  .cc-w-62-l {
    width: 62%;
  }
  .cc-w-63-l {
    width: 63%;
  }
  .cc-w-64-l {
    width: 64%;
  }
  .cc-w-65-l {
    width: 65%;
  }
  .cc-w-66-l {
    width: 66%;
  }
  .cc-w-67-l {
    width: 67%;
  }
  .cc-w-68-l {
    width: 68%;
  }
  .cc-w-69-l {
    width: 69%;
  }
  .cc-w-70-l {
    width: 70%;
  }
  .cc-w-71-l {
    width: 71%;
  }
  .cc-w-72-l {
    width: 72%;
  }
  .cc-w-73-l {
    width: 73%;
  }
  .cc-w-74-l {
    width: 74%;
  }
  .cc-w-75-l {
    width: 75%;
  }
  .cc-w-76-l {
    width: 76%;
  }
  .cc-w-77-l {
    width: 77%;
  }
  .cc-w-78-l {
    width: 78%;
  }
  .cc-w-79-l {
    width: 79%;
  }
  .cc-w-80-l {
    width: 80%;
  }
  .cc-w-81-l {
    width: 81%;
  }
  .cc-w-82-l {
    width: 82%;
  }
  .cc-w-83-l {
    width: 83%;
  }
  .cc-w-84-l {
    width: 84%;
  }
  .cc-w-85-l {
    width: 85%;
  }
  .cc-w-86-l {
    width: 86%;
  }
  .cc-w-87-l {
    width: 87%;
  }
  .cc-w-88-l {
    width: 88%;
  }
  .cc-w-89-l {
    width: 89%;
  }
  .cc-w-90-l {
    width: 90%;
  }
  .cc-w-91-l {
    width: 91%;
  }
  .cc-w-92-l {
    width: 92%;
  }
  .cc-w-93-l {
    width: 93%;
  }
  .cc-w-94-l {
    width: 94%;
  }
  .cc-w-95-l {
    width: 95%;
  }
  .cc-w-96-l {
    width: 96%;
  }
  .cc-w-97-l {
    width: 97%;
  }
  .cc-w-98-l {
    width: 98%;
  }
  .cc-w-99-l {
    width: 99%;
  }
  .cc-w-100-l {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .cc-w-0-xl {
    width: 0%;
  }
  .cc-w-1-xl {
    width: 1%;
  }
  .cc-w-2-xl {
    width: 2%;
  }
  .cc-w-3-xl {
    width: 3%;
  }
  .cc-w-4-xl {
    width: 4%;
  }
  .cc-w-5-xl {
    width: 5%;
  }
  .cc-w-6-xl {
    width: 6%;
  }
  .cc-w-7-xl {
    width: 7%;
  }
  .cc-w-8-xl {
    width: 8%;
  }
  .cc-w-9-xl {
    width: 9%;
  }
  .cc-w-10-xl {
    width: 10%;
  }
  .cc-w-11-xl {
    width: 11%;
  }
  .cc-w-12-xl {
    width: 12%;
  }
  .cc-w-13-xl {
    width: 13%;
  }
  .cc-w-14-xl {
    width: 14%;
  }
  .cc-w-15-xl {
    width: 15%;
  }
  .cc-w-16-xl {
    width: 16%;
  }
  .cc-w-17-xl {
    width: 17%;
  }
  .cc-w-18-xl {
    width: 18%;
  }
  .cc-w-19-xl {
    width: 19%;
  }
  .cc-w-20-xl {
    width: 20%;
  }
  .cc-w-21-xl {
    width: 21%;
  }
  .cc-w-22-xl {
    width: 22%;
  }
  .cc-w-23-xl {
    width: 23%;
  }
  .cc-w-24-xl {
    width: 24%;
  }
  .cc-w-25-xl {
    width: 25%;
  }
  .cc-w-26-xl {
    width: 26%;
  }
  .cc-w-27-xl {
    width: 27%;
  }
  .cc-w-28-xl {
    width: 28%;
  }
  .cc-w-29-xl {
    width: 29%;
  }
  .cc-w-30-xl {
    width: 30%;
  }
  .cc-w-31-xl {
    width: 31%;
  }
  .cc-w-32-xl {
    width: 32%;
  }
  .cc-w-33-xl {
    width: 33%;
  }
  .cc-w-34-xl {
    width: 34%;
  }
  .cc-w-35-xl {
    width: 35%;
  }
  .cc-w-36-xl {
    width: 36%;
  }
  .cc-w-37-xl {
    width: 37%;
  }
  .cc-w-38-xl {
    width: 38%;
  }
  .cc-w-39-xl {
    width: 39%;
  }
  .cc-w-40-xl {
    width: 40%;
  }
  .cc-w-41-xl {
    width: 41%;
  }
  .cc-w-42-xl {
    width: 42%;
  }
  .cc-w-43-xl {
    width: 43%;
  }
  .cc-w-44-xl {
    width: 44%;
  }
  .cc-w-45-xl {
    width: 45%;
  }
  .cc-w-46-xl {
    width: 46%;
  }
  .cc-w-47-xl {
    width: 47%;
  }
  .cc-w-48-xl {
    width: 48%;
  }
  .cc-w-49-xl {
    width: 49%;
  }
  .cc-w-50-xl {
    width: 50%;
  }
  .cc-w-51-xl {
    width: 51%;
  }
  .cc-w-52-xl {
    width: 52%;
  }
  .cc-w-53-xl {
    width: 53%;
  }
  .cc-w-54-xl {
    width: 54%;
  }
  .cc-w-55-xl {
    width: 55%;
  }
  .cc-w-56-xl {
    width: 56%;
  }
  .cc-w-57-xl {
    width: 57%;
  }
  .cc-w-58-xl {
    width: 58%;
  }
  .cc-w-59-xl {
    width: 59%;
  }
  .cc-w-60-xl {
    width: 60%;
  }
  .cc-w-61-xl {
    width: 61%;
  }
  .cc-w-62-xl {
    width: 62%;
  }
  .cc-w-63-xl {
    width: 63%;
  }
  .cc-w-64-xl {
    width: 64%;
  }
  .cc-w-65-xl {
    width: 65%;
  }
  .cc-w-66-xl {
    width: 66%;
  }
  .cc-w-67-xl {
    width: 67%;
  }
  .cc-w-68-xl {
    width: 68%;
  }
  .cc-w-69-xl {
    width: 69%;
  }
  .cc-w-70-xl {
    width: 70%;
  }
  .cc-w-71-xl {
    width: 71%;
  }
  .cc-w-72-xl {
    width: 72%;
  }
  .cc-w-73-xl {
    width: 73%;
  }
  .cc-w-74-xl {
    width: 74%;
  }
  .cc-w-75-xl {
    width: 75%;
  }
  .cc-w-76-xl {
    width: 76%;
  }
  .cc-w-77-xl {
    width: 77%;
  }
  .cc-w-78-xl {
    width: 78%;
  }
  .cc-w-79-xl {
    width: 79%;
  }
  .cc-w-80-xl {
    width: 80%;
  }
  .cc-w-81-xl {
    width: 81%;
  }
  .cc-w-82-xl {
    width: 82%;
  }
  .cc-w-83-xl {
    width: 83%;
  }
  .cc-w-84-xl {
    width: 84%;
  }
  .cc-w-85-xl {
    width: 85%;
  }
  .cc-w-86-xl {
    width: 86%;
  }
  .cc-w-87-xl {
    width: 87%;
  }
  .cc-w-88-xl {
    width: 88%;
  }
  .cc-w-89-xl {
    width: 89%;
  }
  .cc-w-90-xl {
    width: 90%;
  }
  .cc-w-91-xl {
    width: 91%;
  }
  .cc-w-92-xl {
    width: 92%;
  }
  .cc-w-93-xl {
    width: 93%;
  }
  .cc-w-94-xl {
    width: 94%;
  }
  .cc-w-95-xl {
    width: 95%;
  }
  .cc-w-96-xl {
    width: 96%;
  }
  .cc-w-97-xl {
    width: 97%;
  }
  .cc-w-98-xl {
    width: 98%;
  }
  .cc-w-99-xl {
    width: 99%;
  }
  .cc-w-100-xl {
    width: 100%;
  }
}
/* *****
    @name : ._generateFontSize
    @description : Generate font-size from 0rem to 10rem;
***** */
.cc-fs-0 {
  font-size: 0rem;
}
.cc-fs-1 {
  font-size: 0.1rem;
}
.cc-fs-2 {
  font-size: 0.2rem;
}
.cc-fs-3 {
  font-size: 0.3rem;
}
.cc-fs-4 {
  font-size: 0.4rem;
}
.cc-fs-5 {
  font-size: 0.5rem;
}
.cc-fs-6 {
  font-size: 0.6rem;
}
.cc-fs-7 {
  font-size: 0.7rem;
}
.cc-fs-8 {
  font-size: 0.8rem;
}
.cc-fs-9 {
  font-size: 0.9rem;
}
.cc-fs-10 {
  font-size: 1rem;
}
.cc-fs-11 {
  font-size: 1.1rem;
}
.cc-fs-12 {
  font-size: 1.2rem;
}
.cc-fs-13 {
  font-size: 1.3rem;
}
.cc-fs-14 {
  font-size: 1.4rem;
}
.cc-fs-15 {
  font-size: 1.5rem;
}
.cc-fs-16 {
  font-size: 1.6rem;
}
.cc-fs-17 {
  font-size: 1.7rem;
}
.cc-fs-18 {
  font-size: 1.8rem;
}
.cc-fs-19 {
  font-size: 1.9rem;
}
.cc-fs-20 {
  font-size: 2rem;
}
.cc-fs-21 {
  font-size: 2.1rem;
}
.cc-fs-22 {
  font-size: 2.2rem;
}
.cc-fs-23 {
  font-size: 2.3rem;
}
.cc-fs-24 {
  font-size: 2.4rem;
}
.cc-fs-25 {
  font-size: 2.5rem;
}
.cc-fs-26 {
  font-size: 2.6rem;
}
.cc-fs-27 {
  font-size: 2.7rem;
}
.cc-fs-28 {
  font-size: 2.8rem;
}
.cc-fs-29 {
  font-size: 2.9rem;
}
.cc-fs-30 {
  font-size: 3rem;
}
.cc-fs-31 {
  font-size: 3.1rem;
}
.cc-fs-32 {
  font-size: 3.2rem;
}
.cc-fs-33 {
  font-size: 3.3rem;
}
.cc-fs-34 {
  font-size: 3.4rem;
}
.cc-fs-35 {
  font-size: 3.5rem;
}
.cc-fs-36 {
  font-size: 3.6rem;
}
.cc-fs-37 {
  font-size: 3.7rem;
}
.cc-fs-38 {
  font-size: 3.8rem;
}
.cc-fs-39 {
  font-size: 3.9rem;
}
.cc-fs-40 {
  font-size: 4rem;
}
.cc-fs-41 {
  font-size: 4.1rem;
}
.cc-fs-42 {
  font-size: 4.2rem;
}
.cc-fs-43 {
  font-size: 4.3rem;
}
.cc-fs-44 {
  font-size: 4.4rem;
}
.cc-fs-45 {
  font-size: 4.5rem;
}
.cc-fs-46 {
  font-size: 4.6rem;
}
.cc-fs-47 {
  font-size: 4.7rem;
}
.cc-fs-48 {
  font-size: 4.8rem;
}
.cc-fs-49 {
  font-size: 4.9rem;
}
.cc-fs-50 {
  font-size: 5rem;
}
.cc-fs-51 {
  font-size: 5.1rem;
}
.cc-fs-52 {
  font-size: 5.2rem;
}
.cc-fs-53 {
  font-size: 5.3rem;
}
.cc-fs-54 {
  font-size: 5.4rem;
}
.cc-fs-55 {
  font-size: 5.5rem;
}
.cc-fs-56 {
  font-size: 5.6rem;
}
.cc-fs-57 {
  font-size: 5.7rem;
}
.cc-fs-58 {
  font-size: 5.8rem;
}
.cc-fs-59 {
  font-size: 5.9rem;
}
.cc-fs-60 {
  font-size: 6rem;
}
.cc-fs-61 {
  font-size: 6.1rem;
}
.cc-fs-62 {
  font-size: 6.2rem;
}
.cc-fs-63 {
  font-size: 6.3rem;
}
.cc-fs-64 {
  font-size: 6.4rem;
}
.cc-fs-65 {
  font-size: 6.5rem;
}
.cc-fs-66 {
  font-size: 6.6rem;
}
.cc-fs-67 {
  font-size: 6.7rem;
}
.cc-fs-68 {
  font-size: 6.8rem;
}
.cc-fs-69 {
  font-size: 6.9rem;
}
.cc-fs-70 {
  font-size: 7rem;
}
.cc-fs-71 {
  font-size: 7.1rem;
}
.cc-fs-72 {
  font-size: 7.2rem;
}
.cc-fs-73 {
  font-size: 7.3rem;
}
.cc-fs-74 {
  font-size: 7.4rem;
}
.cc-fs-75 {
  font-size: 7.5rem;
}
.cc-fs-76 {
  font-size: 7.6rem;
}
.cc-fs-77 {
  font-size: 7.7rem;
}
.cc-fs-78 {
  font-size: 7.8rem;
}
.cc-fs-79 {
  font-size: 7.9rem;
}
.cc-fs-80 {
  font-size: 8rem;
}
.cc-fs-81 {
  font-size: 8.1rem;
}
.cc-fs-82 {
  font-size: 8.2rem;
}
.cc-fs-83 {
  font-size: 8.3rem;
}
.cc-fs-84 {
  font-size: 8.4rem;
}
.cc-fs-85 {
  font-size: 8.5rem;
}
.cc-fs-86 {
  font-size: 8.6rem;
}
.cc-fs-87 {
  font-size: 8.7rem;
}
.cc-fs-88 {
  font-size: 8.8rem;
}
.cc-fs-89 {
  font-size: 8.9rem;
}
.cc-fs-90 {
  font-size: 9rem;
}
.cc-fs-91 {
  font-size: 9.1rem;
}
.cc-fs-92 {
  font-size: 9.2rem;
}
.cc-fs-93 {
  font-size: 9.3rem;
}
.cc-fs-94 {
  font-size: 9.4rem;
}
.cc-fs-95 {
  font-size: 9.5rem;
}
.cc-fs-96 {
  font-size: 9.6rem;
}
.cc-fs-97 {
  font-size: 9.7rem;
}
.cc-fs-98 {
  font-size: 9.8rem;
}
.cc-fs-99 {
  font-size: 9.9rem;
}
.cc-fs-100 {
  font-size: 10rem;
}
@media screen and (min-width: 360px) {
  .cc-fs-0-s {
    font-size: 0rem;
  }
  .cc-fs-1-s {
    font-size: 0.1rem;
  }
  .cc-fs-2-s {
    font-size: 0.2rem;
  }
  .cc-fs-3-s {
    font-size: 0.3rem;
  }
  .cc-fs-4-s {
    font-size: 0.4rem;
  }
  .cc-fs-5-s {
    font-size: 0.5rem;
  }
  .cc-fs-6-s {
    font-size: 0.6rem;
  }
  .cc-fs-7-s {
    font-size: 0.7rem;
  }
  .cc-fs-8-s {
    font-size: 0.8rem;
  }
  .cc-fs-9-s {
    font-size: 0.9rem;
  }
  .cc-fs-10-s {
    font-size: 1rem;
  }
  .cc-fs-11-s {
    font-size: 1.1rem;
  }
  .cc-fs-12-s {
    font-size: 1.2rem;
  }
  .cc-fs-13-s {
    font-size: 1.3rem;
  }
  .cc-fs-14-s {
    font-size: 1.4rem;
  }
  .cc-fs-15-s {
    font-size: 1.5rem;
  }
  .cc-fs-16-s {
    font-size: 1.6rem;
  }
  .cc-fs-17-s {
    font-size: 1.7rem;
  }
  .cc-fs-18-s {
    font-size: 1.8rem;
  }
  .cc-fs-19-s {
    font-size: 1.9rem;
  }
  .cc-fs-20-s {
    font-size: 2rem;
  }
  .cc-fs-21-s {
    font-size: 2.1rem;
  }
  .cc-fs-22-s {
    font-size: 2.2rem;
  }
  .cc-fs-23-s {
    font-size: 2.3rem;
  }
  .cc-fs-24-s {
    font-size: 2.4rem;
  }
  .cc-fs-25-s {
    font-size: 2.5rem;
  }
  .cc-fs-26-s {
    font-size: 2.6rem;
  }
  .cc-fs-27-s {
    font-size: 2.7rem;
  }
  .cc-fs-28-s {
    font-size: 2.8rem;
  }
  .cc-fs-29-s {
    font-size: 2.9rem;
  }
  .cc-fs-30-s {
    font-size: 3rem;
  }
  .cc-fs-31-s {
    font-size: 3.1rem;
  }
  .cc-fs-32-s {
    font-size: 3.2rem;
  }
  .cc-fs-33-s {
    font-size: 3.3rem;
  }
  .cc-fs-34-s {
    font-size: 3.4rem;
  }
  .cc-fs-35-s {
    font-size: 3.5rem;
  }
  .cc-fs-36-s {
    font-size: 3.6rem;
  }
  .cc-fs-37-s {
    font-size: 3.7rem;
  }
  .cc-fs-38-s {
    font-size: 3.8rem;
  }
  .cc-fs-39-s {
    font-size: 3.9rem;
  }
  .cc-fs-40-s {
    font-size: 4rem;
  }
  .cc-fs-41-s {
    font-size: 4.1rem;
  }
  .cc-fs-42-s {
    font-size: 4.2rem;
  }
  .cc-fs-43-s {
    font-size: 4.3rem;
  }
  .cc-fs-44-s {
    font-size: 4.4rem;
  }
  .cc-fs-45-s {
    font-size: 4.5rem;
  }
  .cc-fs-46-s {
    font-size: 4.6rem;
  }
  .cc-fs-47-s {
    font-size: 4.7rem;
  }
  .cc-fs-48-s {
    font-size: 4.8rem;
  }
  .cc-fs-49-s {
    font-size: 4.9rem;
  }
  .cc-fs-50-s {
    font-size: 5rem;
  }
  .cc-fs-51-s {
    font-size: 5.1rem;
  }
  .cc-fs-52-s {
    font-size: 5.2rem;
  }
  .cc-fs-53-s {
    font-size: 5.3rem;
  }
  .cc-fs-54-s {
    font-size: 5.4rem;
  }
  .cc-fs-55-s {
    font-size: 5.5rem;
  }
  .cc-fs-56-s {
    font-size: 5.6rem;
  }
  .cc-fs-57-s {
    font-size: 5.7rem;
  }
  .cc-fs-58-s {
    font-size: 5.8rem;
  }
  .cc-fs-59-s {
    font-size: 5.9rem;
  }
  .cc-fs-60-s {
    font-size: 6rem;
  }
  .cc-fs-61-s {
    font-size: 6.1rem;
  }
  .cc-fs-62-s {
    font-size: 6.2rem;
  }
  .cc-fs-63-s {
    font-size: 6.3rem;
  }
  .cc-fs-64-s {
    font-size: 6.4rem;
  }
  .cc-fs-65-s {
    font-size: 6.5rem;
  }
  .cc-fs-66-s {
    font-size: 6.6rem;
  }
  .cc-fs-67-s {
    font-size: 6.7rem;
  }
  .cc-fs-68-s {
    font-size: 6.8rem;
  }
  .cc-fs-69-s {
    font-size: 6.9rem;
  }
  .cc-fs-70-s {
    font-size: 7rem;
  }
  .cc-fs-71-s {
    font-size: 7.1rem;
  }
  .cc-fs-72-s {
    font-size: 7.2rem;
  }
  .cc-fs-73-s {
    font-size: 7.3rem;
  }
  .cc-fs-74-s {
    font-size: 7.4rem;
  }
  .cc-fs-75-s {
    font-size: 7.5rem;
  }
  .cc-fs-76-s {
    font-size: 7.6rem;
  }
  .cc-fs-77-s {
    font-size: 7.7rem;
  }
  .cc-fs-78-s {
    font-size: 7.8rem;
  }
  .cc-fs-79-s {
    font-size: 7.9rem;
  }
  .cc-fs-80-s {
    font-size: 8rem;
  }
  .cc-fs-81-s {
    font-size: 8.1rem;
  }
  .cc-fs-82-s {
    font-size: 8.2rem;
  }
  .cc-fs-83-s {
    font-size: 8.3rem;
  }
  .cc-fs-84-s {
    font-size: 8.4rem;
  }
  .cc-fs-85-s {
    font-size: 8.5rem;
  }
  .cc-fs-86-s {
    font-size: 8.6rem;
  }
  .cc-fs-87-s {
    font-size: 8.7rem;
  }
  .cc-fs-88-s {
    font-size: 8.8rem;
  }
  .cc-fs-89-s {
    font-size: 8.9rem;
  }
  .cc-fs-90-s {
    font-size: 9rem;
  }
  .cc-fs-91-s {
    font-size: 9.1rem;
  }
  .cc-fs-92-s {
    font-size: 9.2rem;
  }
  .cc-fs-93-s {
    font-size: 9.3rem;
  }
  .cc-fs-94-s {
    font-size: 9.4rem;
  }
  .cc-fs-95-s {
    font-size: 9.5rem;
  }
  .cc-fs-96-s {
    font-size: 9.6rem;
  }
  .cc-fs-97-s {
    font-size: 9.7rem;
  }
  .cc-fs-98-s {
    font-size: 9.8rem;
  }
  .cc-fs-99-s {
    font-size: 9.9rem;
  }
  .cc-fs-100-s {
    font-size: 10rem;
  }
}
@media screen and (min-width: 768px) {
  .cc-fs-0-m {
    font-size: 0rem;
  }
  .cc-fs-1-m {
    font-size: 0.1rem;
  }
  .cc-fs-2-m {
    font-size: 0.2rem;
  }
  .cc-fs-3-m {
    font-size: 0.3rem;
  }
  .cc-fs-4-m {
    font-size: 0.4rem;
  }
  .cc-fs-5-m {
    font-size: 0.5rem;
  }
  .cc-fs-6-m {
    font-size: 0.6rem;
  }
  .cc-fs-7-m {
    font-size: 0.7rem;
  }
  .cc-fs-8-m {
    font-size: 0.8rem;
  }
  .cc-fs-9-m {
    font-size: 0.9rem;
  }
  .cc-fs-10-m {
    font-size: 1rem;
  }
  .cc-fs-11-m {
    font-size: 1.1rem;
  }
  .cc-fs-12-m {
    font-size: 1.2rem;
  }
  .cc-fs-13-m {
    font-size: 1.3rem;
  }
  .cc-fs-14-m {
    font-size: 1.4rem;
  }
  .cc-fs-15-m {
    font-size: 1.5rem;
  }
  .cc-fs-16-m {
    font-size: 1.6rem;
  }
  .cc-fs-17-m {
    font-size: 1.7rem;
  }
  .cc-fs-18-m {
    font-size: 1.8rem;
  }
  .cc-fs-19-m {
    font-size: 1.9rem;
  }
  .cc-fs-20-m {
    font-size: 2rem;
  }
  .cc-fs-21-m {
    font-size: 2.1rem;
  }
  .cc-fs-22-m {
    font-size: 2.2rem;
  }
  .cc-fs-23-m {
    font-size: 2.3rem;
  }
  .cc-fs-24-m {
    font-size: 2.4rem;
  }
  .cc-fs-25-m {
    font-size: 2.5rem;
  }
  .cc-fs-26-m {
    font-size: 2.6rem;
  }
  .cc-fs-27-m {
    font-size: 2.7rem;
  }
  .cc-fs-28-m {
    font-size: 2.8rem;
  }
  .cc-fs-29-m {
    font-size: 2.9rem;
  }
  .cc-fs-30-m {
    font-size: 3rem;
  }
  .cc-fs-31-m {
    font-size: 3.1rem;
  }
  .cc-fs-32-m {
    font-size: 3.2rem;
  }
  .cc-fs-33-m {
    font-size: 3.3rem;
  }
  .cc-fs-34-m {
    font-size: 3.4rem;
  }
  .cc-fs-35-m {
    font-size: 3.5rem;
  }
  .cc-fs-36-m {
    font-size: 3.6rem;
  }
  .cc-fs-37-m {
    font-size: 3.7rem;
  }
  .cc-fs-38-m {
    font-size: 3.8rem;
  }
  .cc-fs-39-m {
    font-size: 3.9rem;
  }
  .cc-fs-40-m {
    font-size: 4rem;
  }
  .cc-fs-41-m {
    font-size: 4.1rem;
  }
  .cc-fs-42-m {
    font-size: 4.2rem;
  }
  .cc-fs-43-m {
    font-size: 4.3rem;
  }
  .cc-fs-44-m {
    font-size: 4.4rem;
  }
  .cc-fs-45-m {
    font-size: 4.5rem;
  }
  .cc-fs-46-m {
    font-size: 4.6rem;
  }
  .cc-fs-47-m {
    font-size: 4.7rem;
  }
  .cc-fs-48-m {
    font-size: 4.8rem;
  }
  .cc-fs-49-m {
    font-size: 4.9rem;
  }
  .cc-fs-50-m {
    font-size: 5rem;
  }
  .cc-fs-51-m {
    font-size: 5.1rem;
  }
  .cc-fs-52-m {
    font-size: 5.2rem;
  }
  .cc-fs-53-m {
    font-size: 5.3rem;
  }
  .cc-fs-54-m {
    font-size: 5.4rem;
  }
  .cc-fs-55-m {
    font-size: 5.5rem;
  }
  .cc-fs-56-m {
    font-size: 5.6rem;
  }
  .cc-fs-57-m {
    font-size: 5.7rem;
  }
  .cc-fs-58-m {
    font-size: 5.8rem;
  }
  .cc-fs-59-m {
    font-size: 5.9rem;
  }
  .cc-fs-60-m {
    font-size: 6rem;
  }
  .cc-fs-61-m {
    font-size: 6.1rem;
  }
  .cc-fs-62-m {
    font-size: 6.2rem;
  }
  .cc-fs-63-m {
    font-size: 6.3rem;
  }
  .cc-fs-64-m {
    font-size: 6.4rem;
  }
  .cc-fs-65-m {
    font-size: 6.5rem;
  }
  .cc-fs-66-m {
    font-size: 6.6rem;
  }
  .cc-fs-67-m {
    font-size: 6.7rem;
  }
  .cc-fs-68-m {
    font-size: 6.8rem;
  }
  .cc-fs-69-m {
    font-size: 6.9rem;
  }
  .cc-fs-70-m {
    font-size: 7rem;
  }
  .cc-fs-71-m {
    font-size: 7.1rem;
  }
  .cc-fs-72-m {
    font-size: 7.2rem;
  }
  .cc-fs-73-m {
    font-size: 7.3rem;
  }
  .cc-fs-74-m {
    font-size: 7.4rem;
  }
  .cc-fs-75-m {
    font-size: 7.5rem;
  }
  .cc-fs-76-m {
    font-size: 7.6rem;
  }
  .cc-fs-77-m {
    font-size: 7.7rem;
  }
  .cc-fs-78-m {
    font-size: 7.8rem;
  }
  .cc-fs-79-m {
    font-size: 7.9rem;
  }
  .cc-fs-80-m {
    font-size: 8rem;
  }
  .cc-fs-81-m {
    font-size: 8.1rem;
  }
  .cc-fs-82-m {
    font-size: 8.2rem;
  }
  .cc-fs-83-m {
    font-size: 8.3rem;
  }
  .cc-fs-84-m {
    font-size: 8.4rem;
  }
  .cc-fs-85-m {
    font-size: 8.5rem;
  }
  .cc-fs-86-m {
    font-size: 8.6rem;
  }
  .cc-fs-87-m {
    font-size: 8.7rem;
  }
  .cc-fs-88-m {
    font-size: 8.8rem;
  }
  .cc-fs-89-m {
    font-size: 8.9rem;
  }
  .cc-fs-90-m {
    font-size: 9rem;
  }
  .cc-fs-91-m {
    font-size: 9.1rem;
  }
  .cc-fs-92-m {
    font-size: 9.2rem;
  }
  .cc-fs-93-m {
    font-size: 9.3rem;
  }
  .cc-fs-94-m {
    font-size: 9.4rem;
  }
  .cc-fs-95-m {
    font-size: 9.5rem;
  }
  .cc-fs-96-m {
    font-size: 9.6rem;
  }
  .cc-fs-97-m {
    font-size: 9.7rem;
  }
  .cc-fs-98-m {
    font-size: 9.8rem;
  }
  .cc-fs-99-m {
    font-size: 9.9rem;
  }
  .cc-fs-100-m {
    font-size: 10rem;
  }
}
@media screen and (min-width: 990px) {
  .cc-fs-0-l {
    font-size: 0rem;
  }
  .cc-fs-1-l {
    font-size: 0.1rem;
  }
  .cc-fs-2-l {
    font-size: 0.2rem;
  }
  .cc-fs-3-l {
    font-size: 0.3rem;
  }
  .cc-fs-4-l {
    font-size: 0.4rem;
  }
  .cc-fs-5-l {
    font-size: 0.5rem;
  }
  .cc-fs-6-l {
    font-size: 0.6rem;
  }
  .cc-fs-7-l {
    font-size: 0.7rem;
  }
  .cc-fs-8-l {
    font-size: 0.8rem;
  }
  .cc-fs-9-l {
    font-size: 0.9rem;
  }
  .cc-fs-10-l {
    font-size: 1rem;
  }
  .cc-fs-11-l {
    font-size: 1.1rem;
  }
  .cc-fs-12-l {
    font-size: 1.2rem;
  }
  .cc-fs-13-l {
    font-size: 1.3rem;
  }
  .cc-fs-14-l {
    font-size: 1.4rem;
  }
  .cc-fs-15-l {
    font-size: 1.5rem;
  }
  .cc-fs-16-l {
    font-size: 1.6rem;
  }
  .cc-fs-17-l {
    font-size: 1.7rem;
  }
  .cc-fs-18-l {
    font-size: 1.8rem;
  }
  .cc-fs-19-l {
    font-size: 1.9rem;
  }
  .cc-fs-20-l {
    font-size: 2rem;
  }
  .cc-fs-21-l {
    font-size: 2.1rem;
  }
  .cc-fs-22-l {
    font-size: 2.2rem;
  }
  .cc-fs-23-l {
    font-size: 2.3rem;
  }
  .cc-fs-24-l {
    font-size: 2.4rem;
  }
  .cc-fs-25-l {
    font-size: 2.5rem;
  }
  .cc-fs-26-l {
    font-size: 2.6rem;
  }
  .cc-fs-27-l {
    font-size: 2.7rem;
  }
  .cc-fs-28-l {
    font-size: 2.8rem;
  }
  .cc-fs-29-l {
    font-size: 2.9rem;
  }
  .cc-fs-30-l {
    font-size: 3rem;
  }
  .cc-fs-31-l {
    font-size: 3.1rem;
  }
  .cc-fs-32-l {
    font-size: 3.2rem;
  }
  .cc-fs-33-l {
    font-size: 3.3rem;
  }
  .cc-fs-34-l {
    font-size: 3.4rem;
  }
  .cc-fs-35-l {
    font-size: 3.5rem;
  }
  .cc-fs-36-l {
    font-size: 3.6rem;
  }
  .cc-fs-37-l {
    font-size: 3.7rem;
  }
  .cc-fs-38-l {
    font-size: 3.8rem;
  }
  .cc-fs-39-l {
    font-size: 3.9rem;
  }
  .cc-fs-40-l {
    font-size: 4rem;
  }
  .cc-fs-41-l {
    font-size: 4.1rem;
  }
  .cc-fs-42-l {
    font-size: 4.2rem;
  }
  .cc-fs-43-l {
    font-size: 4.3rem;
  }
  .cc-fs-44-l {
    font-size: 4.4rem;
  }
  .cc-fs-45-l {
    font-size: 4.5rem;
  }
  .cc-fs-46-l {
    font-size: 4.6rem;
  }
  .cc-fs-47-l {
    font-size: 4.7rem;
  }
  .cc-fs-48-l {
    font-size: 4.8rem;
  }
  .cc-fs-49-l {
    font-size: 4.9rem;
  }
  .cc-fs-50-l {
    font-size: 5rem;
  }
  .cc-fs-51-l {
    font-size: 5.1rem;
  }
  .cc-fs-52-l {
    font-size: 5.2rem;
  }
  .cc-fs-53-l {
    font-size: 5.3rem;
  }
  .cc-fs-54-l {
    font-size: 5.4rem;
  }
  .cc-fs-55-l {
    font-size: 5.5rem;
  }
  .cc-fs-56-l {
    font-size: 5.6rem;
  }
  .cc-fs-57-l {
    font-size: 5.7rem;
  }
  .cc-fs-58-l {
    font-size: 5.8rem;
  }
  .cc-fs-59-l {
    font-size: 5.9rem;
  }
  .cc-fs-60-l {
    font-size: 6rem;
  }
  .cc-fs-61-l {
    font-size: 6.1rem;
  }
  .cc-fs-62-l {
    font-size: 6.2rem;
  }
  .cc-fs-63-l {
    font-size: 6.3rem;
  }
  .cc-fs-64-l {
    font-size: 6.4rem;
  }
  .cc-fs-65-l {
    font-size: 6.5rem;
  }
  .cc-fs-66-l {
    font-size: 6.6rem;
  }
  .cc-fs-67-l {
    font-size: 6.7rem;
  }
  .cc-fs-68-l {
    font-size: 6.8rem;
  }
  .cc-fs-69-l {
    font-size: 6.9rem;
  }
  .cc-fs-70-l {
    font-size: 7rem;
  }
  .cc-fs-71-l {
    font-size: 7.1rem;
  }
  .cc-fs-72-l {
    font-size: 7.2rem;
  }
  .cc-fs-73-l {
    font-size: 7.3rem;
  }
  .cc-fs-74-l {
    font-size: 7.4rem;
  }
  .cc-fs-75-l {
    font-size: 7.5rem;
  }
  .cc-fs-76-l {
    font-size: 7.6rem;
  }
  .cc-fs-77-l {
    font-size: 7.7rem;
  }
  .cc-fs-78-l {
    font-size: 7.8rem;
  }
  .cc-fs-79-l {
    font-size: 7.9rem;
  }
  .cc-fs-80-l {
    font-size: 8rem;
  }
  .cc-fs-81-l {
    font-size: 8.1rem;
  }
  .cc-fs-82-l {
    font-size: 8.2rem;
  }
  .cc-fs-83-l {
    font-size: 8.3rem;
  }
  .cc-fs-84-l {
    font-size: 8.4rem;
  }
  .cc-fs-85-l {
    font-size: 8.5rem;
  }
  .cc-fs-86-l {
    font-size: 8.6rem;
  }
  .cc-fs-87-l {
    font-size: 8.7rem;
  }
  .cc-fs-88-l {
    font-size: 8.8rem;
  }
  .cc-fs-89-l {
    font-size: 8.9rem;
  }
  .cc-fs-90-l {
    font-size: 9rem;
  }
  .cc-fs-91-l {
    font-size: 9.1rem;
  }
  .cc-fs-92-l {
    font-size: 9.2rem;
  }
  .cc-fs-93-l {
    font-size: 9.3rem;
  }
  .cc-fs-94-l {
    font-size: 9.4rem;
  }
  .cc-fs-95-l {
    font-size: 9.5rem;
  }
  .cc-fs-96-l {
    font-size: 9.6rem;
  }
  .cc-fs-97-l {
    font-size: 9.7rem;
  }
  .cc-fs-98-l {
    font-size: 9.8rem;
  }
  .cc-fs-99-l {
    font-size: 9.9rem;
  }
  .cc-fs-100-l {
    font-size: 10rem;
  }
}
@media screen and (min-width: 1200px) {
  .cc-fs-0-xl {
    font-size: 0rem;
  }
  .cc-fs-1-xl {
    font-size: 0.1rem;
  }
  .cc-fs-2-xl {
    font-size: 0.2rem;
  }
  .cc-fs-3-xl {
    font-size: 0.3rem;
  }
  .cc-fs-4-xl {
    font-size: 0.4rem;
  }
  .cc-fs-5-xl {
    font-size: 0.5rem;
  }
  .cc-fs-6-xl {
    font-size: 0.6rem;
  }
  .cc-fs-7-xl {
    font-size: 0.7rem;
  }
  .cc-fs-8-xl {
    font-size: 0.8rem;
  }
  .cc-fs-9-xl {
    font-size: 0.9rem;
  }
  .cc-fs-10-xl {
    font-size: 1rem;
  }
  .cc-fs-11-xl {
    font-size: 1.1rem;
  }
  .cc-fs-12-xl {
    font-size: 1.2rem;
  }
  .cc-fs-13-xl {
    font-size: 1.3rem;
  }
  .cc-fs-14-xl {
    font-size: 1.4rem;
  }
  .cc-fs-15-xl {
    font-size: 1.5rem;
  }
  .cc-fs-16-xl {
    font-size: 1.6rem;
  }
  .cc-fs-17-xl {
    font-size: 1.7rem;
  }
  .cc-fs-18-xl {
    font-size: 1.8rem;
  }
  .cc-fs-19-xl {
    font-size: 1.9rem;
  }
  .cc-fs-20-xl {
    font-size: 2rem;
  }
  .cc-fs-21-xl {
    font-size: 2.1rem;
  }
  .cc-fs-22-xl {
    font-size: 2.2rem;
  }
  .cc-fs-23-xl {
    font-size: 2.3rem;
  }
  .cc-fs-24-xl {
    font-size: 2.4rem;
  }
  .cc-fs-25-xl {
    font-size: 2.5rem;
  }
  .cc-fs-26-xl {
    font-size: 2.6rem;
  }
  .cc-fs-27-xl {
    font-size: 2.7rem;
  }
  .cc-fs-28-xl {
    font-size: 2.8rem;
  }
  .cc-fs-29-xl {
    font-size: 2.9rem;
  }
  .cc-fs-30-xl {
    font-size: 3rem;
  }
  .cc-fs-31-xl {
    font-size: 3.1rem;
  }
  .cc-fs-32-xl {
    font-size: 3.2rem;
  }
  .cc-fs-33-xl {
    font-size: 3.3rem;
  }
  .cc-fs-34-xl {
    font-size: 3.4rem;
  }
  .cc-fs-35-xl {
    font-size: 3.5rem;
  }
  .cc-fs-36-xl {
    font-size: 3.6rem;
  }
  .cc-fs-37-xl {
    font-size: 3.7rem;
  }
  .cc-fs-38-xl {
    font-size: 3.8rem;
  }
  .cc-fs-39-xl {
    font-size: 3.9rem;
  }
  .cc-fs-40-xl {
    font-size: 4rem;
  }
  .cc-fs-41-xl {
    font-size: 4.1rem;
  }
  .cc-fs-42-xl {
    font-size: 4.2rem;
  }
  .cc-fs-43-xl {
    font-size: 4.3rem;
  }
  .cc-fs-44-xl {
    font-size: 4.4rem;
  }
  .cc-fs-45-xl {
    font-size: 4.5rem;
  }
  .cc-fs-46-xl {
    font-size: 4.6rem;
  }
  .cc-fs-47-xl {
    font-size: 4.7rem;
  }
  .cc-fs-48-xl {
    font-size: 4.8rem;
  }
  .cc-fs-49-xl {
    font-size: 4.9rem;
  }
  .cc-fs-50-xl {
    font-size: 5rem;
  }
  .cc-fs-51-xl {
    font-size: 5.1rem;
  }
  .cc-fs-52-xl {
    font-size: 5.2rem;
  }
  .cc-fs-53-xl {
    font-size: 5.3rem;
  }
  .cc-fs-54-xl {
    font-size: 5.4rem;
  }
  .cc-fs-55-xl {
    font-size: 5.5rem;
  }
  .cc-fs-56-xl {
    font-size: 5.6rem;
  }
  .cc-fs-57-xl {
    font-size: 5.7rem;
  }
  .cc-fs-58-xl {
    font-size: 5.8rem;
  }
  .cc-fs-59-xl {
    font-size: 5.9rem;
  }
  .cc-fs-60-xl {
    font-size: 6rem;
  }
  .cc-fs-61-xl {
    font-size: 6.1rem;
  }
  .cc-fs-62-xl {
    font-size: 6.2rem;
  }
  .cc-fs-63-xl {
    font-size: 6.3rem;
  }
  .cc-fs-64-xl {
    font-size: 6.4rem;
  }
  .cc-fs-65-xl {
    font-size: 6.5rem;
  }
  .cc-fs-66-xl {
    font-size: 6.6rem;
  }
  .cc-fs-67-xl {
    font-size: 6.7rem;
  }
  .cc-fs-68-xl {
    font-size: 6.8rem;
  }
  .cc-fs-69-xl {
    font-size: 6.9rem;
  }
  .cc-fs-70-xl {
    font-size: 7rem;
  }
  .cc-fs-71-xl {
    font-size: 7.1rem;
  }
  .cc-fs-72-xl {
    font-size: 7.2rem;
  }
  .cc-fs-73-xl {
    font-size: 7.3rem;
  }
  .cc-fs-74-xl {
    font-size: 7.4rem;
  }
  .cc-fs-75-xl {
    font-size: 7.5rem;
  }
  .cc-fs-76-xl {
    font-size: 7.6rem;
  }
  .cc-fs-77-xl {
    font-size: 7.7rem;
  }
  .cc-fs-78-xl {
    font-size: 7.8rem;
  }
  .cc-fs-79-xl {
    font-size: 7.9rem;
  }
  .cc-fs-80-xl {
    font-size: 8rem;
  }
  .cc-fs-81-xl {
    font-size: 8.1rem;
  }
  .cc-fs-82-xl {
    font-size: 8.2rem;
  }
  .cc-fs-83-xl {
    font-size: 8.3rem;
  }
  .cc-fs-84-xl {
    font-size: 8.4rem;
  }
  .cc-fs-85-xl {
    font-size: 8.5rem;
  }
  .cc-fs-86-xl {
    font-size: 8.6rem;
  }
  .cc-fs-87-xl {
    font-size: 8.7rem;
  }
  .cc-fs-88-xl {
    font-size: 8.8rem;
  }
  .cc-fs-89-xl {
    font-size: 8.9rem;
  }
  .cc-fs-90-xl {
    font-size: 9rem;
  }
  .cc-fs-91-xl {
    font-size: 9.1rem;
  }
  .cc-fs-92-xl {
    font-size: 9.2rem;
  }
  .cc-fs-93-xl {
    font-size: 9.3rem;
  }
  .cc-fs-94-xl {
    font-size: 9.4rem;
  }
  .cc-fs-95-xl {
    font-size: 9.5rem;
  }
  .cc-fs-96-xl {
    font-size: 9.6rem;
  }
  .cc-fs-97-xl {
    font-size: 9.7rem;
  }
  .cc-fs-98-xl {
    font-size: 9.8rem;
  }
  .cc-fs-99-xl {
    font-size: 9.9rem;
  }
  .cc-fs-100-xl {
    font-size: 10rem;
  }
}
/* *****
    @name : ._generateFontWeight
    @description : Generate font-weight from 100 to 900
***** */
.cc-fw-100 {
  font-weight: 100;
}
.cc-fw-200 {
  font-weight: 200;
}
.cc-fw-300 {
  font-weight: 300;
}
.cc-fw-400 {
  font-weight: 400;
}
.cc-fw-500 {
  font-weight: 500;
}
.cc-fw-600 {
  font-weight: 600;
}
.cc-fw-700 {
  font-weight: 700;
}
.cc-fw-800 {
  font-weight: 800;
}
.cc-fw-900 {
  font-weight: 900;
}
@media screen and (min-width: 360px) {
  .cc-fw-100-s {
    font-weight: 100;
  }
  .cc-fw-200-s {
    font-weight: 200;
  }
  .cc-fw-300-s {
    font-weight: 300;
  }
  .cc-fw-400-s {
    font-weight: 400;
  }
  .cc-fw-500-s {
    font-weight: 500;
  }
  .cc-fw-600-s {
    font-weight: 600;
  }
  .cc-fw-700-s {
    font-weight: 700;
  }
  .cc-fw-800-s {
    font-weight: 800;
  }
  .cc-fw-900-s {
    font-weight: 900;
  }
}
@media screen and (min-width: 768px) {
  .cc-fw-100-m {
    font-weight: 100;
  }
  .cc-fw-200-m {
    font-weight: 200;
  }
  .cc-fw-300-m {
    font-weight: 300;
  }
  .cc-fw-400-m {
    font-weight: 400;
  }
  .cc-fw-500-m {
    font-weight: 500;
  }
  .cc-fw-600-m {
    font-weight: 600;
  }
  .cc-fw-700-m {
    font-weight: 700;
  }
  .cc-fw-800-m {
    font-weight: 800;
  }
  .cc-fw-900-m {
    font-weight: 900;
  }
}
@media screen and (min-width: 990px) {
  .cc-fw-100-l {
    font-weight: 100;
  }
  .cc-fw-200-l {
    font-weight: 200;
  }
  .cc-fw-300-l {
    font-weight: 300;
  }
  .cc-fw-400-l {
    font-weight: 400;
  }
  .cc-fw-500-l {
    font-weight: 500;
  }
  .cc-fw-600-l {
    font-weight: 600;
  }
  .cc-fw-700-l {
    font-weight: 700;
  }
  .cc-fw-800-l {
    font-weight: 800;
  }
  .cc-fw-900-l {
    font-weight: 900;
  }
}
@media screen and (min-width: 1200px) {
  .cc-fw-100-xl {
    font-weight: 100;
  }
  .cc-fw-200-xl {
    font-weight: 200;
  }
  .cc-fw-300-xl {
    font-weight: 300;
  }
  .cc-fw-400-xl {
    font-weight: 400;
  }
  .cc-fw-500-xl {
    font-weight: 500;
  }
  .cc-fw-600-xl {
    font-weight: 600;
  }
  .cc-fw-700-xl {
    font-weight: 700;
  }
  .cc-fw-800-xl {
    font-weight: 800;
  }
  .cc-fw-900-xl {
    font-weight: 900;
  }
}
/* *****
    @name : ._generateTextsAligments
    @description : Generate texts aligments
***** */
.cc-txt-center {
  text-align: center;
}
.cc-txt-left {
  text-align: left;
}
.cc-txt-right {
  text-align: right;
}
.cc-txt-justify {
  text-align: justify;
}
@media screen and (min-width: 360px) {
  .cc-txt-center-s {
    text-align: center;
  }
  .cc-txt-left-s {
    text-align: left;
  }
  .cc-txt-right-s {
    text-align: right;
  }
  .cc-txt-justify-s {
    text-align: justify;
  }
}
@media screen and (min-width: 768px) {
  .cc-txt-center-m {
    text-align: center;
  }
  .cc-txt-left-m {
    text-align: left;
  }
  .cc-txt-right-m {
    text-align: right;
  }
  .cc-txt-justify-m {
    text-align: justify;
  }
}
@media screen and (min-width: 990px) {
  .cc-txt-center-l {
    text-align: center;
  }
  .cc-txt-left-l {
    text-align: left;
  }
  .cc-txt-right-l {
    text-align: right;
  }
  .cc-txt-justify-l {
    text-align: justify;
  }
}
@media screen and (min-width: 1200px) {
  .cc-txt-center-xl {
    text-align: center;
  }
  .cc-txt-left-xl {
    text-align: left;
  }
  .cc-txt-right-xl {
    text-align: right;
  }
  .cc-txt-justify-xl {
    text-align: justify;
  }
}
/*
  * Extend the ChuckCSS settings.less file *
  *** OVERRIDE CHUCKCSS VARS ***
  * Specific vars
*/
/*
    -- @ Colors @ --
*/
/* Primary color */
/*
    -- @ Titles @ --
*/
@media screen and (min-width: 990px) {
  html.opened-menu,
  body.opened-menu {
    overflow-y: auto !important;
  }
}
body {
  background-color: white;
  color: #3a3a3a;
  font-family: 'Open Sans', sans-serif;
  font-display: auto;
}
body .cc-inside {
  width: 100%;
  margin: 0 auto;
  -webkit-transition: 0.2s width ease-out 0s;
  transition: 0.2s width ease-out 0s;
}
@media screen and (min-width: 990px) {
  body .cc-inside {
    width: 900px;
    margin: 0 auto;
    -webkit-transition: 0.2s width ease-out 0s;
    transition: 0.2s width ease-out 0s;
  }
}
@media screen and (min-width: 1200px) {
  body .cc-inside {
    width: 1100px;
    margin: 0 auto;
    -webkit-transition: 0.2s width ease-out 0s;
    transition: 0.2s width ease-out 0s;
  }
}
body ul {
  padding: 0;
  margin: 0;
  border: 0;
}
body ul li {
  list-style: none;
}
/* define text selection */
::selection {
  background: #ff810d;
  color: white;
}
/* Import Helpers files */
/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  border-radius: 0px;
}
.hamburger:hover {
  background-color: transparent;
  border: 0;
}
.hamburger-box {
  width: 24px;
  height: 20px;
  display: inline-block;
  position: relative;
}
.hamburger-box .hamburger-inner {
  display: block;
  top: 0px;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
}
.hamburger-box .hamburger-inner::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
  width: 75%;
  top: 7px;
}
.hamburger-box .hamburger-inner::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff810d;
  position: absolute;
  -webkit-transition: 0.15s all ease 0s;
  transition: 0.15s all ease 0s;
  width: 50%;
  top: 14px;
}
/*
 * Elastic
 */
.hamburger--elastic {
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic:before {
  -webkit-transition: opacity 0.15s 0.4s ease;
  transition: opacity 0.15s 0.4s ease;
}
.hamburger--elastic:after {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 6px, 0) rotate(135deg);
          transform: translate3d(0, 6px, 0) rotate(135deg);
}
.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner:after {
  width: 100%;
  -webkit-transform: translate3d(0, -14px, 0) rotate(-270deg);
          transform: translate3d(0, -14px, 0) rotate(-270deg);
}
/* ********* */
/** MODALS **/
/* ********* */
/* ********************* */
#modal-contact .modal-body {
  padding: 0;
}
#modal-contact .modal-body .wrap-modal-content {
  padding: 20px;
  text-align: center;
}
#modal-contact .modal-body .wrap-modal-content div {
  padding: 15px 20px 40px;
}
#modal-contact .modal-body .wrap-modal-content div .icon span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto 20px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 3rem;
  border-radius: 100%;
}
#modal-contact .modal-body .wrap-modal-content div .icon span i {
  padding: 0;
  margin: 0;
  border: 0;
  display: block;
}
#modal-contact .modal-body .wrap-modal-content div strong {
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#modal-contact .modal-body .wrap-modal-content div strong small {
  color: #575757;
  font-weight: 300;
  text-transform: none;
  display: block;
}
#modal-contact .modal-body .wrap-modal-content div a {
  color: #B85500;
  font-weight: 600;
  font-size: 1.6rem;
}
@media screen and (min-width: 990px) {
  #modal-contact .modal-body h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  #modal-contact .modal-body .wrap-modal-content {
    padding: 40px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #modal-contact .modal-body .wrap-modal-content div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
}
/* Import libraries plugins files */
body.compensate-for-scrollbar {
  overflow: hidden;
  -ms-overflow-style: none;
}
.fancybox-active {
  height: auto;
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}
.fancybox-container * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  -webkit-transition-duration: inherit;
          transition-duration: inherit;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
          transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}
.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
          transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}
.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fancybox-toolbar {
  right: 0;
  top: 0;
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 99994;
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px 44px 0 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}
.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}
.fancybox-slide--next {
  z-index: 99995;
}
.fancybox-slide--image {
  padding: 44px 0 0 0;
}
.fancybox-slide--image {
  overflow: visible;
}
.fancybox-slide--image::before {
  display: none;
}
.fancybox-slide--html {
  padding: 6px 6px 0 6px;
}
.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 44px 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}
.fancybox-slide--html .fancybox-content {
  margin: 0 0 6px 0;
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}
.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fancybox-container [data-selectable='true'] {
  cursor: text;
}
.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.fancybox-spaceball {
  z-index: 1;
}
.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: calc(100% - 44px);
  overflow: visible;
  padding: 0;
  width: 100%;
}
.fancybox-slide--video .fancybox-content {
  background: #000;
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}
.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: top;
  width: 100%;
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}
.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}
.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}
.fancybox-button:hover {
  color: #fff;
}
.fancybox-button:focus {
  outline: none;
}
.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}
.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}
.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}
.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}
.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}
.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}
/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}
/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background: transparent;
  height: 100px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}
.fancybox-navigation .fancybox-button div {
  background: rgba(30, 30, 30, 0.6);
  height: 100%;
  padding: 7px;
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px;
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
}
/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0;
}
.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1;
}
.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0;
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}
/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate 0.8s infinite linear;
  animation: fancybox-rotate 0.8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: 0.6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999;
}
@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* Transition effects */
.fancybox-animated {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}
/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}
/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}
.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}
.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}
/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0 0 0;
  }
  .fancybox-slide--image .fancybox-content {
    margin-bottom: 6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}
.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.fancybox-share p {
  margin: 0;
  padding: 0;
}
.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}
.fancybox-share__button:hover {
  text-decoration: none;
}
.fancybox-share__button--fb {
  background: #3b5998;
}
.fancybox-share__button--fb:hover {
  background: #344e86;
}
.fancybox-share__button--pt {
  background: #bd081d;
}
.fancybox-share__button--pt:hover {
  background: #aa0719;
}
.fancybox-share__button--tw {
  background: #1da1f2;
}
.fancybox-share__button--tw:hover {
  background: #0d95e8;
}
.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}
.fancybox-share__button svg path {
  fill: #fff;
}
.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}
/* Thumbs */
.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995;
}
.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}
.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}
.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}
.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}
.fancybox-thumbs__list a::before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}
.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}
.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}
/* Styling for Small-Screen Devices */
@media all and (max-width: 768px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
          animation-name: flipInX;
}
@-webkit-keyframes slideInDownBig {
  from {
    -webkit-transform: translate3d(0, -45px, 0);
            transform: translate3d(0, -45px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDownBig {
  from {
    -webkit-transform: translate3d(0, -45px, 0);
            transform: translate3d(0, -45px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.slideInDownBig {
  -webkit-animation-name: slideInDownBig;
          animation-name: slideInDownBig;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
            transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
            transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
}
@-webkit-keyframes wrench {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes wrench {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.wrench {
  -webkit-transform-origin: 56px 15px;
          transform-origin: 56px 15px;
  -webkit-animation-name: wrench;
          animation-name: wrench;
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  6.5% {
    -webkit-transform: translateY(-6px) rotateY(-9deg);
            transform: translateY(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateY(5px) rotateY(7deg);
            transform: translateY(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateY(-3px) rotateY(-5deg);
            transform: translateY(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateY(2px) rotateY(3deg);
            transform: translateY(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  6.5% {
    -webkit-transform: translateY(-6px) rotateY(-9deg);
            transform: translateY(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateY(5px) rotateY(7deg);
            transform: translateY(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateY(-3px) rotateY(-5deg);
            transform: translateY(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateY(2px) rotateY(3deg);
            transform: translateY(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
          animation-name: headShake;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
            animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
@-webkit-keyframes hinge {
  0%,
  6%,
  12% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  3%,
  9% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  40%,
  44%,
  48%,
  52% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  42%,
  46%,
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0%,
  6%,
  12% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  3%,
  9% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  40%,
  44%,
  48%,
  52% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  42%,
  46%,
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
            transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
          animation-name: hinge;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform-origin: 30px 30px;
          transform-origin: 30px 30px;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, -140px, 0);
            transform: translate3d(0, -140px, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, -140px, 0);
            transform: translate3d(0, -140px, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
/*
  @@ LOZAD animations @@
  -- Animate img and backstretch when elements are loaded with lozad
*/
img[data-loaded="true"],
[data-loaded="true"] img {
  -webkit-animation-name: showLazyImg;
          animation-name: showLazyImg;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}
.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  -webkit-filter: inherit;
          filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}
.leaflet-pane {
  z-index: 400;
}
.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}
.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}
.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}
/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}
/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  transition: none;
}
.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}
/* cursors */
.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}
.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078A8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}
/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}
/* general toolbar styles */
.leaflet-bar {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}
.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}
.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}
/* layers control */
.leaflet-control-layers {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  background-image: url(/images/leaflet/layers.png);
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/images/leaflet/layers-2x.png);
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}
/* Default icon URLs/ */
.leaflet-default-icon-path {
  background-image: url(/images/leaflet/marker-icon.png);
}
/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 13px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}
.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  -webkit-box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
          box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}
.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}
/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}
/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}
/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}
/* Import Website styles */
/*
    - Basics styles : strong, Hn
    - .body on CMs pages
    - Videos Top of pages
    - Intro on pages (H1 + intro texts)
    - 3 pushes after intro : Homepage, Metiers;
    - Realisations pushes : Homepage, Clients, sites Symfony
*/
/*
	- Basics Styles
*/
strong {
  font-weight: 600;
}
h1,
.h1-like {
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
  color: #444444;
}
.h2-main-title {
  padding: 50px 20px;
  color: #3a3a3a;
  letter-spacing: 2px;
  font-weight: 300;
  text-align: center;
  font-size: 2rem;
}
.h2-main-title strong {
  color: #ff810d;
}
@media screen and (min-width: 768px) {
  .h2-main-title {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 990px) {
  .h2-main-title {
    padding-left: 0;
    padding-right: 0;
  }
}
strong.cc-orange,
em.cc-orange {
  color: #ff810d;
}
/*
	- .body on CMs pages
*/
.body {
  padding: 0 20px;
}
.body h2 {
  margin-top: 50px;
}
.body h4,
.body h3 {
  margin-top: 20px;
  color: #ff810d;
}
.body p {
  padding: 10px 0;
}
.body ul {
  margin-top: 20px;
}
.body a:not(.btn) {
  color: #ff810d;
  display: inline-block;
  border-bottom: 1px dotted #ff810d;
  padding: 0 3px;
  margin: 0 2px;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.body a:not(.btn):hover {
  color: #d96800;
}
.body .alert a.btn,
.body .message a.btn {
  text-decoration: none;
}
.custom-columns > ul {
  padding-top: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .custom-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .custom-columns > ul {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
  }
}
/*
	- Videos top of pages
*/
.css-video-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
}
.homepage .css-video-wrapper {
  height: 50vh;
}
.webp .metiers .css-video-wrapper {
  background-image: url('/images/video/mobile/meeting-room.webp');
}
.no-webp .metiers .css-video-wrapper {
  background-image: url('/images/video/mobile/meeting-room.jpg');
}
.webp .sites-symfony .css-video-wrapper,
.webp .sites-drupal .css-video-wrapper,
.webp .sites-prestashop .css-video-wrapper {
  background-image: url('/images/video/mobile/love-coding.webp');
}
.no-webp .sites-symfony .css-video-wrapper,
.no-webp .sites-drupal .css-video-wrapper,
.no-webp .sites-prestashop .css-video-wrapper {
  background-image: url('/images/video/mobile/love-coding.jpg');
}
.webp .clients .css-video-wrapper {
  background-image: url('/images/video/mobile/customers.webp');
}
.no-webp .clients .css-video-wrapper {
  background-image: url('/images/video/mobile/customers.jpg');
}
.webp .ref-symfony.clients .css-video-wrapper,
.webp .ref-drupal.clients .css-video-wrapper,
.webp .ref-prestashop.clients .css-video-wrapper {
  background-image: url('/images/video/mobile/travaho.webp');
}
.no-webp .ref-symfony.clients .css-video-wrapper,
.no-webp .ref-drupal.clients .css-video-wrapper,
.no-webp .ref-prestashop.clients .css-video-wrapper {
  background-image: url('/images/video/mobile/travaho.jpg');
}
.webp .contact .css-video-wrapper,
.webp .cms .css-video-wrapper,
.webp .agency .css-video-wrapper {
  background-image: url('/images/video/mobile/agency.webp');
}
.no-webp .contact .css-video-wrapper,
.no-webp .cms .css-video-wrapper,
.no-webp .agency .css-video-wrapper {
  background-image: url('/images/video/mobile/agency.jpg');
}
.css-video-wrapper .video-overlay {
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 768px) {
  .css-video-wrapper {
    height: 45vh;
  }
  .homepage .css-video-wrapper {
    height: 100vh;
  }
  .webp .metiers .css-video-wrapper {
    background-image: url('/images/video/meeting-room.webp');
  }
  .no-webp .metiers .css-video-wrapper {
    background-image: url('/images/video/meeting-room.jpg');
  }
  .webp .sites-symfony .css-video-wrapper,
  .webp .sites-drupal .css-video-wrapper,
  .webp .sites-prestashop .css-video-wrapper {
    background-image: url('/images/video/love-coding.webp');
  }
  .no-webp .sites-symfony .css-video-wrapper,
  .no-webp .sites-drupal .css-video-wrapper,
  .no-webp .sites-prestashop .css-video-wrapper {
    background-image: url('/images/video/love-coding.jpg');
  }
  .webp .clients .css-video-wrapper {
    background-image: url('/images/video/customers.webp');
  }
  .no-webp .clients .css-video-wrapper {
    background-image: url('/images/video/customers.jpg');
  }
  .webp .ref-symfony.clients .css-video-wrapper,
  .webp .ref-drupal.clients .css-video-wrapper,
  .webp .ref-prestashop.clients .css-video-wrapper {
    background-image: url('/images/video/travaho.webp');
  }
  .no-webp .ref-symfony.clients .css-video-wrapper,
  .no-webp .ref-drupal.clients .css-video-wrapper,
  .no-webp .ref-prestashop.clients .css-video-wrapper {
    background-image: url('/images/video/travaho.jpg');
  }
  .webp .cms .css-video-wrapper,
  .webp .agency .css-video-wrapper {
    background-image: url('/images/video/agency.webp');
  }
  .no-webp .cms .css-video-wrapper,
  .no-webp .agency .css-video-wrapper {
    background-image: url('/images/video/agency.jpg');
  }
}
/*
	- Titles H1 + Intro text on pages
*/
.page-intro {
  padding: 50px 20px;
}
.page-intro h1 {
  font-weight: 600;
  font-size: 2.5rem;
}
.page-intro .intro {
  font-weight: 300;
  font-size: 1.9rem;
  text-align: justify;
}
@media screen and (min-width: 768px) {
  .page-intro {
    padding: 120px 20px 60px;
  }
  .page-intro h1 {
    font-size: 3.9rem;
  }
  .page-intro .intro {
    text-align: center;
    line-height: 1.8;
  }
}
/*
	- 3 pushes after intro
	* Used in :
		- Homepage
		- Metiers & Valeurs
*/
.pushes {
  padding: 50px 20px;
}
.pushes div {
  padding: 30px 0;
  text-align: center;
}
.pushes div a {
  display: block;
}
.pushes div h2 {
  margin: 20px 0;
}
.pushes div .push-description {
  font-style: italic;
  color: #999999;
  font-size: 1.4rem;
}
.pushes div .push-description strong {
  color: #ff810d;
}
.pushes div .artisan {
  position: relative;
  width: 70px;
  height: 70px;
  margin: 0 auto;
}
.pushes div i {
  color: #adadad;
  font-size: 7rem;
}
.pushes div i.fa-heart {
  color: #c41515;
}
.pushes div i.fa-wrench {
  color: #9caed5;
  display: inline-block;
  margin: 0 auto;
  width: 70px;
  height: 70px;
}
.pushes div i.fa-times-hexagon {
  color: #33566f;
  display: block;
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 1.4rem;
  /* Not perspective */
  -webkit-transform: rotate(16deg);
          transform: rotate(16deg);
  /* Perspective */
}
.pushes div i.fa-handshake {
  color: #ddb899;
}
.pushes div .link-preston {
  display: block;
  width: 150px;
  height: 150px;
  background-color: #93ddea;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.pushes div .link-preston img,
.pushes div .link-preston picture {
  position: relative;
  display: block;
}
.pushes div .link-preston img.hide,
.pushes div .link-preston picture.hide {
  display: none;
}
@media screen and (min-width: 768px) {
  .pushes {
    padding: 60px 20px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .pushes div {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0 30px;
  }
}
/*
	- Realisations pushes
	* Used in :
		- Homepage
		- Clients
*/
.realisations article {
  min-height: 250px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin-bottom: 5px;
  overflow: hidden;
}
.realisations article a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  position: relative;
}
.agency .realisations article a:after {
  display: none;
}
.realisations article a:after {
  display: block;
  content: ' ';
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: -1;
}
.realisations article a h3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
}
.realisations article a h3:after {
  display: block;
  content: ' ';
  background-color: currentColor;
  height: 1px;
  width: 30%;
  margin: 15px auto 20px;
}
.realisations article a h4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  color: #737373;
  font-weight: 300;
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .realisations article {
    min-height: 350px;
  }
}
@media screen and (min-width: 990px) {
  .realisations {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .realisations .realisation-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    /*
        C'est joli et ça me sert pour l'inté, donc on ne supprime pas merki :)

        +-----------+-----+-----+
        |           | 2   | 3   |
        +     1     +-----+-----+
        |           |     4     |
        +-----------+-----+-----+
        |     5     | 6   | 7   |
        +-----+-----+-----+-----+
        | 8   | 9   |           |
        +-----+-----+    11     +
        |    10     |           |
        +-----+-----+-----+-----+
        |    12     | 13  | 14  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 16  | 17  |
        +    15     +-----+-----+
        |           |     18    |
        +-----------+-----+-----+
        |    19     | 20  | 21  |
        +-----+-----+-----+-----+
        | 22  | 23  |           |
        +-----+-----+    25     +
        |    24     |           |
        +-----+-----+-----+-----+
        |    26     | 27  | 28  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 30  | 31  |
        +    29     +-----+-----+
        |           |     32    |
        +-----------+-----+-----+
        |    33     | 34  | 35  |
        +-----+-----+-----+-----+
        | 36  | 37  |           |
        +-----+-----+    39     +
        |    38     |           |
        +-----+-----+-----+-----+
        |    40     | 41  | 42  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 44  | 45  |
        +    43     +-----+-----+
        |           |     46    |
        +-----------+-----+-----+
        |    47     | 48  | 49  |
        +-----+-----+-----+-----+
        | 50  | 51  |           |
        +-----+-----+    53     +
        |    52     |           |
        +-----+-----+-----+-----+
        |    54     | 55  | 56  |
        +-----------+-----+-----+
    */
    /* Big Images */
    /* Small : 50% width */
  }
  .realisations .realisation-item article {
    margin: 0.5%;
    min-height: 300px;
  }
  .realisations .realisation-item article:hover a {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .realisations .realisation-item article:hover .backstretch img {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
  }
  .realisations .realisation-item article a {
    opacity: 0;
    -webkit-transform: translate3d(0px, 250px, 0px);
            transform: translate3d(0px, 250px, 0px);
    -webkit-transition: 0.35s all ease-out 0s;
    transition: 0.35s all ease-out 0s;
    -webkit-transition-timing-function: cubic-bezier(0.45, 0.38, 0.34, 0.83);
            transition-timing-function: cubic-bezier(0.45, 0.38, 0.34, 0.83);
  }
  .agency .realisations .realisation-item article a {
    -webkit-transition: none;
    transition: none;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .realisations .realisation-item article .backstretch img {
    -webkit-transition: 0.2s -webkit-filter ease-out 0s;
    transition: 0.2s -webkit-filter ease-out 0s;
    transition: 0.2s filter ease-out 0s;
    transition: 0.2s filter ease-out 0s, 0.2s -webkit-filter ease-out 0s;
    -webkit-filter: grayscale(0.8);
            filter: grayscale(0.8);
  }
  .realisations .realisation-item.item-53,
  .realisations .realisation-item.item-43,
  .realisations .realisation-item.item-39,
  .realisations .realisation-item.item-29,
  .realisations .realisation-item.item-25,
  .realisations .realisation-item.item-15,
  .realisations .realisation-item.item-11,
  .realisations .realisation-item.item-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .realisations .realisation-item.item-53 article,
  .realisations .realisation-item.item-43 article,
  .realisations .realisation-item.item-39 article,
  .realisations .realisation-item.item-29 article,
  .realisations .realisation-item.item-25 article,
  .realisations .realisation-item.item-15 article,
  .realisations .realisation-item.item-11 article,
  .realisations .realisation-item.item-1 article {
    width: 99%;
  }
  .realisations .realisation-item.item-55,
  .realisations .realisation-item.item-50,
  .realisations .realisation-item.item-48,
  .realisations .realisation-item.item-44,
  .realisations .realisation-item.item-41,
  .realisations .realisation-item.item-36,
  .realisations .realisation-item.item-34,
  .realisations .realisation-item.item-30,
  .realisations .realisation-item.item-27,
  .realisations .realisation-item.item-22,
  .realisations .realisation-item.item-20,
  .realisations .realisation-item.item-16,
  .realisations .realisation-item.item-13,
  .realisations .realisation-item.item-8,
  .realisations .realisation-item.item-6,
  .realisations .realisation-item.item-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .realisations .realisation-item.item-55 article,
  .realisations .realisation-item.item-50 article,
  .realisations .realisation-item.item-48 article,
  .realisations .realisation-item.item-44 article,
  .realisations .realisation-item.item-41 article,
  .realisations .realisation-item.item-36 article,
  .realisations .realisation-item.item-34 article,
  .realisations .realisation-item.item-30 article,
  .realisations .realisation-item.item-27 article,
  .realisations .realisation-item.item-22 article,
  .realisations .realisation-item.item-20 article,
  .realisations .realisation-item.item-16 article,
  .realisations .realisation-item.item-13 article,
  .realisations .realisation-item.item-8 article,
  .realisations .realisation-item.item-6 article,
  .realisations .realisation-item.item-2 article {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 49%;
        -ms-flex: 0 0 49%;
            flex: 0 0 49%;
  }
  .realisations .realisation-item.item-55 article:nth-child(3),
  .realisations .realisation-item.item-50 article:nth-child(3),
  .realisations .realisation-item.item-48 article:nth-child(3),
  .realisations .realisation-item.item-44 article:nth-child(3),
  .realisations .realisation-item.item-41 article:nth-child(3),
  .realisations .realisation-item.item-36 article:nth-child(3),
  .realisations .realisation-item.item-34 article:nth-child(3),
  .realisations .realisation-item.item-30 article:nth-child(3),
  .realisations .realisation-item.item-27 article:nth-child(3),
  .realisations .realisation-item.item-22 article:nth-child(3),
  .realisations .realisation-item.item-20 article:nth-child(3),
  .realisations .realisation-item.item-16 article:nth-child(3),
  .realisations .realisation-item.item-13 article:nth-child(3),
  .realisations .realisation-item.item-8 article:nth-child(3),
  .realisations .realisation-item.item-6 article:nth-child(3),
  .realisations .realisation-item.item-2 article:nth-child(3) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 99%;
        -ms-flex: 0 0 99%;
            flex: 0 0 99%;
  }
}
#main-header {
  padding: 15px;
  position: relative;
  -webkit-transition: 0.2s background-color ease-out 0s;
  transition: 0.2s background-color ease-out 0s;
}
#main-header.sticky {
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.05);
          box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.05);
}
#main-header #logo {
  display: block;
  padding-left: 5px;
  max-width: 65%;
}
#main-header #logo img {
  vertical-align: bottom;
}
#main-header .css-open-menu {
  position: absolute;
  z-index: 8;
  top: 13px;
  right: 0;
}
#main-header nav {
  position: fixed;
  height: 100vh;
  z-index: 98;
  background-color: white;
  top: 0;
  left: -120%;
  width: 100%;
  bottom: 0;
  padding-top: 45px;
  overflow-y: auto;
  -webkit-transition: 0.35s left cubic-bezier(1, 0, 0, 1) 0s;
  transition: 0.35s left cubic-bezier(1, 0, 0, 1) 0s;
}
#main-header nav.shown {
  left: 0%;
}
#main-header nav > ul > li {
  text-align: center;
  border-bottom: 1px solid #ececec;
  position: relative;
}
#main-header nav > ul > li:nth-child(1) {
  border-top: 1px solid #ececec;
}
#main-header nav > ul > li a {
  text-transform: uppercase;
  display: block;
  padding: 10px;
  font-size: 1.8rem;
  font-weight: 300;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
#main-header nav > ul > li a:hover,
#main-header nav > ul > li a.active {
  color: #ff810d;
}
#main-header nav > ul > li ul {
  margin-bottom: 10px;
}
#main-header nav > ul > li ul li a {
  text-transform: none;
  font-size: 1.4rem;
  padding: 5px;
}
#main-header .header-contact {
  padding: 15px 0 0;
}
#main-header .header-contact div {
  padding: 5px;
}
#main-header .header-contact div a {
  display: block;
  width: 100%;
  position: relative;
  background-color: #282828;
  color: white;
  border: 0;
  font-size: 1.4rem;
}
#main-header .header-contact div a p {
  position: relative;
  z-index: 2;
}
#main-header .header-contact div a:hover:after {
  width: 100%;
}
#main-header .header-contact div a:after {
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  border-radius: 3px;
  background-color: #0e0e0e;
  z-index: 1;
  -webkit-transition: 0.35s width cubic-bezier(1, 0, 0, 1) 0s;
  transition: 0.35s width cubic-bezier(1, 0, 0, 1) 0s;
}
@media screen and (min-width: 360px) {
  #main-header .header-contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #main-header .header-contact div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}
@media screen and (min-width: 768px) {
  #main-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    z-index: 98;
    left: 0;
    right: 0;
  }
  body:not(.homepage) #main-header {
    background-color: rgba(255, 255, 255, 0.95);
  }
  #main-header.sticky {
    padding: 0 15px;
  }
  #main-header.sticky .header-contact {
    display: block;
  }
  #main-header #logo {
    padding: 15px 30px;
    max-width: 250px;
  }
  #main-header .css-open-menu {
    display: none;
  }
  #main-header nav {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    position: static;
    height: inherit;
    background-color: transparent;
    padding-top: 0px;
    padding-right: 15px;
    overflow-y: inherit;
  }
  #main-header nav > ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #main-header nav > ul > li {
    border: 0;
  }
  #main-header nav > ul > li:nth-child(1) {
    border-top-width: 0px;
  }
  #main-header nav > ul > li:hover > a {
    color: #ff810d;
  }
  #main-header nav > ul > li:hover ul {
    display: block;
  }
  #main-header nav > ul > li a {
    font-size: 1.5rem;
  }
  #main-header nav > ul > li a.active,
  #main-header nav > ul > li a:hover {
    color: #ff810d;
  }
  #main-header nav > ul > li ul {
    display: none;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 100%;
    margin-bottom: 0;
    background-color: white;
    padding: 10px 0;
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
  }
  #main-header nav > ul > li ul li a {
    padding: 5px 15px;
  }
  #main-header .header-contact {
    padding: 0;
    display: block;
    padding: 15px;
    display: none;
  }
  #main-header .header-contact div {
    padding: 0;
  }
  #main-header .header-contact div:nth-child(1) {
    padding-bottom: 5px;
  }
}
@media screen and (min-width: 990px) {
  #main-header #logo {
    max-width: inherit;
  }
}
@media screen and (min-width: 1200px) {
  #main-header nav {
    padding-right: 30px;
  }
  #main-header nav > ul > li a {
    padding: 10px 20px;
  }
  #main-header .header-contact {
    padding: 15px 40px;
  }
}
#main-footer .footer-title {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 1.66666667em;
  line-height: 1.5;
  font-weight: 400;
  display: block;
  color: #070707;
}
#main-footer .footer-title:nth-child(1) {
  margin-top: 0;
}
#main-footer .wrap-maps #leaflet-maps {
  min-height: 450px;
  z-index: 1;
}
#main-footer .wrap-maps .alpixel-coordinates {
  padding: 50px 20px;
  text-align: center;
  color: inherit;
}
#main-footer .wrap-maps .alpixel-coordinates a,
#main-footer .wrap-maps .alpixel-coordinates strong {
  color: #B85500;
}
#main-footer .wrap-maps .alpixel-coordinates ul li {
  display: inline-block;
  padding: 0 20px;
}
#main-footer .wrap-maps .alpixel-coordinates ul li a {
  display: block;
  font-size: 3rem;
}
#main-footer .alpixel-credits {
  padding: 30px 20px;
  background-color: #282828;
  text-align: center;
  font-size: 1.2rem;
}
#main-footer .alpixel-credits ul {
  color: #8F8F8F;
}
#main-footer .alpixel-credits ul li {
  padding: 10px 0 0;
}
#main-footer .alpixel-credits a {
  display: inline-block;
  padding: 10px 0;
  color: #8F8F8F;
  border-bottom: 1px solid currentColor;
}
#main-footer .alpixel-credits a.active {
  color: white;
  border-bottom-color: white;
}
@media screen and (min-width: 990px) {
  #main-footer .wrap-maps {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  #main-footer .wrap-maps #leaflet-maps {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66%;
        -ms-flex: 0 0 66%;
            flex: 0 0 66%;
  }
  #main-footer .wrap-maps .alpixel-coordinates {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
  }
  #main-footer .alpixel-credits ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #main-footer .alpixel-credits ul li {
    padding: 0 10px;
  }
  #main-footer .alpixel-credits ul a {
    padding: 5px 0;
  }
}
/*
    Used in :
    	- Métiers @ valeurs
    	- sites Symfony exemples
    	- Site Drupal exemples
*/
.wrap-metiers {
  background-color: white;
}
.wrap-metiers .metier-item {
  padding: 0;
  text-align: center;
  padding: 40px 20px;
}
.wrap-metiers .metier-item i {
  color: #adadad;
  font-size: 7rem;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.wrap-metiers .metier-item h3 {
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
}
.wrap-metiers .metier-item p {
  max-width: 350px;
  margin: 0 auto;
  color: #888888;
  font-weight: 300;
  font-size: 1.5rem;
  padding-top: 20px;
}
.wrap-metiers .metier-item p strong {
  color: #ff810d;
}
@media screen and (min-width: 990px) {
  .wrap-metiers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .wrap-metiers .metier-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.32%;
        -ms-flex: 0 0 33.32%;
            flex: 0 0 33.32%;
    padding: 70px 30px;
  }
  .wrap-metiers .metier-item p {
    text-align: justify;
  }
}
/*

*/
.clients .page-intro i {
  color: #adadad;
  font-size: 7.7rem;
}
.clients .filter-realisations {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #f9f9f9;
  padding: 0.2%;
}
.clients .filter-realisations header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}
.clients .filter-realisations a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  margin: 0.2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 49.59%;
      -ms-flex: 0 0 49.59%;
          flex: 0 0 49.59%;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.clients .filter-realisations a.active,
.clients .filter-realisations a:hover {
  background-color: #f9f9f9;
}
.clients .filter-realisations a.active i,
.clients .filter-realisations a:hover i {
  color: #ff810d;
}
.clients .filter-realisations a.active img,
.clients .filter-realisations a:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.clients .filter-realisations i {
  color: #adadad;
  font-size: 4.2rem;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.clients .filter-realisations img {
  max-height: 70px;
  width: auto;
  -webkit-filter: grayscale(0.4);
          filter: grayscale(0.4);
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.clients .filter-realisations p {
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  color: #070707;
}
.clients .filter-realisations p + hr + p {
  margin-top: 0;
}
.clients .filter-realisations hr {
  padding: 0;
  margin: 0;
  border: 0;
  width: 20px;
  height: 1px;
  background-color: #d3d3d3;
  margin: 5px auto;
}
@media screen and (min-width: 500px) {
  .clients .filter-realisations a {
    padding: 25px 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .clients .filter-realisations a.cc-third {
    padding: 30px 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 32.9%;
        -ms-flex: 0 0 32.9%;
            flex: 0 0 32.9%;
  }
}
.clients.ref-bornes video {
  max-width: 100%;
}
.realisation-detail .canvas-basic {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
}
.realisation-detail .realisation-top {
  overflow: hidden;
}
.realisation-detail .realisation-top .realisation-top-left {
  min-height: 30vh;
}
.realisation-detail .realisation-top .realisation-top-right {
  padding: 40px 20px;
  position: relative;
}
.realisation-detail .realisation-top .realisation-top-right h1 {
  text-align: center;
  position: relative;
  z-index: 9;
  color: white;
}
.realisation-detail .realisation-top .realisation-top-right h1 small {
  color: currentcolor;
  padding-top: 10px;
  display: block;
}
.realisation-detail .wrap-reference-skills {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.realisation-detail .wrap-reference-skills div,
.realisation-detail .wrap-reference-skills a {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 50%;
      -ms-flex: 1 0 50%;
          flex: 1 0 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  -webkit-transition: 0.2s all ease-out 0s;
  transition: 0.2s all ease-out 0s;
}
.realisation-detail .wrap-reference-skills div:hover,
.realisation-detail .wrap-reference-skills a:hover {
  background-color: white;
}
.realisation-detail .wrap-reference-skills .reference-year {
  color: #adadad;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2rem;
}
.realisation-detail .wrap-reference-skills i {
  color: #adadad;
  font-size: 3rem;
}
.realisation-detail .wrap-reference-skills img {
  max-height: 30px;
  width: auto;
}
.realisation-detail .wrap-reference-skills p:not(.reference-year) {
  color: #070707;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.realisation-detail .reference-description {
  padding: 40px 20px;
}
.realisation-detail .reference-description a {
  color: #ff810d;
}
.realisation-detail .reference-description .video-container {
  margin: 60px auto 0;
  width: 700px;
  max-width: 100%;
}
.realisation-detail .reference-description .video-container .contain-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.realisation-detail .reference-description .video-container .contain-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.realisation-detail .wrap-testinomial {
  padding: 50px 20px;
  background-color: #282828;
  overflow: hidden;
}
.realisation-detail .wrap-testinomial > .cc-inside {
  position: relative;
}
.realisation-detail .wrap-testinomial h2 {
  font-size: 2.5rem;
  color: white;
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  padding: 0 20px;
}
.realisation-detail .wrap-testinomial .icon-illu {
  color: #353535;
  font-size: 30rem;
  position: absolute;
  z-index: 0;
  top: -100px;
  left: -100px;
}
.realisation-detail .wrap-testinomial .icon-illu i {
  display: block;
}
.realisation-detail .wrap-testinomial blockquote {
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  z-index: 2;
  padding: 0 20px;
  text-align: justify;
  font-weight: 300;
  color: #bfbfbf;
}
.realisation-detail .wrap-testinomial blockquote footer {
  padding-top: 0;
  margin-top: 25px;
  font-size: 1.4rem;
}
.realisation-detail .references-links {
  padding: 40px 20px;
}
.realisation-detail .references-links a {
  display: block;
  margin: 20px auto 0px;
}
.realisation-detail .references-links a:nth-child(1) {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .realisation-detail #main-content {
    padding-top: 96px;
  }
  .realisation-detail .wrap-reference-skills div,
  .realisation-detail .wrap-reference-skills a {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    padding: 25px 15px;
  }
  .realisation-detail .wrap-reference-skills .reference-year {
    height: 42px;
    font-size: 3.5rem;
  }
  .realisation-detail .wrap-reference-skills i {
    font-size: 4.2rem;
  }
  .realisation-detail .wrap-reference-skills img {
    max-height: 42px;
  }
  .realisation-detail .wrap-reference-skills p:not(.reference-year) {
    font-size: 1.4rem;
  }
  .realisation-detail .references-links {
    padding: 40px 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .realisation-detail .references-links a {
    margin: 0;
  }
  .realisation-detail .references-links a.cc-fat {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
}
@media screen and (min-width: 990px) {
  .realisation-detail #main-content {
    padding-top: 108px;
  }
  .realisation-detail .realisation-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 500px;
  }
  .realisation-detail .realisation-top .realisation-top-left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 60%;
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
  }
  .realisation-detail .realisation-top .realisation-top-right {
    background-color: #f7f7f7;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .realisation-detail .realisation-top .realisation-top-right:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* X only */
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    /* Y only */
    /* X and Y : no @axis */
  }
  .realisation-detail .realisation-top .realisation-top-right:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -125px;
    width: 250px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* X only */
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    /* Y only */
    /* X and Y : no @axis */
  }
  .realisation-detail .wrap-testinomial {
    padding: 60px 20px;
  }
  .realisation-detail .wrap-testinomial blockquote {
    padding: 0 70px;
  }
  .realisation-detail .references-links {
    padding: 40px 0px;
  }
}
@media screen and (min-width: 1200px) {
  .realisation-detail .wrap-testinomial .icon-illu {
    font-size: 30rem;
    top: -150px;
    left: -50px;
  }
}
/*
    
*/
.contact .wrap-contact-content {
  padding: 20px;
  text-align: center;
}
.contact .wrap-contact-content div {
  padding: 15px 20px 40px;
}
.contact .wrap-contact-content div .icon span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto 20px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 3rem;
  border-radius: 100%;
}
.contact .wrap-contact-content div .icon span i {
  padding: 0;
  margin: 0;
  border: 0;
  display: block;
}
.contact .wrap-contact-content div strong {
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.contact .wrap-contact-content div strong small {
  color: #777777;
  font-weight: 300;
  text-transform: none;
  display: block;
}
.contact .wrap-contact-content div a {
  color: #ff810d;
  font-weight: 600;
  font-size: 1.6rem;
}
@media screen and (min-width: 990px) {
  .contact .wrap-contact-content {
    padding: 40px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .contact .wrap-contact-content div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
}
/*
    
*/
.error404 {
  background: #101010 url('/images/page-404.jpg') no-repeat center center;
  background-size: cover;
}
.error404 #main-header {
  display: none;
}
.error404 .wrap-404 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  height: 100vh;
  padding-top: 100px;
}
.error404 h1 {
  font-size: 10rem;
  font-weight: 600;
  line-height: 6rem;
}
.error404 h1 small {
  font-size: 2rem;
  font-weight: 300;
  display: block;
}
@media screen and (min-width: 990px) {
  .error404 .wrap-404 {
    background-size: 70%;
  }
}

