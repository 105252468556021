@charset "UTF-8";

@import 'normalize';
@import "fonts";

// ChuckCSS
@import (reference) "../../node_modules/ChuckCSS/chuckcss/settings";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/functions";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/responsive";
@import "../../node_modules/ChuckCSS/chuckcss/main/animations";
@import "../../node_modules/ChuckCSS/chuckcss/main/base";
@import "../../node_modules/ChuckCSS/chuckcss/elements/titles";
@import "../../node_modules/ChuckCSS/chuckcss/elements/buttons";
@import "../../node_modules/ChuckCSS/chuckcss/elements/lists";
@import "../../node_modules/ChuckCSS/chuckcss/elements/images";
@import "../../node_modules/ChuckCSS/chuckcss/components/alerts";
@import "../../node_modules/ChuckCSS/chuckcss/components/modals";
@import "../../node_modules/ChuckCSS/chuckcss/main/helpers";
@import "../../node_modules/ChuckCSS/chuckcss/main/icons";
@import "../../node_modules/ChuckCSS/chuckcss/main/generator";




// Settings
@import 'settings-override';

.titillium() {
  font-family: 'Titillium Web', sans-serif;
  font-display: auto;
}
.open() {
  font-family: 'Open Sans', sans-serif;
  font-display: auto;
}

._media-m-up({
  html.opened-menu,
  body.opened-menu {
    overflow-y: auto !important;
  }
});

body {
  background-color: white;
  color: @text-mc;
  .open;



  // Main containers
  .cc-inside {
    ._inside(100%);

    // Screen > 989px
    ._media-m-up({ ._inside(900px); });

    // Screen > 1189px
    ._media-l-up({ ._inside(1100px); });
  }

  ul {
    ._reset;

    li {
      list-style: none;
    }
  }
}

/* define text selection */
::-moz-selection {
  background: @primary;
  color: white;
}

::selection {
  background: @primary;
  color: white;
}


/* Import Helpers files */
@import 'helpers/hamburger';
@import 'helpers/modals';

/* Import libraries plugins files */
@import "libraries/fancybox";
@import "libraries/wow";
@import "libraries/leaflet";

/* Import Website styles */
@import 'website/common';
@import 'website/header-footer';
@import 'website/metiers';
@import 'website/clients-references';
@import 'website/contact';
@import 'website/error404';



