@charset "UTF-8";


// @keyframes fadeIn {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// .fadeIn {
//   animation-name: fadeIn;
// }



@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX;
}



@keyframes slideInDownBig {
  from {
    transform: translate3d(0, -45px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInDownBig {
  animation-name: slideInDownBig;
}



@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}



@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}



@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.08, 1.08, 1.08);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}



@keyframes wrench {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(60deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.wrench {
  transform-origin: 56px 15px;
  animation-name: wrench;
}



@keyframes headShake {
  0% {
    transform: translateY(0);
  }
  6.5% {
    transform: translateY(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateY(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateY(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateY(2px) rotateY(3deg);
  }
  50% {
    transform: translateY(0);
  }
}
.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
}


.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}



@keyframes hinge {
  0%,6%,12% {
    ._translate(0, 0, 0);
  }

  3%,9% {
    ._translate(0, 3px, 0);
  }

  40%,44%,48%,52% {
    ._translate(0, 0, 0);
  }

  42%,46%,50% {
    ._translate(0, 10px, 0);
  }

  90% {
    ._translate(0, 0, 0);
    opacity: 1;
  }

  to {
    ._translate(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  animation-name: hinge;
  animation-timing-function: ease-in-out;
  transform-origin: 30px 30px;
  animation-duration: 4s;
  animation-fill-mode: both;
}


@keyframes slideInUp {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, -140px, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
    animation-fill-mode: both;
}


/*
  @@ LOZAD animations @@
  -- Animate img and backstretch when elements are loaded with lozad
*/
img[data-loaded="true"],
[data-loaded="true"] img {
  animation-name: showLazyImg;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@keyframes showLazyImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


