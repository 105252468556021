// HEADER / FOOTER

#main-header {
  padding: 15px;
  position: relative;
  ._transition(0.2s, background-color);

  &.sticky {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .05);
  }

  #logo {
    display: block;
    padding-left: 5px;
    max-width: 65%;

    img {
      vertical-align: bottom;
    }
  }

  // Hamburger
  .css-open-menu {
    position: absolute;
    z-index: 8;
    top: 13px;
    right: 0;
  }

  // Main-nav
  nav {
    position: fixed;
    height: 100vh;
    z-index: 98;
    background-color: white;
    top: 0;
    left: -120%;
    width: 100%;
    bottom: 0;
    padding-top: 45px;
    overflow-y: auto;
    ._transition(0.35s, left, cubic-bezier(1, 0, 0, 1));

    &.shown {
      left: 0%;
    }

    & > ul {
      & > li {
        text-align: center;
        border-bottom: 1px solid darken(@gray, 5%);
        position: relative;

        &:nth-child(1) {
          border-top: 1px solid darken(@gray, 5%);
        }

        a {
          text-transform: uppercase;
          display: block;
          padding: 10px;
          font-size: 1.8rem;
          font-weight: 300;
          ._transition;

          &:hover,
          &.active {
            color: @primary;
          }
        }

        // Sub-menus
        ul {
          margin-bottom: 10px;

          li {
            a {
              text-transform: none;
              font-size: 1.4rem;
              padding: 5px;
            }
          }
        }
      }
    }
  }

  // Contact
  .header-contact {
    padding: 15px 0 0;

    div {
      padding: 5px;

      a {
        display: block;
        width: 100%;
        position: relative;
        background-color: #282828;
        color: white;
        border: 0;
        font-size: 1.4rem;

        p {
          position: relative;
          z-index: 2;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 0%;
          border-radius: 3px;
          background-color: darken(#282828, 10%);
          z-index: 1;
          ._transition(0.35s, width, cubic-bezier(1, 0, 0, 1));
        }
      }
    }
  }

  // Screen > 359px;
  ._media-xs-up({ // Contact
    .header-contact {
      display: flex;

      div {
        flex: 0 0 50%;
      }
    } });


  // Screen > 767px
  ._media-s-up({ display: flex; align-items: center; position: fixed; z-index: 98; left: 0; right: 0; // White BG on all pages, but not on homepage
    body:not(.homepage) & {
      background-color: rgba(255, 255, 255, 0.95);
    } &.sticky {
        padding: 0 15px;

        .header-contact {
          display: block;
        }
      } // Logo
    #logo {
      padding: 15px 30px;
      max-width: 250px;
    } // Hamburger
    .css-open-menu {
      display: none;
    } // Menu
    nav {
      flex: 1;
      position: static;
      height: inherit;
      background-color: transparent;
      padding-top: 0px;
      padding-right: 15px;
      overflow-y: inherit;

      & > ul {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        & > li {
          border: 0;

          &:nth-child(1) {
            border-top-width: 0px;
          }

          &:hover {
            & > a {
              color: @orange;
            }

            ul {
              display: block;
            }
          }

          a {
            font-size: 1.5rem;

            &.active,
            &:hover {
              color: @orange;
            }
          }

          ul {
            display: none;
            position: absolute;
            z-index: 9;
            left: 0;
            right: 0;
            top: 100%;
            margin-bottom: 0;
            background-color: white;
            padding: 10px 0;
            box-shadow: 0 5px 10px 0px rgba(0, 0, 0, .05);

            li {
              a {
                padding: 5px 15px;
              }
            }
          }
        }
      }
    } // Contact
    .header-contact {
      padding: 0;
      display: block;
      padding: 15px;
      display: none;

      div {
        padding: 0;

        &:nth-child(1) {
          padding-bottom: 5px;
        }
      }
    } });

  // Screen > 989px;
  ._media-m-up({ // Logo
    #logo {
      max-width: inherit;
    } });


  // Screen > 1999px;
  ._media-l-up({ // Menu
    nav {
      padding-right: 30px;

      & > ul {
        & > li {
          a {
            padding: 10px 20px;
          }
        }
      }
    } // Contact
    .header-contact {
      padding: 15px 40px;
    } });
}

#main-footer {

  .footer-title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 1.66666667em;
    line-height: 1.5;
    font-weight: 400;
    display: block;
    color: #070707;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  // Maps + Contact informations
  .wrap-maps {
    #leaflet-maps {
      min-height: 450px;
      z-index: 1;
    }

    .alpixel-coordinates {
      text-align: center;
      padding: 50px 20px;
      text-align: center;
      color: inherit;

      a, strong {
        color: #B85500;
      }


      ul {
        li {
          display: inline-block;
          padding: 0 20px;

          a {
            display: block;
            font-size: 3rem;
          }
        }
      }
    }
  }

  .alpixel-credits {
    padding: 30px 20px;
    background-color: #282828;
    text-align: center;
    font-size: 1.2rem;

    ul {
      color: #8F8F8F;
      
      li {
        padding:10px 0 0;
      }
    }

    a {
      display: inline-block;
      padding:10px 0;
      color: #8F8F8F;
      border-bottom: 1px solid currentColor;

      &.active {
        color: white;
        border-bottom-color: white;
      }
    }
  }

  ._media-m-up({ .wrap-maps {
    display: flex;
    align-items: stretch;

    #leaflet-maps {
      flex: 0 0 66%;
    }

    .alpixel-coordinates {
      flex: 1;
      order: 2;
    }
  } .alpixel-credits {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          padding: 0 10px;
        }

        a {
          padding: 5px 0;
        }
      }
    } });
}
