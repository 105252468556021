// METIERS & VALEURS
/*
    Used in :
    	- Métiers @ valeurs
    	- sites Symfony exemples
    	- Site Drupal exemples
*/

// Métiers
.wrap-metiers {
  background-color: white;

  .metier-item {
    padding: 0;
    text-align: center;
    padding: 40px 20px;

    i {
      color: darken(@gray, 30%);
      font-size: 7rem;
      ._transition;
    }

    h3 {
      margin-top: 15px;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 600;
    }

    p {
      max-width: 350px;
      margin: 0 auto;
      color: #888888;
      font-weight: 300;
      font-size: 1.5rem;
      padding-top: 20px;

      strong {
        color: @orange;
      }
    }
  }


  // Screen > 989px;
  ._media-m-up({ display: flex; flex-wrap: wrap; justify-content: center; .metier-item {
    flex: 0 0 33.32%;
    padding: 70px 30px;

    p {
      text-align: justify
    }
  } });
}