/* ********* */
/** MODALS **/
/* ********* */

/* ********************* */
#modal-contact {
    .modal-body {
        padding:0;

        .wrap-modal-content {
            padding:20px;
            text-align: center;

            div {
                padding: 15px 20px 40px;

                .icon {
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin:0 auto 20px;
                        height: 50px;
                        width: 50px;
                        color: white;
                        font-size: 3rem;
                        border-radius: 100%;

                        i {
                            ._reset;
                            display: block;
                        }
                    }

                }

                strong {
                    display: block;
                    text-transform: uppercase;
                    margin-bottom:10px;

                    small {
                        color:#575757;
                        font-weight: 300;
                        text-transform: none;
                        display: block;

                    }
                }

                a {
                    color:#B85500;
                    font-weight:600;
                    font-size:1.6rem;
                }
            }
        }
    }

    ._media-m-up({
        .modal-body {

            h2 {
                padding-left:20px;
                padding-right:20px;
            }
            
            .wrap-modal-content {
                padding:40px 0 0;
                display: flex;
                flex-wrap: wrap;

                div {
                    flex:0 0 33%;
                }
            }
        }
    })
}
