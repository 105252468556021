// Error 404
/*
    
*/
.error404 {

  background: #101010 url('/images/page-404.jpg') no-repeat center center;
  background-size: cover;

  #main-header {
    display: none;
  }

  .wrap-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding-top: 100px;
  }

  h1 {
    font-size: 10rem;
    font-weight: 600;
    line-height: 6rem;

    small {
      font-size: 2rem;
      font-weight: 300;
      display: block;
    }
  }


  // Screen > 989px;
  ._media-m-up({ .wrap-404 {
    background-size: 70%;
  } });

  // Screen > 1199px;
  ._media-l-up({ });
}