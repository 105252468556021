// CONTACT
/*
    
*/
.contact {

  .wrap-contact-content {
    padding: 20px;
    text-align: center;

    div {
      padding: 15px 20px 40px;

      .icon {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px;
          height: 50px;
          width: 50px;
          color: white;
          font-size: 3rem;
          border-radius: 100%;

          i {
            ._reset;
            display: block;
          }
        }

      }

      strong {
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;

        small {
          color: #777777;
          font-weight: 300;
          text-transform: none;
          display: block;

        }
      }

      a {
        color: @orange;
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }


  ._media-m-up({ .wrap-contact-content {
    padding: 40px 0 0;
    display: flex;
    flex-wrap: wrap;

    div {
      flex: 0 0 33%;
    }
  } })


}