// COMMON CSS FILE
/*
    - Basics styles : strong, Hn
    - .body on CMs pages
    - Videos Top of pages
    - Intro on pages (H1 + intro texts)
    - 3 pushes after intro : Homepage, Metiers;
    - Realisations pushes : Homepage, Clients, sites Symfony
*/


/*
	- Basics Styles
*/
strong {
  font-weight: 600;
}

h1, .h1-like {
  .titillium;
  color: #444444;
}

// Gray background with H2 titles insides
// Accroches
.h2-main-title {
  padding: 50px 20px;
  color: @text-mc;
  letter-spacing: 2px;
  font-weight: 300;
  text-align: center;
  font-size: 2rem;

  strong {
    color: @orange;
  }

  // Screen > 767px;
  ._media-s-up({ font-size: 2.8rem; });

  // Screen > 989px;
  ._media-m-up({ padding-left: 0; padding-right: 0; });
}

strong.cc-orange,
em.cc-orange {
  color: @orange;
}



/*
	- .body on CMs pages
*/
.body {
  padding: 0 20px;

  h2 {
    margin-top: 50px;
  }

  h4,
  h3 {
    margin-top: 20px;
    color: @orange;
  }

  p {
    padding: 10px 0;
  }

  ul {
    margin-top: 20px;
  }

  a:not(.btn) {
    color: @orange;
    display: inline-block;
    border-bottom: 1px dotted @orange;
    padding: 0 3px;
    margin: 0 2px;
    ._transition;

    &:hover {
      color: darken(@orange, 10%);
    }
  }

  .alert a.btn {
    text-decoration: none;
  }
}

// Specific for Symfony exemples page
.custom-columns {
  & > ul {
    padding-top:20px;
    text-align: center;
  }

  ._media-s-up({
    display:flex;

    & > ul {
      flex:1;
      text-align: left;
    }
  });
}


/*
	- Videos top of pages
*/
.css-video-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;

  .homepage & {
    height: 50vh;
  }

  .metiers & {
    // Browser supports .webp images
    .webp & {
      background-image: url('/images/video/mobile/meeting-room.webp');
    }

    // Browser who does not support .webp images
    .no-webp & {
      background-image: url('/images/video/mobile/meeting-room.jpg');
    }
  }

  .sites-symfony &,
  .sites-drupal &,
  .sites-prestashop & {
    // Browser supports .webp images
    .webp & {
      background-image: url('/images/video/mobile/love-coding.webp');
    }

    // Browser who does not support .webp images
    .no-webp & {
      background-image: url('/images/video/mobile/love-coding.jpg');
    }
  }

  .clients & {
    // Browser supports .webp images
    .webp & {
      background-image: url('/images/video/mobile/customers.webp');
    }

    // Browser who does not support .webp images
    .no-webp & {
      background-image: url('/images/video/mobile/customers.jpg');
    }

    .ref-symfony&,
    .ref-drupal&,
    .ref-prestashop& {
      // Browser supports .webp images
      .webp & {
        background-image: url('/images/video/mobile/travaho.webp');
      }

      // Browser who does not support .webp images
      .no-webp & {
        background-image: url('/images/video/mobile/travaho.jpg');
      }
    }
  }

  .contact &,
  .cms &,
  .agency & {
    // Browser supports .webp images
    .webp & {
      background-image: url('/images/video/mobile/agency.webp');
    }

    // Browser who does not support .webp images
    .no-webp & {
      background-image: url('/images/video/mobile/agency.jpg');
    }
  }

  // Overlay
  .video-overlay {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.8);
  }

  ._media-s-up({
    height: 45vh;
  .homepage & {
    height: 100vh;
  } .metiers & {
      // Browser supports .webp images
      .webp & {
        background-image: url('/images/video/meeting-room.webp');
      }

      // Browser who does not support .webp images
      .no-webp & {
        background-image: url('/images/video/meeting-room.jpg');
      }
    } .sites-symfony &,
      .sites-drupal &,
      .sites-prestashop & {
        // Browser supports .webp images
        .webp & {
          background-image: url('/images/video/love-coding.webp');
        }

        // Browser who does not support .webp images
        .no-webp & {
          background-image: url('/images/video/love-coding.jpg');
        }
      } .clients & {
          // Browser supports .webp images
          .webp & {
            background-image: url('/images/video/customers.webp');
          }

          // Browser who does not support .webp images
          .no-webp & {
            background-image: url('/images/video/customers.jpg');
          }

          .ref-symfony&,
          .ref-drupal&,
          .ref-prestashop& {
            // Browser supports .webp images
            .webp & {
              background-image: url('/images/video/travaho.webp');
            }

            // Browser who does not support .webp images
            .no-webp & {
              background-image: url('/images/video/travaho.jpg');
            }
          }
        } .cms &,
          .agency & {
            // Browser supports .webp images
            .webp & {
              background-image: url('/images/video/agency.webp');
            }

            // Browser who does not support .webp images
            .no-webp & {
              background-image: url('/images/video/agency.jpg');
            }
          } });
}


/*
	- Titles H1 + Intro text on pages
*/
.page-intro {
  padding: 50px 20px;

  h1 {
    font-weight: 600;
    font-size: 2.5rem;
  }

  .intro {
    font-weight: 300;
    font-size: 1.9rem;
    text-align: justify;
  }

  // Screen > 767px;
  ._media-s-up({ padding: 120px 20px 60px; h1 {
    font-size: 3.9rem;
  } .intro {
      text-align: center;
      line-height: 1.8
    } });
}


/*
	- 3 pushes after intro
	* Used in :
		- Homepage
		- Metiers & Valeurs
*/
.pushes {
  padding: 50px 20px;

  div {
    padding: 30px 0;
    text-align: center;

    a {
      display: block;
    }

    h2 {
      margin: 20px 0;
    }

    .push-description {
      font-style: italic;
      color: #999999;
      font-size: 1.4rem;

      strong {
        color: @orange;
      }
    }

    // Specific for "Métiers"
    .artisan {
      position: relative;
      width: 70px;
      height: 70px;
      margin: 0 auto;
    }

    i {
      color: darken(@gray, 30%);
      font-size: 7rem;

      &.fa-heart {
        color: @red;
      }

      &.fa-wrench {
        color: #9caed5;
        display: inline-block;
        margin: 0 auto;
        width: 70px;
        height: 70px;
      }

      &.fa-times-hexagon {
        color: #33566f;
        display: block;
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 1.4rem;
        ._rotate(16deg);
      }

      &.fa-handshake {
        color: #ddb899;
      }
    }

    .link-preston {
      display: block;
      width: 150px;
      height: 150px;
      background-color: #93ddea;
      border-radius: 100%;
      margin: 0 auto;
      overflow: hidden;

      img,
      picture {
        position: relative;
        display: block;

        &.hide {
          display: none;
        }
      }
    }
  }


  // Screen > 767px;
  ._media-s-up({ padding: 60px 20px 0; display: flex; div {
    flex: 1;
    padding: 0 30px;
  } });
}


/*
	- Realisations pushes
	* Used in :
		- Homepage
		- Clients
*/
.realisations {
  article {
    min-height: 250px;
    display: flex;
    align-items: stretch;
    margin-bottom: 5px;
    overflow: hidden;

    a {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
      width: 100%;
      min-height: 100%;
      position: relative;

      .agency & {
        &:after {
          display: none;
        }
      }

      &:after {
        display: block;
        content: ' ';
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        z-index: -1
      }

      h3 {
        flex: 0 0 100%;
        .titillium;

        &:after {
          display: block;
          content: ' ';
          background-color: currentColor;
          height: 1px;
          width: 30%;
          margin: 15px auto 20px;
        }
      }

      h4 {
        flex: 0 0 100%;
        color: #737373;
        font-weight: 300;
        font-size: 1.5rem;
      }
    }
  }

  ._media-s-up({ article {
    min-height: 350px;
  } });

  // Screen > 989px;
  ._media-m-up({ display: flex; flex-wrap: wrap; .realisation-item {
    flex: 0 0 50.00%;


    article {
      margin: 0.50%;
      min-height: 300px;

      &:hover {
        a {
          opacity: 1;
          ._translate(0px, 0px, 0px);
        }

        .backstretch img {
          ._filter(grayscale(0));
        }
      }

      a {
        opacity: 0;
        ._translate(0px, 250px, 0px);
        ._transition(0.35s);
        transition-timing-function: cubic-bezier(.45, .38, .34, .83);
      }

      .agency & {
        a {
          transition: none;
          ._translate(0px, 0px, 0px)

        }
      }

      .backstretch img {
        ._transition(0.2s, filter);
        ._filter(grayscale(0.8));
      }
    }

    /*
        C'est joli et ça me sert pour l'inté, donc on ne supprime pas merki :)

        +-----------+-----+-----+
        |           | 2   | 3   |
        +     1     +-----+-----+
        |           |     4     |
        +-----------+-----+-----+
        |     5     | 6   | 7   |
        +-----+-----+-----+-----+
        | 8   | 9   |           |
        +-----+-----+    11     +
        |    10     |           |
        +-----+-----+-----+-----+
        |    12     | 13  | 14  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 16  | 17  |
        +    15     +-----+-----+
        |           |     18    |
        +-----------+-----+-----+
        |    19     | 20  | 21  |
        +-----+-----+-----+-----+
        | 22  | 23  |           |
        +-----+-----+    25     +
        |    24     |           |
        +-----+-----+-----+-----+
        |    26     | 27  | 28  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 30  | 31  |
        +    29     +-----+-----+
        |           |     32    |
        +-----------+-----+-----+
        |    33     | 34  | 35  |
        +-----+-----+-----+-----+
        | 36  | 37  |           |
        +-----+-----+    39     +
        |    38     |           |
        +-----+-----+-----+-----+
        |    40     | 41  | 42  |
        +-----------+-----+-----+

        +-----------+-----+-----+
        |           | 44  | 45  |
        +    43     +-----+-----+
        |           |     46    |
        +-----------+-----+-----+
        |    47     | 48  | 49  |
        +-----+-----+-----+-----+
        | 50  | 51  |           |
        +-----+-----+    53     +
        |    52     |           |
        +-----+-----+-----+-----+
        |    54     | 55  | 56  |
        +-----------+-----+-----+
    */

    /* Big Images */

    &.item-53, &.item-43, &.item-39, &.item-29, &.item-25, &.item-15, &.item-11,
    &.item-1 {
      display: flex;
      align-items: stretch;

      article {
        width: 99.00%;
      }
    }

    /* Small : 50% width */

    &.item-55, &.item-50, &.item-48, &.item-44, &.item-41, &.item-36, &.item-34, &.item-30, &.item-27, &.item-22, &.item-20, &.item-16, &.item-13, &.item-8, &.item-6,
    &.item-2 {
      display: flex;
      flex-wrap: wrap;

      article {
        flex: 0 0 49.00%;

        &:nth-child(3) {
          flex: 0 0 99.00%;
        }
      }

    }
  } });
}
