/* titillium-web-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/titillium-web-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/titillium-web-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/titillium-web-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/titillium-web-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/titillium-web-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/titillium-web-v15-latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}


/* open-sans-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v34-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/open-sans-v34-latin-italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/open-sans-v34-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v34-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v34-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v34-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v34-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v34-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v34-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v34-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
